import TagBox from "devextreme-react/tag-box";
import { useEffect, useState } from "react";
import { hocSinhApi } from "../../api/hocSinhApi";
import { useCommonContext } from "../../contexts/common";
import './TrangThaiHocSinh.css';
type ITagboxTrangThaiHocSinhProps = {
    isReadonly?: boolean,
    value?: number[],
    width?: number,
    onValueChanged: (id: number[], data?: any[]) => void,
    className?: string,
    isShowClearButton?: boolean,
    preText?: string,
    stylingMode?: "outlined" | "filled" | "underlined"
}
const TagBoxTrangThaiHocSinh = (props: ITagboxTrangThaiHocSinhProps) => {
    const [dataSource, setDataSource] = useState<any[]>([]);
    useEffect(() => {
        handleReloadTrangThai();
    }, [])
    const handleReloadTrangThai = async () => {
        const res = await hocSinhApi.selectTrangThai();
        if (res.is_success) {
            setDataSource(res.data.filter((x: any) => x.id > 0))
        }
    }
    const { translate } = useCommonContext();
    return (
        <TagBox dataSource={dataSource}
            displayExpr={"trang_thai"}
            valueExpr={"id"}
            value={props.value}
            stylingMode={"outlined"}
            readOnly={props.isReadonly === true}
            placeholder={translate("Trạng thái HS")}
            width={200}
            height={32}
            className={"default_selectbox dx-overlay-content"}
            showClearButton={props.isShowClearButton === true}
            showSelectionControls={true}
            maxDisplayedTags={0}
            
            dropDownOptions={{
                width: 'auto', 
                minWidth: 300, 
                maxWidth: 400, 
            }}
            onValueChanged={(e) => {
                if (e.event) {
                    if (e.value) {
                        const data = dataSource.filter(x => e.value.includes(x.id))
                        props.onValueChanged(e.value, data)
                    } else {
                        props.onValueChanged([], [])
                    }
                }
            }} />
    );
}
export default TagBoxTrangThaiHocSinh

