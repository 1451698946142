import DataGrid from "devextreme-react/data-grid";
import NumberBox from "devextreme-react/number-box";
import ScrollView from "devextreme-react/scroll-view";
import TextBox from "devextreme-react/text-box";


import { Column, Editing, FilterPanel, FilterRow, HeaderFilter, Selection, Sorting } from "devextreme-react/data-grid";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { loadKhoanNopStart } from '../../../state/actions/khoanNopActions';

import { AddKhoanNopModalRespone } from "..";
import { mucPhaiThuChiTietApi } from "../../../api/mucPhaiThuChiTietApi";
import { mucThuPhiApi } from "../../../api/mucThuPhiApi";
import CmbHinhThucNopBom from '../../../components/combobox-hinh-thuc-nop-bom';
import { NamHocTruongKhoiHeFilter } from "../../../models/namHocTruongKhoiHeFilter";
import { sf_hinhthucnopbom } from '../../../models/response/category/sf_hinhthucnopbom';
import { RootState } from '../../../state/reducers';
import { Button } from "../../buttons";
import RenderGroup from "../../cell-group";
import { khoanNopGroupApi } from "../../../api/khoanNopGroupApi";
import { DefaultPopup } from "../../modal";

type AddKhoanNopFromKhoanNopProps = {
    sf_khoannop_ids_added?: number[],
    onSelected: (data: AddKhoanNopModalRespone) => void,
    onCancel: () => void,
    isSaving: boolean,
    filter: NamHocTruongKhoiHeFilter,
    nam_nhap_hoc?: string,
    ts_hocsinh_id: number,
    sf_hinhthucnopbom_ids?: string

}
export const AddKhoanNopFromKhoanNop = (props: AddKhoanNopFromKhoanNopProps) => {
    const { dm_coso_id } = useSelector((state: RootState) => state.common);
    const { sf_khoannops } = useSelector((x: RootState) => x.khoanNop)
    const [sf_mucthuphi_selected_ids, set_sf_mucthuphi_selected_ids] = useState<number[]>([]);
    const [is_tinh_mien_giam, set_is_tinh_mien_giam] = useState<boolean>(true)
    const [isShowPlaceHolder, SetIsShowPlaceHolder] = useState<boolean>(true)
    const [sf_mucthuphi_datasource, set_sf_mucthuphi_datasource] = useState<any[]>([])
    const [hinhThucNopBoms, setHinhThucNopBoms] = useState<sf_hinhthucnopbom[]>([]);
    const [khoanDaNops, setKhoanDaNops] = useState<number[]>([]);
    const [khoanNopDichVuIds, setKhoanNopDichVuIds] = useState<number[]>([]);

    const [summary, setSummary] = useState<number>();

    const grid = useRef<any>();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadKhoanNopStart())
    }, [sf_khoannops.length == 0])
    useEffect(() => {
        handleReloadKhoanNopDichVu();
    }, [])
    useEffect(() => {
        let filterData = sf_mucthuphi_datasource.filter((x: any) => khoanDaNops === undefined || !khoanDaNops.includes(x.sf_khoannop_id));
        if (khoanNopDichVuIds.length > 0) {
            filterData = filterData.filter((x: any) => x.sf_loaikhoannop_id === 4 || (!x.is_dichvu || khoanNopDichVuIds.includes(x.sf_khoannop_group_id)))
        }
        else {
            filterData = filterData.filter((x: any) => !x.is_dichvu)

        }
        set_sf_mucthuphi_datasource(filterData)
    }, [khoanDaNops, khoanNopDichVuIds])
    useEffect(() => {
        handleReloadKhoanNop()
    }, [hinhThucNopBoms])
    // useEffect(() => {
    //     const data = {
    //         sf_mucthuphis: sf_mucthuphi_datasource.filter((x) => sf_mucthuphi_selected_ids.includes(x.id))
    //     };
    //     const totalAmount = data.sf_mucthuphis.reduce((count, item) => {
    //         return count + ((item.so_tien_phai_nop === undefined || item.so_tien_phai_nop === 0) ? item.so_tien : item.so_tien_phai_nop);
    //     }, 0);
    //     setSummary(totalAmount)
    // }, [sf_mucthuphi_selected_ids])
    const handleReloadMucPhaiThuChiTiet = async () => {
        const request = { nam_hoc: props.filter.nam_hoc, ts_hocsinh_id: props.ts_hocsinh_id }
        const res = await mucPhaiThuChiTietApi.select_all_khoannop(request)
        if (res.is_success) {
            const khoanDaNopIds = res.data.map((x: any) => x.sf_khoannop_id);
            setKhoanDaNops(khoanDaNopIds)
        }
    }
    const handleReloadKhoanNopDichVu = async () => {
        const request = { dm_coso_id: dm_coso_id, ts_hocsinh_id: props.ts_hocsinh_id, nam_hoc: props.filter.nam_hoc }
        const res = await khoanNopGroupApi.select_dichvu_by_hs(request)
        if (res.is_success) {
            const khoanNopIds = res.data.filter((x: any) => x.sf_saleset_register_dichvu_status_id == 1 || x.sf_saleset_register_dichvu_status_id == 2).map((x: any) => x.sf_khoannop_group_id);
            setKhoanNopDichVuIds(khoanNopIds)
        }
    }
    const handleReloadKhoanNop = async () => {
        let isLoading = true;
        const intever_obj = setInterval(() => {
            if (!isLoading) {
                clearInterval(intever_obj)
                SetIsShowPlaceHolder(false)
            }
        }, 200)
        const datasend = {
            dm_he_id: props.filter.dm_he_id,
            dm_khoi_id: props.filter.dm_khoi_id,
            dm_truong_id: props.filter.dm_truong_id,
            nam_hoc: props.filter.nam_hoc,
            nam_nhap_hoc: props.nam_nhap_hoc === undefined ? props.filter.nam_hoc : props.nam_nhap_hoc
        }
        const res = await mucThuPhiApi.select_by_nam_nhap_hoc(datasend)
        if (res.is_success) {
            let sf_mucthuphi_datasource = res.data.filter((x: any) => x.nam_nhap_hoc === props.nam_nhap_hoc).map((x: any) => {
                return {
                    ...x,
                    so_tien_phai_nop: x.so_tien,
                    ghi_chu: ""
                }
            })
            if (props.sf_khoannop_ids_added) {
                sf_mucthuphi_datasource = res.data.filter((x: any) => props.sf_khoannop_ids_added === undefined || !props.sf_khoannop_ids_added.includes(x.sf_khoannop_id));
            }
            if (!hinhThucNopBoms || hinhThucNopBoms.length === 0 || hinhThucNopBoms[0] === undefined) {
                set_sf_mucthuphi_datasource(sf_mucthuphi_datasource)
            }
            if (hinhThucNopBoms.length > 0 && hinhThucNopBoms[0] !== undefined && hinhThucNopBoms[0] != null) {
                const sf_htnb_ids = hinhThucNopBoms.map((obj: { id: number }) => obj.id);

                sf_mucthuphi_datasource = sf_mucthuphi_datasource
                    .filter((x: any) =>
                        x.sf_hinhthucnopbom_ids
                            .split(',')
                            .map((id: string) => parseInt(id, 10))
                            .some((id: number) => sf_htnb_ids.includes(id))
                    )
                    .map((x: any) => ({ ...x }));
                set_sf_mucthuphi_datasource(sf_mucthuphi_datasource)

            }
        }
        handleReloadMucPhaiThuChiTiet()
        isLoading = false;
    }
    const handelSubmit = () => {
        const data = {
            sf_bom_id: 0,
            is_calculate_miengiam: is_tinh_mien_giam,
            sf_bom_chitiets: sf_mucthuphi_datasource.filter((x) => sf_mucthuphi_selected_ids.includes(x.id)),
            sf_mucthuphis: sf_mucthuphi_datasource.filter((x) => sf_mucthuphi_selected_ids.includes(x.id)),
            sf_hinhthucnopbom_id: (hinhThucNopBoms.length > 0 && hinhThucNopBoms !== undefined) ? hinhThucNopBoms[0].id : 0

        }
        props.onSelected(data);
    }

    const onSelectionChanged = ({ selectedRowKeys }: any) => {
        const data = {
            sf_mucthuphis: sf_mucthuphi_datasource.filter((x) => selectedRowKeys.includes(x.id))
        };
        const totalAmount = data.sf_mucthuphis.reduce((count, item) => {
            return count + ((item.so_tien_phai_nop === undefined || item.so_tien_phai_nop === 0) ? item.so_tien : item.so_tien_phai_nop);
        }, 0);
        setSummary(totalAmount)
        if (selectedRowKeys.length !== sf_mucthuphi_selected_ids.length || !selectedRowKeys.every((key: number, index: number) => key === sf_mucthuphi_selected_ids[index])) {
            set_sf_mucthuphi_selected_ids(selectedRowKeys);
        }
    };


    const so_tien_phai_nop_cell_render = (cell: any) => {
        return (
            <NumberBox
                format="#,## VNĐ"
                onValueChanged={(e) => {
                    cell.setValue(e.value);
                    const new_source = sf_mucthuphi_datasource.map(x => {
                        if (x.id === cell.data.id) {
                            return { ...x, so_tien_ap_dung: e.value };
                        }
                        return x;
                    });
                    set_sf_mucthuphi_datasource(new_source);
                }}
            />
        );
    };

    const ghi_chu_cell_render = (cell: any) => {
        return <TextBox onValueChanged={(e) => {
            cell.setValue(e.value)
            const new_source = sf_mucthuphi_datasource.map(x => {
                if (x.id === cell.data.id) {
                    return { ...x, ghi_chu: e.value }
                } else {
                    return { ...x }
                }
            })
            set_sf_mucthuphi_datasource(new_source);
        }} />
    }

    return (
        <DefaultPopup
            width="80%"
            title={'Thêm khoản nộp'}
            onClose={props.onCancel}
        >
            <div>
                {/* <ScrollView width={"100%"} height={window.innerHeight - 250}> */}
                <div>
                    <div className="row">
                        <div className="col-md-12" style={{ paddingTop: "1rem" }}>
                            <div className="form-inline" style={{ paddingLeft: "1rem" }}>
                                <div className="form-group">
                                    <label className='form-label'>Chọn kỳ nộp </label>
                                    <CmbHinhThucNopBom
                                        isShowClearButton={true}
                                        width={200}
                                        stylingMode="filled"
                                        sf_hinhthucnopbom_dotgui={props.sf_hinhthucnopbom_ids}
                                        value={hinhThucNopBoms[0]?.id}
                                        onValueChanged={(id, data) => {
                                            let arrBom = new Array();
                                            arrBom.push(data);
                                            setHinhThucNopBoms(arrBom)
                                        }}
                                        dmCoSoId={dm_coso_id}
                                    />
                                </div>

                                <div className="form-group ">
                                    <label className='form-label'>Tổng tiền phải nộp: <span style={{ color: 'red', fontWeight: 600 }}> {summary?.toLocaleString()}</span> VNĐ </label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 padding-top-1rem">
                            <DataGrid
                                ref={grid}
                                keyExpr={"id"}
                                dataSource={sf_mucthuphi_datasource}
                                columnHidingEnabled={false}
                                columnAutoWidth={true}
                                showBorders={true}
                                showColumnLines={false}
                                showRowLines={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode={"nextColumn"}
                                hoverStateEnabled={true}
                                focusedRowEnabled={false}
                                height={window.innerHeight - 300}
                                noDataText="Không có dữ liệu"
                                selectedRowKeys={sf_mucthuphi_selected_ids}
                                onSelectionChanged={onSelectionChanged}
                                paging={{ enabled: false }}
                                wordWrapEnabled
                            >   <Editing
                                    mode="cell"
                                    allowUpdating={true}
                                    allowAdding={false}
                                    allowDeleting={false} />
                                <Sorting mode="multiple" />
                                <Selection mode="multiple" showCheckBoxesMode="always"></Selection>
                                <FilterRow visible={true} />
                                <FilterPanel visible={false} />
                                <HeaderFilter visible={true} />
                                <Column dataField="nhom_khoan_nop" groupCellRender={(e: any) => <RenderGroup
                                    e={e}
                                    selectedIds={sf_mucthuphi_selected_ids}
                                    onSelectedChanged={set_sf_mucthuphi_selected_ids}
                                />} caption="Nhóm" groupIndex={0} allowEditing={false} autoExpandGroup={true} ></Column>
                                <Column dataField="ma_khoan_nop" caption="Mã khoản nộp" width={150} allowEditing={false}></Column>
                                <Column dataField="ten_khoan_nop" caption="Tên khoản nộp" allowEditing={false}></Column>
                                <Column dataField="so_tien" caption="Số tiền định mức" format={{ formatter: (value: any) => value.toLocaleString('de-DE') }} width={150} allowEditing={false}></Column>
                                <Column dataField="so_tien_phai_nop" caption="Số tiền phải nộp" format={{ formatter: (value: any) => value.toLocaleString('de-DE') }} width={150}
                                    editCellRender={so_tien_phai_nop_cell_render}
                                ></Column>
                                <Column dataField="ghi_chu" caption="Ghi chú" minWidth={150}
                                    editCellRender={ghi_chu_cell_render}
                                ></Column>
                            </DataGrid>
                        </div>



                    </div>
                    {/* } */}
                </div>

                {/* </ScrollView> */}
            </div>
            <div className="pop-up-actions">
                <Button type="normal" icon="fa fa-times" text="Đóng" onClick={() => { props.onCancel() }}></Button> {' '}
                <Button type="normal" icon="fas fa-check-square" text="Áp dụng"
                    isLoading={props.isSaving}
                    // isDisabled={sf_mucthuphi_selected_ids.length === 0 || hinhThucNopBoms === undefined || hinhThucNopBoms[0] === undefined}
                    onClick={handelSubmit}></Button>
            </div>
        </DefaultPopup>
    );
}