import { call, put, takeLatest } from "redux-saga/effects";
import { IAccountLoginStart, IAuthGetUserStart, eAuthAcitonTypes } from "../action-types/IAuthActionTypes";
import { actions } from './../actions/actionsWrapper';
import { IBaseResponeModel } from "../../models/response/base-response";
import { accountApi } from "../../api/accountApi";


export function* authSaga(): any {
    yield takeLatest(eAuthAcitonTypes.GET_USER_START, getDetailWorker)
    yield takeLatest(eAuthAcitonTypes.LOGIN_START, logInWorker)

}

function* getDetailWorker(action: IAuthGetUserStart): any {
    const res: IBaseResponeModel = yield call([accountApi, accountApi.getUser])
    if (res.is_success) {
        yield put(actions.account.getUserSuccss(res.data))
    } else {
        yield put(actions.account.getUserError(res.message))
    }
}

function* logInWorker(action: IAccountLoginStart): any {
    const res: IBaseResponeModel = yield call(accountApi.logInSSO, action.payload)
    if (res.is_success) {
        yield put(actions.account.loginSuccess(res.data.data))
    } else {
        yield put(actions.account.loginError(res.message ?? ""))
    }
}