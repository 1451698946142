
import { Select } from '@primer/react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCommonContext } from '../../contexts/common';
import { dm_khoi } from '../../models/response/category/dm_khoi';
import { actions } from '../../state/actions/actionsWrapper';
import { RootState } from '../../state/reducers';
type ComboboxKhoiProps = {
	isReadonly?: boolean;
	value?: number;
	onValueChanged: (id: number, data?: dm_khoi) => void;
	className?: string;
	isShowClearButton?: boolean;
	preText?: string;
	width?: string | number;
	dm_truong_id?: number;
	stylingMode?: 'outlined' | 'filled' | 'underlined';
};
const ComboboxKhoi = (props: ComboboxKhoiProps) => {
	const { dm_khois, dm_truong_khoi_hes } = useSelector((state: RootState) => state.categorySource);
	const { language } = useSelector((x: RootState) => x.common);
	const dispatch = useDispatch();
	useEffect(() => {
		if (dm_khois.length === 0) dispatch(actions.categorySource.loadKhoiStart());
	}, []);
	const { translate } = useCommonContext();
	const source: dm_khoi[] = useMemo(() => {

		if (props.dm_truong_id) {
			return dm_khois.filter((x) => x.dm_truong_id == props.dm_truong_id);
		}
		return [];
	}, [dm_khois, dm_truong_khoi_hes, props.dm_truong_id]);
	return (
		<Select onChange={(e) => {
			const dm_khoi_id: number = e.currentTarget.value ? parseInt(e.currentTarget.value) : 0
			props.onValueChanged(dm_khoi_id, source.find(x => x.id == dm_khoi_id))

		}}
			value={`${props.value ?? 0}`}
			placeholder={translate("Chọn khối")}
		>
			{source.map(x => {
				return (
					<Select.Option key={x.id} value={`${x.id}`}>{x.ten_khoi}</Select.Option>
				);
			})}
		</Select>
	);
};

export default ComboboxKhoi;
