import SelectBox from "devextreme-react/select-box";
import React, { useEffect, useState } from "react";
import { categorySourceApi } from "../../api/categorySourceApi";
import { useCommonContext } from "../../contexts/common";
type ComboboxCoSoProps = {
    isReadonly?: boolean,
    value?: number,
    onValueChanged: (id: number) => void,
    className?: string,
    isShowClearButton?: boolean,
    preText?: string,
    stylingMode?: "outlined" | "filled" | "underlined"
}
const ComboboxCoSo = (props: ComboboxCoSoProps) => {
    const [coSos, setCoSos] = useState([]);
    const { translate } = useCommonContext();
    useEffect(() => {
        handleReloadCoSos();
    }, [])
    const handleReloadCoSos = async () => {
        const res = await categorySourceApi.dm_cosos();
        if (res.is_success) {
            setCoSos(res.data)
        }
    }
    return (
        <SelectBox dataSource={coSos}
            displayExpr={"viet_tat"}
            valueExpr={"id"}
            value={props.value}
            stylingMode={props.stylingMode || "outlined"}
            readOnly={props.isReadonly === true}
            placeholder={translate("Chọn cơ sở")}
            width="100%"
            className={"default_selectbox " + (props.className ? props.className : "")}
            showClearButton={props.isShowClearButton === true}
            onValueChanged={(e) => {
                props.onValueChanged(e.value)
            }} />
    );
}
export { ComboboxCoSo };

