import { Box, PageLayout } from '@primer/react';
import React from 'react';
import Header from './header';
import Menu from './menu';
interface ILayoutProps {
    children?: React.ReactNode
}
const Layout = (props: ILayoutProps) => {
    return (
        <PageLayout containerWidth="full" sx={{
            padding: 0
        }}>
            <>
                <PageLayout.Header sx={{
                    marginBottom: 0,
                }}
                >
                    <Header />
                </PageLayout.Header>
                <PageLayout.Pane sx={{
                    maxWidth: "200px",
                    width: "200px",
                    marginRight: "0px!important",
                    // backgroundColor: "canvas.subtle"
                }}
                    position={'start'}
                >
                    <Box height={window.innerHeight - 140}
                        overflow={"auto"}
                        sx={{
                            borderRightWidth: "1px",
                            borderRightStyle: "solid",
                            borderRightColor: "border.default",
                            m: 0
                        }}
                    >
                        <Menu />
                    </Box>
                </PageLayout.Pane>
                <PageLayout.Content sx={{p: 0}}>
                    <Box height={window.innerHeight - 120}
                        // overflow={"auto"}
                    >
                        {/* {props.children ? props.children : null} */}
                        {props.children && <>
                            {props.children}
                        </>}
                    </Box>
                </PageLayout.Content>
            </>

        </PageLayout >
    );
};

export default Layout;