import ScrollView from "devextreme-react/scroll-view";
import React, { useCallback, useState } from "react";
import { Button } from "../../../components/buttons";
import { DefaultPopup } from "../../../components/modal";
import { sf_tbtp_viewmodel } from "../../../models/response/tbtp/sf_tbtp";
import { EditTBTPHachToan } from "./edit_tbtp_hachtoan";
import { EditTBTPKhoanNop } from "./edit_tbtp_khoannop";

type TBTPEditModalProps = {
    sf_tbtp: sf_tbtp_viewmodel,
    onCancel: (isMustReload: boolean) => void,
    isReadOnly?: boolean
}

export const TBTPEditModal = (props: TBTPEditModalProps) => {
    const [isMustReload, SetIsMustReload] = useState<boolean>(false);
    const onClose = useCallback(() => {
        window.animationHidePopup("")
        setTimeout(() => {
            props.onCancel(true);
        }, 500);
    }, [])
    return (
        <DefaultPopup
            maxWidth={window.innerWidth - 200}
            title="Khoản nộp TBTP"
            onClose={onClose}
        >
            <div>
                <ScrollView width={window.innerWidth - 200} height={window.innerHeight - 200}>
                    <div className="pop-up-form">
                        <div>
                            <EditTBTPKhoanNop
                                sf_tbtp_viewmodel={props.sf_tbtp}
                                onChanged={() => { SetIsMustReload(true) }}
                                isReadOnly={props.isReadOnly}
                            />
                        </div>

                        <div style={{ marginTop: "1rem" }}>
                            <EditTBTPHachToan
                                sf_tbtp_viewmodel={props.sf_tbtp}
                                onChanged={() => { SetIsMustReload(true) }}
                                isReadOnly={props.isReadOnly}
                            />
                        </div>
                    </div>
                </ScrollView>
                <div className="pop-up-actions">
                    <Button onClick={() => { props.onCancel(isMustReload) }} text="Đóng"></Button> {" "}
                </div>
            </div>
        </DefaultPopup >
    );
}