import { ILoginOIDCRequest } from "../../models/request/account/ILoginOIDCRequest"
import { ILoginData } from "../../models/response/account/login-response"
import { IUserInfoModel } from "../../models/response/account/user-info"
import { IActionTypeBase } from "./IActionTypeBase"

export enum eAuthAcitonTypes {
    GET_USER_START = "AUTH_GET_USER_START",
    GET_USER_SUCCESS = "AUTH_GET_USER_SUCCESS",
    GET_USER_ERROR = "AUTH_GET_USER_ERROR",

    CREATE_LOGIN_TICKET_START = "CREATE_LOGIN_TICKET_START",
    CREATE_LOGIN_TICKET_SUCCESS = "CREATE_LOGIN_TICKET_SUCCESS",
    CREATE_LOGIN_TICKET_ERROR = "CREATE_LOGIN_TICKET_ERROR",

    LOGOUT_START = "LOGOUT_START",
    LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
    LOGOUT_ERROR = "LOGOUT_ERROR",

    LOGIN_START = "ACCOUNT_LOGIN_START",
    LOGIN_SUCCESS = "ACCOUNT_LOGIN_SUCCESS",
    LOGIN_ERROR = "ACCOUNT_LOGIN_ERROR",

}
export interface IAuthGetUserStart extends IActionTypeBase<eAuthAcitonTypes.GET_USER_START, undefined> { }
export interface IAuthGetUserSuccess extends IActionTypeBase<eAuthAcitonTypes.GET_USER_SUCCESS, IUserInfoModel> { }
export interface IAuthGetUserError extends IActionTypeBase<eAuthAcitonTypes.GET_USER_ERROR, string | undefined> { }

export interface IAccountLoginStart extends IActionTypeBase<eAuthAcitonTypes.LOGIN_START, ILoginOIDCRequest> { }
export interface IAccountLoginSuccess extends IActionTypeBase<eAuthAcitonTypes.LOGIN_SUCCESS, ILoginData> { }
export interface IAccountLoginError extends IActionTypeBase<eAuthAcitonTypes.LOGIN_ERROR, string> { }

export interface ILogoutStart extends IActionTypeBase<eAuthAcitonTypes.LOGOUT_START, undefined> { }
export interface ILogoutSuccess extends IActionTypeBase<eAuthAcitonTypes.LOGOUT_SUCCESS, undefined> { }
export interface ILogoutError extends IActionTypeBase<eAuthAcitonTypes.LOGOUT_ERROR, undefined> { }

export type IAuthAction = IAuthGetUserStart | IAuthGetUserSuccess | IAuthGetUserError |
    IAccountLoginStart | IAccountLoginSuccess | IAccountLoginError |
    ILogoutStart | ILogoutSuccess | ILogoutError