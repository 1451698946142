import { sf_mucphaithu_search_rq } from "../models/request/tonghop/sf_mucphaithu_search_rq";
import { sf_mucphaithu_select_byhocsinh_rq } from "../models/request/thu-phi/sf_mucphaithu_select_byhocsinh_rq";
import { sf_mucphaithu_confirm_rq } from "../models/request/tonghop/sf_mucphaithu_confirm_rq";
import { sf_mucphaithu_import_khoanthu_rq } from "../models/request/muc-phai-thu/sf_mucphaithu_import_rq";
import { sf_mucphaithu_huy_chung_tu } from "../models/request/tonghop/sf_mucphaithu_huy_chung_tu";

import { IMucPhaiThuHoanPhiRequest } from "../models/request/muc-phai-thu/IMucPhaiThuAddHoanPhiRequest";

import { apiClient } from "./apiClient";
import { sf_mucphaithu_search_rq1 } from "../models/request/tonghop/sf_mucphaithu_search_rq1";
import { sf_mucphaithu_print } from "../models/request/muc-phai-thu/sf_mucphaithu_print";

export const SF_MUCPHAITHU_SEARCH_API = "sf_mucphaithu/search";
export const SF_MUCPHAITHU_IMPORT_API = "sf_mucphaithu/import_khoan_nop_v2";


export const mucPhaiThuApi = {
    search: (request: sf_mucphaithu_search_rq) => {
        return apiClient.post(`${SF_MUCPHAITHU_SEARCH_API}`, request)
    },
    select_by_hocsinh: (request: sf_mucphaithu_select_byhocsinh_rq) => {
        return apiClient.post('sf_mucphaithu/select_by_hocsinh', request)
    },
    updateNguoiNopTien: (data: any) => {
        return apiClient.post(`sf_mucphaithu/update_nguoi_nop`, data)
    },
    selectById: (id: number) => {
        return apiClient.post(`sf_mucphaithu/select_by_sct?sf_mucphaithu_id=` + id)
    },
    addHoanPhi: (request:IMucPhaiThuHoanPhiRequest)=>{
        return apiClient.post(`sf_mucphaithu/hoan-phi`, request)
        
    },
    selectByHoanPhiGoc: (id: number)=>{
        return apiClient.get(`sf_mucphaithu/hoan-phi/${id}`)
    
    },
    getHoanPhi: (ts_hocsinh_id: number)=>{
        return apiClient.get(`sf_mucphaithu/tien-thua/${ts_hocsinh_id}`)
       
    },
    search_tong_hop: (request: sf_mucphaithu_search_rq) => {
        return apiClient.post(`sf_mucphaithu/search_tonghop`, request)
    },
    search_tong_hop_by_khoannop: (request: sf_mucphaithu_search_rq1) => {
        return apiClient.post(`sf_mucphaithu/search_tonghop`, request)
    },
    update_hinhthuc_xacnhan: (request: sf_mucphaithu_confirm_rq) => {
        return apiClient.post(`sf_mucphaithu/update_hinhthuc_xacnhan`, request)
    },
    getSheets: (file_name_uploaded: string) => apiClient.post(`sf-mucphaithu/get_sheets?file_name_uploaded=${file_name_uploaded}`),
    readFiles_khoanthu: (rq: sf_mucphaithu_import_khoanthu_rq) => apiClient.post(`sf-mucphaithu/read_file_khoan_thu`, rq),
    import_khoanthu: (rq: sf_mucphaithu_import_khoanthu_rq) => apiClient.post(`sf-mucphaithu/import_khoan_thu`, rq),
    readFiles_khoannop: (rq: sf_mucphaithu_import_khoanthu_rq) => apiClient.post(`sf-mucphaithu/read_file_khoan_nop`, rq),
    import_khoannop: (rq: sf_mucphaithu_import_khoanthu_rq) => apiClient.post(`sf-mucphaithu/import_khoan_nop`, rq),
    readFiles_khoannop_v2: (rq: sf_mucphaithu_import_khoanthu_rq) => apiClient.post(`sf_mucphaithu/read_file_khoan_nop_v2`, rq),
    import_khoannop_v2: (rq: sf_mucphaithu_import_khoanthu_rq) => apiClient.post(`sf_mucphaithu/import_khoan_nop_v2`, rq),
    readFiles_hoanphi: (rq: sf_mucphaithu_import_khoanthu_rq) => apiClient.post(`sf-mucphaithu/read_file_hoan_phi`, rq),
    import_hoanphi: (rq: sf_mucphaithu_import_khoanthu_rq) => apiClient.post(`sf-mucphaithu/import_hoan_phi`, rq),
    search_hoan_phi: (request: sf_mucphaithu_search_rq) => {
        return apiClient.post(`sf_mucphaithu/search_hoanphi`, request)
    },
    search_hoan_phi_chi_tiet: (request: sf_mucphaithu_search_rq) => {
        return apiClient.post(`sf_mucphaithu/search_hoanphi_chitiet`, request)
    },
    huy_chung_tu_multiple: (data: sf_mucphaithu_huy_chung_tu) => apiClient.post(`sf_mucphaithu/huy_chung_tu_multiple`, data),
    print_confirm_payment: (data: sf_mucphaithu_print) => apiClient.post(`sf_mucphaithu/print_confirm_payment`, data),
    select_tienthua_by_hocsinh: (request: sf_mucphaithu_select_byhocsinh_rq) => {
        return apiClient.post('sf_mucphaithu/select_tienthua_by_hocsinh', request)
    },
}