import {
    PlusIcon
} from "@primer/octicons-react";
import { ActionList, ActionMenu, Box } from "@primer/react";
import DataGrid, { Column } from 'devextreme-react/data-grid';
import Toolbar, { Item } from "devextreme-react/toolbar";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'semantic-ui-react';
import { importHistoryApi, SF_IMPORT_HISTORY_INSERT_API } from "../../api/importHistoryApi";
import { Button } from '../../components/buttons';
import Text from "../../components/ui/text";
import { useCommonContext } from "../../contexts/common";
import { PageBaseStatus } from '../../models/pageBaseStatus';
import { sf_import_history } from "../../models/response/import-history/sf_import_history";
import { RootState } from '../../state/reducers';
import { ImportHachToanModal } from "../hach-toan-cong-no/import-modal";
import { HoanPhiImportModal } from "../tong-hop/import-hoanphi/HoanPhiImportModal";
import { KhoanNopImportModal } from "../tong-hop/import-khoannop/KhoanNopImportModal";
import { KhoanThuImportModal } from "../tong-hop/import-khoanthu/KhoanThuImportModal";
import styles from "./ImportHistory.module.css";
import DetailModal from "./DetailModal";

const ImportHistoryPage = () => {
    const dispatch = useDispatch();
    const state = useSelector((x: RootState) => x.dotGuiTBTP)
    const [importHistorys, setImportHistorys] = useState<sf_import_history[]>([]);
    const { nam_hoc: param_nam_hoc, dm_coso_id } = useSelector(
        (x: RootState) => x.common
    );
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
    const [dataPreview, setDataPreview] = useState<any>([]);
    const { checkAccesiableTo } = useCommonContext();
    const [isShowImportKhoanThuModal, setIsShowImportKhoanThuModal] = useState(false);
    const [isShowImportKhoanNopModal, setIsShowImportKhoanNopModal] = useState(false);
    const [isShowImportQuyetToanModal, setIsShowImportQuyetToanModal] = useState(false);
    const [isShowImportHoanPhiModal, setIsShowImportHoanPhiModal] = useState(false);

    const grid = useRef<any>();
    useEffect(() => {
        handleReloadImportHistorys();
    }, [])
    const handleReloadImportHistorys = async () => {
        const res = await importHistoryApi.selectAll();
        if (res.is_success) {
            setImportHistorys(res.data)
        }
    }
    const isAccessibleLock = useMemo(() => {
        if (!checkAccesiableTo(SF_IMPORT_HISTORY_INSERT_API, 'POST')) return false;
        return true;
    }, [checkAccesiableTo]);
    const handleRefresh = () => {
        handleReloadImportHistorys();
        grid.current.instance.state(null);
    }

    const handleExport = useCallback(() => {
        if (grid && grid.current && grid.current.instance) {
            grid.current.instance.exportToExcel();
        }
    }, [grid])
    const previewRender = (data: any) => {
        return (
            <div>
                <span
                    style={{ cursor: 'pointer', fontSize: '18px' }}
                    onClick={() => {
                        btnPreview_click(data.data);
                    }}
                >
                    <i aria-hidden="true" className="fa fa-eye" style={{ color: '#1c2845' }}></i>{' '}
                </span>
            </div>
        );
    };
    const btnPreview_click = (data: sf_import_history) => {
        handleReloadPreviewData(data);
        setIsShowPreview(true);
    };
    const handleShowImportKhoanThu = () => {
        setIsShowImportKhoanThuModal(true)

    }
    const handleShowImportKhoanNop = () => {
        setIsShowImportKhoanNopModal(true)

    }
    const handleShowImportHoanPhi = () => {
        setIsShowImportHoanPhiModal(true)

    }
    const handleShowImportQuyetToan = () => {
        setIsShowImportQuyetToanModal(true)

    }
    const handleReloadPreviewData = async (data: any) => {
        debugger
        const res = await importHistoryApi.read_import_file(data.url);
        if (res.is_success) {
            setDataPreview(res.data)
        }
    }
    return (<>
        <React.Fragment>
            <Box sx={{ ml: 2 }}>
                <Transition
                    animation="scale"
                    visible={true}
                    duration={100}
                    transitionOnMount={true}
                >
                    <div>
                        <div className="row">
                            <div className="col-md-12 page-toolbar">
                                <Toolbar className="bg-background">
                                    <Item location={"before"}><span className={styles.caption}>Lịch sử import</span></Item>
                                    <Item location="after"
                                        locateInMenu="never">
                                        <ActionMenu>
                                            <ActionMenu.Button style={{ height: 28 }}>
                                                Thao tác
                                            </ActionMenu.Button>
                                            <ActionMenu.Overlay width="auto">
                                                <ActionList showDividers>
                                                    <ActionList.Group>
                                                        <ActionList.Item
                                                            onSelect={handleShowImportKhoanThu}
                                                        // disabled={!isAccessibleDelete}
                                                        >
                                                            <ActionList.LeadingVisual >
                                                                <PlusIcon />
                                                            </ActionList.LeadingVisual>
                                                            <Text text={`Import khoản thu`} />
                                                        </ActionList.Item>
                                                        <ActionList.Item
                                                            onSelect={handleShowImportKhoanNop}
                                                        // disabled={!isAccessiblePdf}
                                                        >
                                                            <ActionList.LeadingVisual >
                                                                <PlusIcon />
                                                            </ActionList.LeadingVisual>
                                                            <Text text={`Import khoản nộp`} />
                                                        </ActionList.Item>
                                                        <ActionList.Item
                                                            onSelect={handleShowImportHoanPhi}
                                                        // disabled={!isAccessiblePdf}
                                                        >
                                                            <ActionList.LeadingVisual >
                                                                <PlusIcon />
                                                            </ActionList.LeadingVisual>
                                                            <Text text={`Import hoàn phí`} />
                                                        </ActionList.Item>
                                                        <ActionList.Item
                                                            onSelect={handleShowImportQuyetToan}
                                                        // disabled={!isAccessiblePdf}
                                                        >
                                                            <ActionList.LeadingVisual >
                                                                <PlusIcon />
                                                            </ActionList.LeadingVisual>
                                                            <Text text={`Import quyết toán`} />
                                                        </ActionList.Item>
                                                        {/* <ActionList.Item
                                                            onSelect={handleExport}
                                                            disabled={false}
                                                        >
                                                            <ActionList.LeadingVisual >
                                                                <DownloadIcon />
                                                            </ActionList.LeadingVisual>
                                                            <Text text={`Export`} />
                                                        </ActionList.Item> */}
                                                    </ActionList.Group>

                                                </ActionList>
                                            </ActionMenu.Overlay>
                                        </ActionMenu>
                                    </Item>
                                    <Item location="after"
                                        locateInMenu="never">
                                        <Button
                                            type="normal"
                                            icon="fas fa-redo"
                                            text="Refresh"
                                            onClick={handleRefresh}
                                            isLoading={state.status === PageBaseStatus.is_loading}
                                        />{' '}
                                    </Item>
                                    <Item location="after"
                                        locateInMenu="never">
                                        <Button
                                            type="normal"
                                            icon="fas fa-file"
                                            text="Export"
                                            onClick={handleExport}
                                            isLoading={state.status === PageBaseStatus.is_loading}
                                        />{' '}
                                    </Item>

                                </Toolbar>
                            </div>
                            <div className="col-md-12">

                                <DataGrid
                                    ref={grid}
                                    keyExpr="id"
                                    dataSource={importHistorys}
                                    columnHidingEnabled={false}
                                    columnAutoWidth={true}
                                    showBorders={true}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    rowAlternationEnabled={true}
                                    allowColumnResizing={true}
                                    columnResizingMode={"nextColumn"}
                                    hoverStateEnabled={true}
                                    focusedRowEnabled={false}
                                    height={window.innerHeight - 150}
                                    width={window.innerWidth - 220}

                                    className={"table-noborder-left table-white-space"}
                                    noDataText="Không có dữ liệu"
                                    wordWrapEnabled={true}
                                    loadPanel={{ enabled: false }}
                                    paging={{ enabled: false }}

                                >
                                    <Column caption={'Thao tác'} width={100} alignment="center"
                                        cellRender={(cell: any) => {
                                            return (
                                                <div>
                                                    {(!cell.data.is_locked && isAccessibleLock) &&
                                                        <span
                                                            style={{ cursor: 'pointer', color: 'green' }}
                                                            onClick={() => {
                                                                // handleBtnEditClicked(cell.data);
                                                            }}
                                                        >
                                                            <i aria-hidden='true' className='fas fa-edit'></i>{' '}
                                                        </span>
                                                    }
                                                </div>
                                            );
                                        }}
                                    />
                                    <Column dataField="nam_hoc" caption="Năm học" width={100} />,
                                    <Column dataField="import_menu" caption="Chức năng" width={200} />,
                                    <Column dataField="file_name" caption="Tên file" minWidth={200} cellRender={(cell) => {
                                        return (
                                            <div style={{ whiteSpace: "pre-line" }}>{cell.data.file_name}</div>
                                        )
                                    }} />,
                                    <Column dataField="url" alignment={'center'} caption="View" minWidth={100} cellRender={previewRender} />,
                                    <Column dataField="import_fullname" alignment={'center'} caption="Người import" minWidth={100} />,
                                    <Column dataField="import_date" caption="Ngày import" type={'date'}
                                        dataType="datetime"
                                        format="dd/MM/yyyy HH:mm:ss" minWidth={100} />,
                                </DataGrid>

                            </div>
                            {isShowImportKhoanThuModal && (
                                <KhoanThuImportModal
                                    nam_hoc={param_nam_hoc}
                                    dm_coso_id={dm_coso_id}
                                    animationOf="#btnImport"
                                    onClose={() => {
                                        setIsShowImportKhoanThuModal(false);
                                        handleRefresh();
                                    }}
                                    onSuccess={() => {
                                        setIsShowImportKhoanThuModal(false);
                                        handleRefresh();
                                    }}
                                />
                            )}
                            {isShowImportKhoanNopModal && (
                                <KhoanNopImportModal
                                    nam_hoc={param_nam_hoc}
                                    dm_coso_id={dm_coso_id}
                                    animationOf="#btnImport"
                                    onClose={() => {
                                        setIsShowImportKhoanNopModal(false);
                                        handleRefresh();
                                    }}
                                    onSuccess={() => {
                                        setIsShowImportKhoanNopModal(false);
                                        handleRefresh();

                                    }}
                                />
                            )}
                            {isShowImportHoanPhiModal && (
                                <HoanPhiImportModal
                                    nam_hoc={param_nam_hoc}
                                    dm_coso_id={dm_coso_id}
                                    animationOf="#btnImport"
                                    onClose={() => {
                                        setIsShowImportHoanPhiModal(false);
                                        handleRefresh();
                                    }}
                                    onSuccess={() => {
                                        setIsShowImportHoanPhiModal(false);
                                        handleRefresh();

                                    }}
                                />
                            )}
                            {isShowImportQuyetToanModal && (
                                <ImportHachToanModal
                                    nam_hoc={param_nam_hoc}
                                    animationOf="#btnInsert"
                                    onClose={() => {
                                        setIsShowImportQuyetToanModal(false); handleRefresh();
                                    }}
                                    onSuccess={() => {
                                        setIsShowImportQuyetToanModal(false);
                                        handleRefresh();
                                    }}

                                />
                            )}
                            {isShowPreview && (
                                <DetailModal
                                    dataSource={dataPreview}
                                    animationOf="#btnPreview"
                                    title='Chi tiết'
                                    onClose={() => {
                                        setIsShowPreview(false);
                                        handleRefresh();
                                    }}

                                />
                            )}
                        </div>
                    </div>
                </Transition>
            </Box>
        </React.Fragment>
    </>)
}
export default ImportHistoryPage;