import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { Button } from "../buttons";
import { AnimationPopup, DefaultPopup } from '../modal';
import styles from './confirm.module.css';
type AnimationConfrimProps = {
    icon?: string,
    text: string,
    text_confirm_button?: string,
    text_close_button?: string,
    onConfirmed: () => void,
    onClose: () => void,
    type: "danger" | "primary" | "success",
    size?: "small" | "large",
    is_saving?: boolean | false,
    animationOf: string,
}
type DefaultConfrimProps = {
    icon?: string,
    text: string,
    text_confirm_button?: string,
    text_close_button?: string,
    onConfirmed: () => void,
    onClose: () => void,
    type: "danger" | "primary" | "success",
    size?: "small" | "large",
    is_saving?: boolean | false
}
const AnimationConfirm = (props: AnimationConfrimProps) => {
    const onClose = useCallback(() => {
        window.animationHidePopup(props.animationOf || "")
        setTimeout(() => {
            props.onClose();
        }, 500);
    }, [props.animationOf])
    return (
        <AnimationPopup
            maxWidth={(props.size || "small") === "small" ? 300 : 500}
            minHeight={250}
            animationOf={props.animationOf}
            // mustClose={closePopUp}
            title='Thông báo'
            onClose={onClose}
        >
            <div className={styles.container}>
                <div className={clsx(styles.icon, styles[`icon_${props.type}`])}>
                    <i className={props.icon || (props.type === "danger" ? "fas fa-trash-alt" : "fas fa-question")}></i>
                </div>
                <div className={styles.text}>
                    {props.text}
                </div>
                <div className={styles.actions}>
                    <Button text={props.text_close_button || "Đóng"}  icon="fa fa-times" onClick={onClose} /> {' '}
                    <Button isLoading={props.is_saving || false} type={'normal'}  icon="fas fa-check-square" text={props.text_confirm_button || "Xóa"} onClick={props.onConfirmed} />
                </div>

            </div >
        </AnimationPopup>
    );
}
const DefaultConfirm = (props: DefaultConfrimProps) => {

    return (
        <DefaultPopup
            maxWidth={(props.size || "small") == "small" ? 300 : 500}
            minHeight={250}
        >
            <div className={styles.container}>
                <div className={clsx(styles.icon, styles[`icon_${props.type}`])}>
                    <i className={props.icon || (props.type === "danger" ? "fas fa-trash-alt" : "fas fa-question")}></i>
                </div>
                <div className={styles.text}>
                    {props.text}
                </div>
                <div className={styles.actions}>
                    <Button text={props.text_close_button || "Đóng"} icon="fa fa-times" onClick={props.onClose} /> {' '}
                    <Button isLoading={props.is_saving || false} type={'normal'}  icon="fas fa-check-square" text={props.text_confirm_button || "Xóa"} onClick={props.onConfirmed} />
                </div>

            </div >
        </DefaultPopup>
    );
}


export { DefaultConfirm, AnimationConfirm };

