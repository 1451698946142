import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { apiWrapper } from "../../api/apiWrapper";
import { IBaseResponeModel } from "../../models/response/base-response";
import { eCategorySourceActionTypeIds } from "../action-types/ICategorySourceActionTypes";
import { actions } from './../actions/actionsWrapper';

export function* categorySourceSaga(): any {
    yield takeLatest(eCategorySourceActionTypeIds.DM_TRUONG_LOAD_START, loadTruongWorker)
    yield takeLatest(eCategorySourceActionTypeIds.DM_NAMHOC_LOAD_START, loadNamHocWorker)
    yield takeLatest(eCategorySourceActionTypeIds.DM_KHOI_LOAD_START, loadKhoiWorker)
    yield takeLatest(eCategorySourceActionTypeIds.DM_HE_LOAD_START, loadHeWorker)
    yield takeLatest(eCategorySourceActionTypeIds.DM_TRUONG_KHOI_HE_LOAD_START, loadTruongKhoiHeWorker)
    yield takeLatest(eCategorySourceActionTypeIds.DM_TRUONG_TREEVIEW_LOAD_START, loadTruongTreeViewWorker)
    yield takeEvery(eCategorySourceActionTypeIds.SF_HINHTHUCNOPBOM_LOAD_START, loadHinhThucNopBomWorker)

}
function* loadTruongWorker(): any {
    const res: IBaseResponeModel = yield call(apiWrapper.categorySource.dm_truongs)
    if (res.is_success) {
        yield put(actions.categorySource.loadDmTruongSuccess(res.data))
    }
}
function* loadNamHocWorker(): any {
    const res: IBaseResponeModel = yield call(apiWrapper.categorySource.dm_namhocs)
    if (res.is_success) {
        yield put(actions.categorySource.loadNamHocSuccess(res.data))
    }
}
function* loadKhoiWorker(): any {
    const res: IBaseResponeModel = yield call(apiWrapper.categorySource.dm_khois)
    if (res.is_success) {
        yield put(actions.categorySource.loadKhoiSucess(res.data))
    }
}
function* loadHeWorker(): any {
    const res: IBaseResponeModel = yield call(apiWrapper.categorySource.dm_hes)
    if (res.is_success) {
        yield put(actions.categorySource.loadHeSuccess(res.data))
    }
}

function* loadTruongKhoiHeWorker(): any {
    const res: IBaseResponeModel = yield call(apiWrapper.categorySource.SelectTruongKhoiHes)
    if (res.is_success) {
        yield put(actions.categorySource.loadDmTruongKhoiHeSuccess(res.data))
    }
}
function* loadTruongTreeViewWorker(): any {
    const res: IBaseResponeModel = yield call(apiWrapper.categorySource.dm_truong_treeview)
    if (res.is_success) {
        yield put(actions.categorySource.loadDmTruongKhoiHeTreeViewSuccess(res.data))
    }
}
function* loadHinhThucNopBomWorker(): any {
    const res: IBaseResponeModel = yield call(apiWrapper.categorySource.sf_hinhthucnopboms)
    if (res.is_success) {
        yield put(actions.categorySource.loadSfHinhThucNopBomSuccessAction(res.data))
    }
}
