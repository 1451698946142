import { NavList } from '@primer/react';
import { useSelector } from 'react-redux';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { RootState } from '../../state/reducers';
const NavItem = ({ to, children }: any) => {
    const resolved = useResolvedPath(to);
    const isCurrent = useMatch({ path: resolved.pathname, end: true });
    const ariaCurrentAttr = isCurrent ? 'page' : undefined;

    return (
        <NavList.Item as={Link} to={to} aria-current={ariaCurrentAttr}>
            {children}
        </NavList.Item>
    );
};

const Menu = () => {
    const { user_info } = useSelector((x: RootState) => x.auth)
    const { menu_group_id } = useSelector((x: RootState) => x.common)
    return (
        <NavList>
            {user_info?.menus.filter(menu => menu.id === menu_group_id).map(menu => (
                <NavList.Group title={menu.name} key={menu.id}>
                    {menu.items.map(subItem => (
                        <NavItem to={`/${subItem.path}`} key={subItem.id}>
                            <NavList.LeadingVisual>
                                <i className={`${subItem.icon}`}></i>
                            </NavList.LeadingVisual>
                            {subItem.name}
                            {subItem.items.length > 0 && (
                                <NavList.SubNav>
                                    {subItem.items.map(subSubItem => (
                                        <NavItem to={`/${subSubItem.path}`} key={subSubItem.id}>
                                            <NavList.LeadingVisual>
                                                <i className={`${subSubItem.icon}`}></i>
                                            </NavList.LeadingVisual>
                                            {subSubItem.name}
                                        </NavItem>
                                    ))}
                                </NavList.SubNav>
                            )}
                        </NavItem>
                    ))}
                </NavList.Group>
            ))}
        </NavList>
    );
    
};

export default Menu;