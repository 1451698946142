
import { dm_he } from "../../models/response/category/dm_he";
import { dm_khoi } from "../../models/response/category/dm_khoi";
import { dm_namhoc } from "../../models/response/category/dm_namhoc";
import { dm_truong } from "../../models/response/category/dm_truong";
import { dm_truong_khoi_he } from "../../models/response/category/dm_truong_khoi_he";
import { sf_hinhthucnopbom } from "../../models/response/category/sf_hinhthucnopbom";
import {
    eCategorySourceActionTypeIds, IDmTruongTreeViewLoadStartAction, IDmTruongTreeViewLoadSuccessAction, IHeLoadStart, IHeLoadSuccess,
    IKhoiLoadStart, IKhoiLoadSuccess, INamHocLoadStart,
    INamHocLoadSuccess, ISfHinhThucNopBomLoadStartAction, ISfHinhThucNopBomLoadSuccessAction, ITruongKhoiHeLoadStart, ITruongKhoiHeLoadSuccess, ITruongLoadStart, ITruongLoadSuccess
} from "../action-types/ICategorySourceActionTypes";
import { dm_truong_treeview } from "../page-state-model/categorySourceState";

export const categorySourceActions = {

    loadDmTruongStart: (): ITruongLoadStart => {
        return {
            type: eCategorySourceActionTypeIds.DM_TRUONG_LOAD_START
        }
    },
    loadDmTruongSuccess: (payload: dm_truong[]): ITruongLoadSuccess => {
        return {
            type: eCategorySourceActionTypeIds.DM_TRUONG_LOAD_SUCCESS,
            payload: payload
        }
    },
    loadHeStart: (): IHeLoadStart => {
        return {
            type: eCategorySourceActionTypeIds.DM_HE_LOAD_START
        }
    },
    loadHeSuccess: (payload: dm_he[]): IHeLoadSuccess => {
        return {
            type: eCategorySourceActionTypeIds.DM_HE_LOAD_SUCCESS,
            payload: payload
        }
    },
    loadKhoiStart: (): IKhoiLoadStart => {
        return {
            type: eCategorySourceActionTypeIds.DM_KHOI_LOAD_START
        }
    },
    loadKhoiSucess: (payload: dm_khoi[]): IKhoiLoadSuccess => {
        return {
            type: eCategorySourceActionTypeIds.DM_KHOI_LOAD_SUCCESS,
            payload: payload
        }
    },

    loadNamHocStart: (): INamHocLoadStart => {
        return {
            type: eCategorySourceActionTypeIds.DM_NAMHOC_LOAD_START
        }
    },
    loadNamHocSuccess: (payload: dm_namhoc[]): INamHocLoadSuccess => {
        return {
            type: eCategorySourceActionTypeIds.DM_NAMHOC_LOAD_SUCESS,
            payload: payload
        }
    },

    loadDmTruongKhoiHeStart: (): ITruongKhoiHeLoadStart => {
        return {
            type: eCategorySourceActionTypeIds.DM_TRUONG_KHOI_HE_LOAD_START
        }
    },
    loadDmTruongKhoiHeSuccess: (payload: dm_truong_khoi_he[]): ITruongKhoiHeLoadSuccess => {
        return {
            type: eCategorySourceActionTypeIds.DM_TRUONG_KHOI_HE_LOAD_SUCESS,
            payload: payload
        }
    },
    loadSfHinhThucNopBomStartAction: (): ISfHinhThucNopBomLoadStartAction => {
        return {
            type: eCategorySourceActionTypeIds.SF_HINHTHUCNOPBOM_LOAD_START
        }
    },
    loadSfHinhThucNopBomSuccessAction: (payload: sf_hinhthucnopbom[]): ISfHinhThucNopBomLoadSuccessAction => {
        return {
            type: eCategorySourceActionTypeIds.SF_HINHTHUCNOPBOM_LOAD_SUCCESS,
            payload: payload
        }
    },
    loadDmTruongKhoiHeTreeViewStart: (): IDmTruongTreeViewLoadStartAction => {
        return {
            type: eCategorySourceActionTypeIds.DM_TRUONG_TREEVIEW_LOAD_START
        }
    },
    loadDmTruongKhoiHeTreeViewSuccess: (payload: dm_truong_treeview[]): IDmTruongTreeViewLoadSuccessAction => {
        return {
            type: eCategorySourceActionTypeIds.DM_TRUONG_TREEVIEW_LOAD_SUCCESS,
            payload: payload
        }
    },
}