import { AuthProvider, useAuth } from 'oidc-react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/reducers';
const DoLogout = () => {
    const auth = useAuth();
    const { appConfig } = useSelector((x: RootState) => x.appConfig)
    useEffect(() => {
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        if (appConfig) {
            auth.signOutRedirect()
        }
    }, [appConfig])
    return (
        <div>
            Logout
        </div>
    );
}
const LogoutPage = () => {
   
    const { appConfig } = useSelector((x: RootState) => x.appConfig)
    
    const oidcConfig: any = useMemo(() => {
        if (!appConfig) {
            // debugger
        }
        return {
            authority: appConfig?.OIDC_AUTHORITY,
            clientId: appConfig?.OIDC_CLIENT_ID,
            redirectUri: appConfig?.OIDC_REDIRECT_URI,
            clientSecret: appConfig?.OIDC_CLIENT_SECRET,
            // userStore: new WebStorageStateStore({
            //     store: localStorage
            // })
        };
    }, [appConfig])
   
    return (
        <div>
            <AuthProvider {...oidcConfig} >
                <DoLogout />
            </AuthProvider>
        </div>
    );
};

export default LogoutPage;