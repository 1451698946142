import { LazyPage } from '../components/loadable';

export { default as HomePage } from './home/home';
export const BaoCaoPage = LazyPage(import('./bao-cao'))
export const BOMPage = LazyPage(import('./bom'))
export const BOMNgsPage = LazyPage(import('./bom-ngs'))
export const CongNoPage = LazyPage(import('./cong-no'))
export const DayOffPage = LazyPage(import('./dayoff'))
export const DotGuiTBTPPage = LazyPage(import('./dot-gui-tbtp'))
export const DuKienPage = LazyPage(import('./du-kien'))
export const GanMienGiamPage = LazyPage(import('./gan-mien-giam'))
export const HachToanCongNoPage = LazyPage(import('./hach-toan-cong-no/HachToanCongNoPage'))
export const HinhThucMienGiamPage = LazyPage(import('./hinh-thuc-mien-giam'))
export const HinhThucThanhToanPage = LazyPage(import('./hinh-thuc-thanh-toan'))
export const HoanPhiPage = LazyPage(import('./hoan-phi'))
export const HoanPhiHocSinhPage = LazyPage(import('./hoan-phi-hoc-sinh'))
export const HoanTraTienAnPage = LazyPage(import('./hoan-tra-tien-an'))
export const HocSinhProfilePage = LazyPage(import('./hoc-sinh'))
export const DanhSachKhoanNopPage = LazyPage(import('./khoan-nop'))
export const KhoanNopGroupPage = LazyPage(import('./khoan-nop-group'))
export const MucThuPhiPage = LazyPage(import('./muc-thu-phi'))
export const MucThuPhiPageNgs = LazyPage(import('./muc-thu-phi-ngs'))
export const MucThuPhiQuickInputPage = LazyPage(import('./muc-thu-phi-ngs-quick-input'))
export const NhomKhoanNopPage = LazyPage(import('./nhom-khoan-nop/NhomKhoanNopPage'))
export const SaleSetEditPage = LazyPage(import('./sale-set-edit-page'))
export const SalesetRegisterDichVuPage = LazyPage(import('./saleset-register-dichvu'))
export const DangKyDichVuPage = LazyPage(import('./register-dichvu'))
export const SalesetKhoanNopGroupTypePage = LazyPage(import('./sf-saleset-khoannopgroup-type'))
export const TBTPPage = LazyPage(import('./tbtp/TBTPPage'))
export const ThuPhiPage = LazyPage(import('./thu-phi/ThuPhiPage'))
export const TongHopPage = LazyPage(import('./tong-hop/TongHopPage'))
export const TongHopChiTietPage = LazyPage(import('./tong-hop-chi-tiet/TongHopChiTietPage'))
export const TongHopCongNoPage = LazyPage(import('./tong-hop-cong-no/TongHopCongNoPage'))
export const TongHopGanMienGiamPage = LazyPage(import('./tong-hop-gan-mien-giam'))
export const TongHopHoanPhiPage = LazyPage(import('./tong-hop-hoan-phi/TongHopHoanPhiPage'))
export const BaoCaoChiTietCongNoPage = LazyPage(import('./tong-hop-hoc-phi'))
export const TongHopQuyetToanPage = LazyPage(import('./tong-hop-quyet-toan'))
export const TongHopChamAnPage = LazyPage(import('./tong-hop-cham-an'))
export const ImportHistoryPage = LazyPage(import('./import-history'))

