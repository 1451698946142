import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    Sorting,
    Summary,
    TotalItem,
} from "devextreme-react/data-grid";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Transition } from "semantic-ui-react";
import * as XLSX from 'xlsx';
import { baoCaoHocPhiApi } from '../../api/baoCaoHocPhiApi';
import { khoanNopGroupApi } from '../../api/khoanNopGroupApi';
import { Button } from "../../components/buttons";
import { ComboboxHe } from "../../components/combobox-he";
import ComboboxKhoi from "../../components/combobox-khoi";
import { ComboboxTruong } from "../../components/combobox-truong";
import { sf_khoannop_group } from '../../models/response/category/sf_khoannop_group';
import { RootState } from "../../state/reducers";
import "./BaoCaoChiTietCongNoPage.scss";

const BaoCaoChiTietCongNoPage = () => {

    const [dm_truong_id, set_dm_truong_id] = useState<number>(0);
    const [dm_khoi_id, set_dm_khoi_id] = useState<number>(0);
    const [dm_he_id, set_dm_he_id] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { nam_hoc, dm_coso_id } = useSelector((x: RootState) => x.common);
    const [dataSourceAll, setDataSourceAll] = useState([]);
    const [sf_khoannop_groups, set_sf_khoannop_groups] = useState<sf_khoannop_group[]>([]);
    const grid = useRef<any>();

    const handleSearch = () => {
        handleReloadReportAll();
    };

    useEffect(() => {
        handleReloadKhoanNopGroup();
    }, [])
    const handleReloadKhoanNopGroup = async () => {
        const res = await khoanNopGroupApi.select_all()
        if (res.is_success) {
            const temp = res.data.filter((x: any) => x.dm_coso_id == dm_coso_id).sort((a: any, b: any) => a.idx_report - b.idx_report)
            set_sf_khoannop_groups(temp)
        }
    }
    const handleReloadReportAll = async () => {
        setIsLoading(true)
        const res = await baoCaoHocPhiApi.reportCongNoAll({
            nam_hoc: nam_hoc,
            dm_he_id: dm_he_id,
            dm_khoi_id: dm_khoi_id,
            dm_truong_id: dm_truong_id,
            dm_coso_id: dm_coso_id
        })
        if (res.is_success) {
            setDataSourceAll(res.data)
        }
        setIsLoading(false)

    }

    const handleExportExcel = async () => {
        if (dataSourceAll !== undefined && dataSourceAll.length > 0) {
            setIsLoading(true);


            // Tạo worksheet từ dữ liệu gốc
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataSourceAll);

            // Thay thế các header mong muốn
            const headerMap: { [key: string]: string } = {
                "ho_ten": "Họ tên",
                "ma_hs": "Mã HS",
                "ngay_sinh": "Ngày sinh",
                "ten_khoi": "Khối",
                "ten_he": "Hệ",
                "QTBS_Noidung": "Nội dung",
                "QTBS_TongTien_PN": "Tổng số tiền",
                "QTHT_Noidung_MG": "Nội dung",
                "QTHT_TongTien": "Tổng số tiền",
                "Tong_cac_khoan_phai_nop_PN": "Tổng các khoản phải nộp",
                "Tong_giam_tru_hoan_tra_MG": "Tổng giảm trừ/hoàn trả",
                "Tong_con_phai_dong": "Tổng còn phải đóng",
                "Cac_lan_thuc_nop": "Các lần thực nộp",
                "Tong_thuc_nop": "Tổng thực nộp",
                "Chenh_lech_thua": "Chênh lệch thừa",
                "Chenh_lech_thieu": "Chênh lệch thiếu",
            };

            // Duyệt qua các header trong worksheet để thay thế tên
            const range = XLSX.utils.decode_range(worksheet['!ref']!);
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellAddress = XLSX.utils.encode_col(C) + "1"; // Địa chỉ của header trong hàng đầu tiên (A1, B1, C1,...)
                const cell = worksheet[cellAddress];
                if (cell && headerMap[cell.v]) {
                    cell.v = headerMap[cell.v]; // Thay thế tên header
                }
            }

            // Tạo workbook và thêm worksheet vào
            const workbook: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "ReportData");
            setIsLoading(false);

            // Xuất workbook ra file Excel
            XLSX.writeFile(workbook, "ReportCongNo.xlsx");
        }
    };

    const handleExport = () => {
        handleExportExcel();
    };
    const dynamicColumns = useMemo(() => {
        return sf_khoannop_groups.map(x => {
            return (
                <Column caption={x.ten_khoan_nop_group} key={x.id} alignment={"center"}>
                    <Column width={100} dataField={`KGR_DM_${x.id}`} format={{ formatter: (value: any) => value.toLocaleString('de-de') }} caption={"Tiêu chuẩn"} />
                    <Column width={100} dataField={`KGR_MG_${x.id}`} format={{ formatter: (value: any) => value.toLocaleString('de-de') }} caption={"Miễn giảm"} />
                    <Column width={100} dataField={`KGR_PN_${x.id}`} format={{ formatter: (value: any) => value.toLocaleString('de-de') }} caption={"Phải nộp"} />
                    <Column width={100} dataField={`KGR_DN_${x.id}`} format={{ formatter: (value: any) => value.toLocaleString('de-de') }} caption={"Đã nộp"} />
                    <Column width={100} dataField={`KGR_CT_${x.id}`} format={{ formatter: (value: any) => value.toLocaleString('de-de') }} caption={"Còn thiếu"} />
                </Column>

            );
        });
    }, [sf_khoannop_groups]);
    const summaryItems = useMemo(() => {
        return sf_khoannop_groups.flatMap(x => [
            <TotalItem
                key={`Total_KGR_DM_${x.id}`}
                column={`KGR_DM_${x.id}`}
                summaryType="sum"
                valueFormat=",##0"
                displayFormat="{0}"
            />,
            <TotalItem
                key={`Total_KGR_MG_${x.id}`}
                column={`KGR_MG_${x.id}`}
                summaryType="sum"
                valueFormat=",##0"
                displayFormat="{0}"
            />,
            <TotalItem
                key={`Total_KGR_PN_${x.id}`}
                column={`KGR_PN_${x.id}`}
                summaryType="sum"
                valueFormat=",##0"
                displayFormat="{0}"
            />,
            <TotalItem
                key={`Total_KGR_DN_${x.id}`}
                column={`KGR_DN_${x.id}`}
                summaryType="sum"
                valueFormat=",##0"
                displayFormat="{0}"
            />,
            <TotalItem
                key={`Total_KGR_CT_${x.id}`}
                column={`KGR_CT_${x.id}`}
                summaryType="sum"
                valueFormat=",##0"
                displayFormat="{0}"
            />
        ]);
    }, [sf_khoannop_groups]);



    return (
        <div>
            <Transition
                animation="scale"
                visible={true}
                duration={100}
                transitionOnMount={true}
            >
                <div className="row">
                    <div className="col-md-12 padding-top-1rem padding-left-2rem">
                        <h4>Báo cáo chi tiết công nợ</h4>
                        <div style={{ display: "flex" }}>
                            <div className="form-group" style={{ marginRight: "5px" }}>
                                <ComboboxTruong
                                    stylingMode="filled"
                                    isReadonly={isLoading}
                                    value={dm_truong_id}
                                    isShowClearButton={true}
                                    onValueChanged={(value) => {
                                        set_dm_truong_id(value || 0);
                                    }}
                                ></ComboboxTruong>
                            </div>
                            <div className="form-group" style={{ marginRight: "5px" }}>
                                <ComboboxKhoi
                                    width={150}
                                    stylingMode="filled"
                                    isReadonly={isLoading}
                                    value={dm_khoi_id}
                                    dm_truong_id={dm_truong_id}
                                    isShowClearButton={true}
                                    onValueChanged={(e: any) => {
                                        set_dm_khoi_id(e || 0);
                                    }}
                                ></ComboboxKhoi>
                            </div>
                            <div className="form-group" style={{ marginRight: "5px" }}>
                                <ComboboxHe
                                    width={200}
                                    stylingMode="filled"
                                    isReadonly={isLoading}
                                    value={dm_he_id}
                                    dm_truong_id={dm_truong_id}
                                    dm_khoi_id={dm_khoi_id}
                                    isShowClearButton={true}
                                    onValueChanged={(value) => {
                                        set_dm_he_id(value || 0);
                                    }}
                                ></ComboboxHe>
                            </div>
                            <div className="form-group">
                                <Button
                                    isLoading={isLoading}
                                    text="Tổng hợp"
                                    icon="fas fa-redo"

                                    type="normal"
                                    onClick={handleSearch}
                                />{" "}
                                <Button
                                    isDisabled={dataSourceAll === undefined || dataSourceAll.length === 0}
                                    isLoading={isLoading}
                                    text="Export"
                                    icon="fas fa-file-excel"
                                    type="normal"
                                    onClick={handleExport}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 padding-top-1rem padding-left-2rem">
                        <div className="padding-top-5">
                            <DataGrid
                                ref={grid}
                                dataSource={dataSourceAll}
                                key={"id"}
                                columnHidingEnabled={false}
                                columnAutoWidth={true}
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={true}
                                rowAlternationEnabled={true}
                                allowColumnResizing={true}
                                columnResizingMode={"nextColumn"}
                                hoverStateEnabled={true}
                                focusedRowEnabled={false}
                                height={window.innerHeight - 210}
                                className={"table table-white-space"}
                                noDataText={
                                    isLoading
                                        ? "Đang tải dữ liệu ..."
                                        : "Không có dữ liệu"
                                }
                                wordWrapEnabled={true}
                            >
                                <FilterRow visible={true} />
                                <Sorting mode="multiple" />
                                <Paging defaultPageSize={50} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={false} />

                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[10, 20, 50, 200, 500]}
                                    infoText="Trang {0} /{1} ({2} dòng)"
                                    showInfo={true}
                                />
                                <Column dataField={"ma_hs"} caption={"Mã HS"} minWidth={100} fixed />
                                <Column dataField={"ho_ten"} caption={"Họ tên"} minWidth={200} fixed />
                                <Column
                                    dataField="ngay_sinh"
                                    dataType={"date"}
                                    format={"dd-MM-yyyy"}
                                    caption="Ngày sinh"
                                    width={130}
                                ></Column>
                                <Column dataField="ten_khoi" caption="Khối" fixed width={100} />
                                <Column dataField="ten_he" caption="Hệ" fixed width={150} />
                                {dynamicColumns}
                                <Column caption={'Quyết toán thu bổ sung'} alignment={'center'}>
                                    <Column dataField={"QTBS_Noidung"} caption={"Nội dung"} minWidth={250} />
                                    <Column dataField={"QTBS_TongTien_PN"} caption={"Tổng số tiền"} format={{ formatter: (value: any) => value.toLocaleString('de-de') }} width={150} />
                                </Column>
                                <Column caption={'Quyết toán thu hoàn trả'} alignment={'center'}>
                                    <Column dataField={"QTHT_Noidung_MG"} caption={"Nội dung"} minWidth={250} />
                                    <Column dataField={"QTHT_TongTien"} caption={"Tổng số tiền"} format={{ formatter: (value: any) => value.toLocaleString('de-de') }} width={150} />

                                </Column>
                                <Column caption={'Tổng hợp'} alignment={'center'}>
                                    <Column dataField={"Tong_cac_khoan_phai_nop_PN"} caption={"Tổng các khoản phải nộp"} minWidth={150} format={{ formatter: (value: any) => value.toLocaleString('de-de') }} />
                                    <Column dataField={"Tong_giam_tru_hoan_tra_MG"} caption={"Tổng giảm trừ/hoàn trả"} minWidth={150} format={{ formatter: (value: any) => value.toLocaleString('de-de') }} />
                                    <Column dataField={"Tong_con_phai_dong"} caption={"Tổng còn phải đóng"} minWidth={150} format={{ formatter: (value: any) => value.toLocaleString('de-de') }} />
                                    <Column dataField={"Cac_lan_thuc_nop"} caption={"Các lần thực nộp"} minWidth={250} />
                                    <Column dataField={"Tong_thuc_nop"} caption={"Tổng thực nộp"} format={{ formatter: (value: any) => value.toLocaleString('de-de') }} width={150} />
                                    <Column
                                        dataField={"Chenh_lech_thua"}
                                        caption={"Chênh lệch thừa"}
                                        headerCellRender={(e) => (
                                            <span
                                                style={{
                                                    color: "red"
                                                }}
                                            >
                                                {e.column.caption}
                                            </span>
                                        )}
                                        width={150}
                                        cellRender={(e) => (
                                            <span
                                                style={{
                                                    color: e.data.Chenh_lech_thua > 0 ? "green" : "black",
                                                    fontWeight: 600
                                                }}
                                            >

                                                {e.text}
                                            </span>
                                        )}
                                        format={{ formatter: (value: any) => value.toLocaleString('de-de') }}
                                    />
                                    <Column
                                        dataField={"Chenh_lech_thieu"}
                                        caption={"Chênh lệch thiếu"}
                                        headerCellRender={(e) => (
                                            <span
                                                style={{
                                                    color: "red"
                                                }}
                                            >
                                                {e.column.caption}
                                            </span>
                                        )}
                                        width={150}
                                        cellRender={(e) => (
                                            <span
                                                style={{
                                                    color: e.data.Chenh_lech_thieu > 0 ? "red" : "black",
                                                    fontWeight: 600
                                                }}
                                            >

                                                {e.text}
                                            </span>
                                        )}
                                        format={{ formatter: (value: any) => value.toLocaleString('de-de') }}
                                    />
                                </Column>
                                <Summary>
                                    {summaryItems}
                                    <TotalItem
                                        column='QTBS_TongTien_PN'
                                        summaryType="sum"
                                        valueFormat=",##0"
                                        displayFormat="{0}"
                                    />,
                                    <TotalItem
                                        column='QTHT_TongTien'
                                        summaryType="sum"
                                        valueFormat=",##0"
                                        displayFormat="{0}"
                                    />,
                                    <TotalItem
                                        column='Tong_cac_khoan_phai_nop_PN'
                                        summaryType="sum"
                                        valueFormat=",##0"
                                        displayFormat="{0}"
                                    />,
                                    <TotalItem
                                        column='Tong_giam_tru_hoan_tra_MG'
                                        summaryType="sum"
                                        valueFormat=",##0"
                                        displayFormat="{0}"
                                    />,
                                    <TotalItem
                                        column='Tong_con_phai_dong'
                                        summaryType="sum"
                                        valueFormat=",##0"
                                        displayFormat="{0}"
                                    />,
                                    <TotalItem
                                        column='Tong_thuc_nop'
                                        summaryType="sum"
                                        valueFormat=",##0"
                                        displayFormat="{0}"
                                    />,
                                    <TotalItem
                                        column='Chenh_lech_thua'
                                        summaryType="sum"
                                        valueFormat=",##0"
                                        displayFormat="{0}"
                                    />,
                                     <TotalItem
                                        column='Chenh_lech_thieu'
                                        summaryType="sum"
                                        valueFormat=",##0"
                                        displayFormat="{0}"
                                    />,
                                </Summary>
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    );
};
export default BaoCaoChiTietCongNoPage;
