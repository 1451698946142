import { showNotify } from './../../helpers/toast';
import { DuKienActionTypeIds, DuKienActionTypes } from './../action-types/duKienActionTypes';
import { localStorageHelper } from "../../helpers/localStorage";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { DuKienPageState, DuKienPageStatus } from './../page-state-model/duKienPageState';
const initialState: DuKienPageState = {
    status: PageBaseStatus.is_not_initial,
    filter: localStorageHelper.getNamHocTruongKhoiFilter(),
    sf_tbtp_dukien_viewmodal: {
        dataSource: [],
        sf_khoannop_groups: []
    }
}
export const duKienReducer = (state: DuKienPageState = initialState, action: DuKienActionTypes): DuKienPageState => {
    switch (action.type) {
        case DuKienActionTypeIds.CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case DuKienActionTypeIds.LOAD_DATASOURCE_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case DuKienActionTypeIds.LOAD_DATASOURCE_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_tbtp_dukien_viewmodal: action.payload
            }
        case DuKienActionTypeIds.LOAD_DATASOURCE_ERROR:
            showNotify({ message: action.payload, type: 'error' })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            }
        default:
            return state;
    }
}