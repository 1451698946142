import {
    BOMNgsPage, CongNoPage,
    DangKyDichVuPage,
    DanhSachKhoanNopPage,
    DayOffPage,
    DotGuiTBTPPage,
    DuKienPage, GanMienGiamPage, HachToanCongNoPage, HinhThucMienGiamPage, HinhThucThanhToanPage,
    HoanPhiHocSinhPage,
    HoanPhiPage,
    HocSinhProfilePage,
    HomePage,
    KhoanNopGroupPage, MucThuPhiPage,
    MucThuPhiPageNgs, MucThuPhiQuickInputPage, NhomKhoanNopPage, SaleSetEditPage,
    SalesetKhoanNopGroupTypePage,
    SalesetRegisterDichVuPage, TBTPPage,
    ThuPhiPage,
    TongHopChiTietPage, TongHopGanMienGiamPage, TongHopHoanPhiPage,
    TongHopPage,
    TongHopQuyetToanPage
} from './pages';
import BaoCaoChiTietCongNoPage from './pages/bao-cao-chi-tiet-cong-no';
import ImportHistoryPage from './pages/import-history';
import TongHopChamAnPage from './pages/tong-hop-cham-an';

const routes = [

    {
        path: '/home',
        component: HomePage
    },
     {
        path: '/khoan_nop',
        component: DanhSachKhoanNopPage
    },
    {
        path: '/muc_thu_phi',
        component: MucThuPhiPage
    },
    {
        path: '/muc-thu-phi-ngs',
        component: MucThuPhiPageNgs
    },
    {
        path: '/muc-thu-phi-ngs/:nam_hoc/:dm_truong_id',
        component: MucThuPhiQuickInputPage
    },
    {
        path: '/bom',
        component: BOMNgsPage
    },
    {
        path: '/tbtp',
        component: TBTPPage
    },
    // {
    //   path: '/tbtp-v2',
    //   component: TBTPPage
    // },
    {
        path: '/hinh_thuc_mien_giam',
        component: HinhThucMienGiamPage
    },
    {
        path: '/miengiam_v2',
        component: GanMienGiamPage
    },
    {
        path: '/hachtoan',
        component: HachToanCongNoPage
    },
    {
        path: "/tong_hop_khoan_nop",
        component: TongHopPage

    },
    {
        path: "/tong_hop_khoan_nop_chitiet",
        component: TongHopChiTietPage

    }, {
        path: "/thu_phi",
        component: ThuPhiPage
    },
    {
        path: "/cong-no",
        component: CongNoPage
    },
    {
        path: "/du-kien",
        component: DuKienPage
    },
    {
        path: "/hoc-sinh/:id",
        component: HocSinhProfilePage
    },
    {
        path: "/sale-set-edit/:ts_hocsinh_id/:nam_hoc",
        component: SaleSetEditPage
    },
    {
        path: "/cham-an",
        component: TongHopChamAnPage,
    },
    {
        path: "/dot-gui-tbtp",
        component: DotGuiTBTPPage
    },
    {
        path: "/hoan-phi",
        component: HoanPhiPage
    },
    {
        path: "/hoan-phi-hoc-sinh/:sf_mucphaithu_id",
        component: HoanPhiHocSinhPage
    },
    {
        path: "/sf-saleset-register-dichvu",
        component: SalesetRegisterDichVuPage
    },
    {
        path: "/register-dichvu",
        component: DangKyDichVuPage
    },
    {
        path: '/hinh-thuc-thanh-toan',
        component: HinhThucThanhToanPage
    },
    {
        path: '/nhom-khoan-nop',
        component: NhomKhoanNopPage
    },
    {
        path: '/khoan-nop-group',
        component: KhoanNopGroupPage
    },
    {
        path: "/sale-set-register",
        component: SalesetRegisterDichVuPage
    },
    {
        path: '/tong-hop-mien-giam',
        component: TongHopGanMienGiamPage
    },
    {
        path: '/dayoff',
        component: DayOffPage
    },
    {
        path: '/tong-hop-hoc-phi',
        component: BaoCaoChiTietCongNoPage
    },
    {
        path: "/sf-saleset-khoannopgroup-type",
        component: SalesetKhoanNopGroupTypePage
    },
    {
        path: '/tong_hop_hoan_phi',
        component: TongHopHoanPhiPage
    },
    {
        path: '/tong_hop_quyet_toan',
        component: TongHopQuyetToanPage
    },
    {
        path: '/import_history',
        component: ImportHistoryPage
    },
]
export default routes.map(route => {
    return {
        ...route,
        // component: withNavigationWatcher(route.component)
    };
});
