import { AuthProvider, useAuth } from 'oidc-react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import LoaderPage from '../components/loader-page';
import { ePageBaseStatus } from '../models/ePageBaseStatus';
import { actions } from '../state/actions/actionsWrapper';
import { RootState } from '../state/reducers';


const LoginContent = () => {

    const auth: any = useAuth();
    const { userData, userManager } = auth;
    const id_token = userData ? userData.id_token : "";
    const email = (userData && userData.profile) ? userData.profile.email : "";
    const access_token = userData ? userData.access_token : "";


    const { status, user_info } = useSelector((x: RootState) => x.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (access_token && id_token) {
            dispatch(actions.account.loginStart({
                access_token,
                id_token
            }))
        }
    }, [access_token, id_token])

    return (
        <LoaderPage />
    );
}
const UnAuthorizedContent = () => {
    const { status, appConfig } = useSelector((x: RootState) => x.appConfig)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.appConfig.loadStart())
    }, [])
    const oidcConfig: any = useMemo(() => {
        if (!appConfig) {
            // debugger
        }
        return {
            onSignIn: (e: any) => {
            },
            authority: appConfig?.OIDC_AUTHORITY,
            clientId: appConfig?.OIDC_CLIENT_ID,
            redirectUri: appConfig?.OIDC_REDIRECT_URI,
            clientSecret: appConfig?.OIDC_CLIENT_SECRET,
            
        };
    }, [appConfig])

    return (
        <div>
            {status === ePageBaseStatus.is_loading &&
                <LoaderPage />
            }
            {status === ePageBaseStatus.is_completed &&
                <AuthProvider {...oidcConfig} >
                    <LoginContent />
                </AuthProvider>
            }

        </div>
    );
};

export default UnAuthorizedContent;