import { IChangeDirectionMode, IChangeLangugae, IChangeMenuGroup, IChangeMenuMode, IHeaderComboboxCosoChangedAction, IHeaderComboboxHocKyChangedAction, IHeaderComboboxHocKyCloseAction, IHeaderComboboxHocKyShowAction } from './../action-types/ICommonActionTypes';
import {
    eCommonActionTypeIds, IHeaderCloseNotifyPanel, IHeaderComboboxNamHocChangedAction, IHeaderComboboxNamHocCloseAction,
    IHeaderComboboxNamHocShowAction, IHeaderComboboxTruongChangedAction, IHeaderComboboxTruongCloseAction,
    IHeaderComboboxTruongShowAction,
    IHeaderShowNofityPanel
} from "../action-types/ICommonActionTypes";

export const commonActions = {

    showHeaderComboboxTruong: (): IHeaderComboboxTruongShowAction => {
        return {
            type: eCommonActionTypeIds.HEADER_SHOW_COMBOBOX_TRUONG,
        }
    },

    changedValueHeaderComboboxTruong: (dm_truong_selected_id: number): IHeaderComboboxTruongChangedAction => {
        return {
            type: eCommonActionTypeIds.HEADER_COMBOBOX_TRUONG_CHANGED,
            payload: dm_truong_selected_id
        }
    },
    changedValueHeaderComboboxCoSo: (dm_coso_id: number): IHeaderComboboxCosoChangedAction => {
        return {
            type: eCommonActionTypeIds.HEADER_COMBOBOX_COSO_CHANGED,
            payload: dm_coso_id
        }
    },
    closeHeaderComboboxTruong: (): IHeaderComboboxTruongCloseAction => {
        return {
            type: eCommonActionTypeIds.HEADER_CLOSE_COMBOBOX_TRUONG,
        }
    },

    showHeaderComboboxNamHoc: (): IHeaderComboboxNamHocShowAction => {
        return {
            type: eCommonActionTypeIds.HEADER_SHOW_COMBOBOX_NAMHOC,
        }
    },
    showHeaderComboboxHocKy: (): IHeaderComboboxHocKyShowAction => {
        return {
            type: eCommonActionTypeIds.HEADER_SHOW_COMBOBOX_HOCKY,
        }
    },
    changedValueHeaderComboboxNamHoc: (nam_hoc: string): IHeaderComboboxNamHocChangedAction => {
        return {
            type: eCommonActionTypeIds.HEADER_COMBOBOX_NAMHOC_CHANGED,
            payload: nam_hoc
        }
    },
    changedValueHeaderComboboxHocKy: (hoc_ky: number): IHeaderComboboxHocKyChangedAction => {
        return {
            type: eCommonActionTypeIds.HEADER_COMBOBOX_HOCKY_CHANGED,
            payload: hoc_ky
        }
    },
    closeHeaderComboboxNamHoc: (): IHeaderComboboxNamHocCloseAction => {
        return {
            type: eCommonActionTypeIds.HEADER_CLOSE_COMBOBOX_NAMHOC,
        }
    },
    closeHeaderComboboxHocKy: (): IHeaderComboboxHocKyCloseAction => {
        return {
            type: eCommonActionTypeIds.HEADER_CLOSE_COMBOBOX_HOCKY,
        }
    },
    showHeaderNotifyPanel: (): IHeaderShowNofityPanel => {
        return {
            type: eCommonActionTypeIds.HEADER_SHOW_NOTIFY_PANEL
        }
    },
    closeHeaderNotifyPanel: (): IHeaderCloseNotifyPanel => {
        return {
            type: eCommonActionTypeIds.HEADER_CLOSE_NOTIFY_PANEL
        }
    },
    changeMenuMode: (payload: "compact" | "full"): IChangeMenuMode => {
        return {
            type: eCommonActionTypeIds.CHANGE_MENU_MODE,
            payload: payload
        }
    },
    changeDirectionMode: (payload: "column" | "row"): IChangeDirectionMode => {
        return {
            type: eCommonActionTypeIds.CHANGE_DIRECTION_MODE,
            payload: payload
        }
    },
    changeLanguge: (payload: "vi" | "en"): IChangeLangugae => {
        return {
            type: eCommonActionTypeIds.CHANGE_LANGUAGE,
            payload: payload
        }
    },
    changeMenuGroup: (payload: number): IChangeMenuGroup => {
        return {
            type: eCommonActionTypeIds.CHANGE_MENU_GROUP,
            payload: payload
        }
    }
}
