import CheckBox from "devextreme-react/check-box";
import React from "react";
interface RenderGroupProps {
    e: any,
    selectedIds: number[],
    onSelectedChanged: (selectedIds: number[]) => void
}
const RenderGroup = ({ e, selectedIds, onSelectedChanged }: RenderGroupProps) => {
    // console.log({ RenderGroup: e })
    const itemRowDatas = e.data.items || [];
    if (e.data.collapsedItems) {
        e.data.collapsedItems.forEach((element: any) => {
            itemRowDatas.push(element)
        });
    }
    let checked: boolean = true;
    if (itemRowDatas && itemRowDatas.find((x: any) => !selectedIds.includes(x.id))) checked = false;

    return (<div style={{ display: "flex", alignItems: "center" }}>
        <CheckBox value={checked} onValueChanged={(e: any) => {
            if (e.event) {
                let newSelectedIds = [...selectedIds];
                if (e.value) {
                    //add vào selectedIds
                    itemRowDatas.forEach((rowData: any) => {
                        if (!newSelectedIds.includes(rowData.id)) newSelectedIds.push(rowData.id)
                    });
                    onSelectedChanged(newSelectedIds)
                } else {
                    //remove khỏi selectedIds
                    // console.log(newSelectedIds)
                    for (let i = 0; i < itemRowDatas.length; i++) {
                        const rowData = itemRowDatas[i];
                        for (let j = 0; j < newSelectedIds.length; j++) {
                            if (newSelectedIds[j] == rowData.id) {
                                newSelectedIds[j] = -1;
                            }
                        }
                    }
                    newSelectedIds = newSelectedIds.filter((x: any) => x > 0);
                    onSelectedChanged(newSelectedIds)
                }
            }
        }} />
        <span style={{ paddingLeft: 10 }}>{e.displayValue}</span>
    </div>)
}
export default RenderGroup