// console.log(process.env)

interface IAppInfo {
    baseApiURL: string
    homeURL: string,
    subSytemId: number,
    versionDataGridStorageKey?: string,
    headerHeight: number

}

 const appInfo: IAppInfo = {
     baseApiURL:process.env.REACT_APP_API_BASE_URL?.toString() || "",
     homeURL: process.env.REACT_APP_PORTAL_URL?.toString() || "",
     subSytemId: 2,
     versionDataGridStorageKey: process.env.DATA_GRID_STOREGE_VERSION?.toString() || "", 
     headerHeight: 48

 };
export { appInfo }

