import { IUserInfoModel } from "../../models/response/account/user-info";
import { IAuthAction, eAuthAcitonTypes } from "../action-types/IAuthActionTypes";
export enum eAccountReducerStatus {
    is_getting_profile,
    is_get_profile_done,
    is_logging_in,
    is_log_in_success,
    is_log_in_error,
}
export interface IAuthState {
    status?: eAccountReducerStatus,
    user_info?: IUserInfoModel,
    ticket?: string,
    is_creating_ticket?: boolean
}
const iniState: IAuthState = {
   
}

export const authReducer = (state: IAuthState = iniState, action: IAuthAction): IAuthState => {
    switch (action.type) {
        case eAuthAcitonTypes.GET_USER_START:
            return {
                ...state,
                status: eAccountReducerStatus.is_getting_profile
            }
        case eAuthAcitonTypes.GET_USER_SUCCESS:
            return {
                ...state,
                status: eAccountReducerStatus.is_get_profile_done,
                user_info: action.payload
            }
        case eAuthAcitonTypes.GET_USER_ERROR:
            return {
                ...state,
                status: eAccountReducerStatus.is_get_profile_done,
                user_info: undefined
            }
        case eAuthAcitonTypes.LOGIN_START:
            return {
                ...state,
                status: eAccountReducerStatus.is_logging_in
            }
        case eAuthAcitonTypes.LOGIN_SUCCESS:
            return {
                ...state,
                status: eAccountReducerStatus.is_log_in_success,
                user_info: action.payload.profile,
            }
        case eAuthAcitonTypes.LOGIN_ERROR:
            return {
                ...state,
                status: eAccountReducerStatus.is_log_in_error,
                user_info: undefined
            }
        case eAuthAcitonTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                user_info: undefined,
                ticket: undefined
            }

        default:
            return {
                ...state
            }
    }
}