import List from "devextreme-react/list";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../components/buttons";
import { DefaultConfirm } from "../../../../components/confirm";
import { PlaceHolder } from "../../../../components/place-holder";
import { PageBaseStatus } from "../../../../models/pageBaseStatus";
import { sf_hachtoan_congno } from "../../../../models/response/hach-toan-cong-no/sf_hachtoan_congno";
import { sf_tbtp_viewmodel } from "../../../../models/response/tbtp/sf_tbtp";
import { closeEditTBTPHachToanAddModal, closeEditTBTPHachToanDeleteConfirm, deleteEditTBTPHachToanStart, loadEditTBTPHachToanAddedStart, loadEditTBTPHachToanPendingStart, showEditTBTPHachToanAddModal, showEditTBTPHachToanDeleteConfirm } from "../../../../state/actions/edit-tbtp/editTBTPHachToanActions";
import { RootState } from "../../../../state/reducers";
import { AddHachToanModel } from "../modal_add_hachtoan";
type EditTBTPKhoanNopProps = {
    sf_tbtp_viewmodel: sf_tbtp_viewmodel,
    onChanged: () => void,
    isReadOnly?: boolean
}
export const EditTBTPHachToan = (props: EditTBTPKhoanNopProps) => {
    const state = useSelector((rootState: RootState) => rootState.editTBTPHachToan);
    const [isShowPlaceHolder, SetIsHowPlaceHolder] = useState<boolean>(false);
    const {
        is_show_add_hachtoan_modal,
        is_show_remove_hachtoan_confirm,
        sf_hachtoan_congno_editing_id,
        sf_hachtoan_congnos_added,
        sf_hachtoan_congnos_pending,
        status
    } = state;
    const dispatch = useDispatch();
    useEffect(() => {
        handleReload();
    }, [props.sf_tbtp_viewmodel.id])
    useEffect(() => {
        if (status === PageBaseStatus.is_need_reload) {
            handleReload();
        }
        SetIsHowPlaceHolder(status === PageBaseStatus.is_loading);

    }, [status])

    const handleReload = useCallback(() => {

        dispatch(loadEditTBTPHachToanAddedStart(props.sf_tbtp_viewmodel.id))
        dispatch(loadEditTBTPHachToanPendingStart({
            nam_hoc: props.sf_tbtp_viewmodel.nam_hoc,
            ts_hocsinh_id: props.sf_tbtp_viewmodel.ts_hocsinh_id,
            is_added_tbtp: false
        }))
    }, [props])
    const handleShowAddHachToanModal = useCallback(() => {
        dispatch(showEditTBTPHachToanAddModal());
    }, [])
    const handleCloseAddHachToanModal = useCallback(() => {
        dispatch(closeEditTBTPHachToanAddModal());
    }, [])
    const handleShowConfirm = useCallback((sf_hachtoan_congno_id: number) => {
        dispatch(showEditTBTPHachToanDeleteConfirm(sf_hachtoan_congno_id));
    }, [])

    const handleCloseConfirm = useCallback(() => {
        dispatch(closeEditTBTPHachToanDeleteConfirm());
    }, [])

    const handleRemoveHachToan = useCallback(() => {
        dispatch(deleteEditTBTPHachToanStart({
            sf_hachtoan_congno_id: sf_hachtoan_congno_editing_id,
            sf_tbtp_id: props.sf_tbtp_viewmodel.id
        }))
        props.onChanged();
    }, [sf_hachtoan_congno_editing_id])

    const ListItemRender = (data: sf_hachtoan_congno): React.ReactNode => {
        return (
            <div>
                <div className="list_caption">{data.is_hoan_tra ? "Hoàn trả" : "Thu bổ sung"}</div>
                <div className="list_description">{data.noi_dung}</div>
                <div className="list_description text-danger">{data.tong_tien.toLocaleString()}</div>
            </div>
        );
    }
    return (
        <div>
            {isShowPlaceHolder && <PlaceHolder line_number={5} />}
            {!isShowPlaceHolder &&
                <div>
                    <div style={{
                        display: "flex",
                        width: "100%",
                        marginBottom: "10px"
                    }}>
                        <b style={{ flex: 1, fontSize: "1.2rem" }}>Các khoản cần quyết toán của học sinh {props.sf_tbtp_viewmodel.ho_ten} </b>

                        <div style={{ width: "5px" }}></div>
                        {sf_hachtoan_congnos_pending.length > 0 && props.isReadOnly !== true &&
                            <Button
                                text="Thêm vào khoản hạch toán"
                                type="normal"
                                icon="plus"
                                id="btnAddHachToan"
                                onClick={handleShowAddHachToanModal}
                            />
                        }
                    </div>
                    {sf_hachtoan_congnos_added.length == 0 && <div>
                        <i>Học sinh {props.sf_tbtp_viewmodel.ho_ten} chưa có khoản cần quyết toán trong TBTP này.</i>
                    </div>}
                    {sf_hachtoan_congnos_added.length > 0 &&
                        <List
                            selectionMode="none"
                            dataSource={sf_hachtoan_congnos_added}
                            searchEnabled={false}
                            itemRender={ListItemRender}
                            collapsibleGroups={true}
                            allowItemDeleting={props.isReadOnly !== true}
                            itemDeleteMode="static"
                            onItemDeleting={(e: any) => {
                                e.cancel = true;
                                handleShowConfirm(e.itemData.id);
                            }}
                        />}
                </div>
            }

            {is_show_add_hachtoan_modal && <AddHachToanModel sf_hachtoan_congnos={sf_hachtoan_congnos_pending}
                sf_tbtp_id={props.sf_tbtp_viewmodel.id}
                onCancel={handleCloseAddHachToanModal}
                onSuccess={() => {
                    handleReload();
                    handleCloseAddHachToanModal();
                    props.onChanged();
                }}
                animationOf="btnAddHachToan"
            />}
            {is_show_remove_hachtoan_confirm && <DefaultConfirm
                onClose={handleCloseConfirm}
                onConfirmed={handleRemoveHachToan}
                type="danger"
                text="Bạn có chắc chắn muốn bỏ quyết toán này khỏi thông báo thu phí ?"
                text_close_button="Đóng"
                text_confirm_button="Xóa"
                is_saving={status === PageBaseStatus.is_deleting}
            />
            }
        </div>)
}