import React, { useCallback } from "react";
import { NamHocTruongKhoiHeFilter } from "../../models/namHocTruongKhoiHeFilter";
import { sf_bom_chitiet_add_tbtp_model } from "../../models/response/bom/sf_bom_chitiet";
import { AnimationPopup, DefaultPopup } from "../modal";
import { AddKhoanNopFromBom } from "./add-from-bom";
import { AddKhoanNopFromKhoanNop } from "./add-from-khoannop";

export interface AddKhoanNopModalRespone {
    sf_bom_id?: number,
    is_calculate_miengiam: boolean,
    sf_bom_chitiets?: sf_bom_chitiet_add_tbtp_model[],
    sf_hinhthucnopbom_id: number
}

type AddKhoanNopModalProps = {
    sf_bom_id?: number,
    filter?: NamHocTruongKhoiHeFilter,
    sf_khoannop_ids_added?: number[],
    onSelected: (data: AddKhoanNopModalRespone) => void,
    onCancel: () => void,
    isSaving: boolean,
    animationOf: string,
    ts_hocsinh_id: number,
    nam_nhap_hoc?: string,
    sf_hinhthucnopbom_ids?: string

}

type AddKhoanNopModalDefaultProps = {
    sf_bom_id?: number,
    filter?: NamHocTruongKhoiHeFilter,
    sf_khoannop_ids_added?: number[],
    onSelected: (data: AddKhoanNopModalRespone) => void,
    onCancel: () => void,
    isSaving: boolean,
    ts_hocsinh_id: number,
    nam_nhap_hoc?: string,
}

export const AddKhoanNopModal = (props: AddKhoanNopModalProps) => {
    const onCancel = useCallback(() => {
        window.animationHidePopup(props.animationOf || "")
        setTimeout(() => {
            props.onCancel();
        }, 500);
    }, [props.animationOf])


    return (
        <AnimationPopup
            maxWidth={800}
            animationOf={props.animationOf}
            onClose={onCancel}
            title="Khoản nộp"
            >
            {props.sf_bom_id && props.sf_bom_id > 0 &&
                <AddKhoanNopFromBom
                    sf_bom_id={props.sf_bom_id}
                    isSaving={props.isSaving}
                    onCancel={onCancel}
                    onSelected={props.onSelected}
                    sf_khoannop_ids_added={props.sf_khoannop_ids_added}
                    ts_hocsinh_id={props.ts_hocsinh_id}

                />}
            {(!props.sf_bom_id || props.sf_bom_id === undefined || props.sf_bom_id <= 0) && props.filter &&
                <AddKhoanNopFromKhoanNop
                    isSaving={props.isSaving}
                    onCancel={onCancel}
                    onSelected={props.onSelected}
                    sf_khoannop_ids_added={props.sf_khoannop_ids_added}
                    filter={props.filter}
                    ts_hocsinh_id={props.ts_hocsinh_id}
                    nam_nhap_hoc={props.nam_nhap_hoc}
                    sf_hinhthucnopbom_ids={props.sf_hinhthucnopbom_ids}
                />}
        </AnimationPopup>
    );


}

export const AddKhoanNopModalDefault = (props: AddKhoanNopModalDefaultProps) => {
    return (
        <>
            {props.sf_bom_id && props.sf_bom_id > 0 &&
                <AddKhoanNopFromBom
                    sf_bom_id={props.sf_bom_id}
                    isSaving={props.isSaving}
                    onCancel={props.onCancel}
                    onSelected={props.onSelected}
                    sf_khoannop_ids_added={props.sf_khoannop_ids_added}
                    ts_hocsinh_id={props.ts_hocsinh_id}

                />}
            {(!props.sf_bom_id || props.sf_bom_id <= 0) && props.filter &&
                <AddKhoanNopFromKhoanNop
                    isSaving={props.isSaving}
                    onCancel={props.onCancel}
                    onSelected={props.onSelected}
                    sf_khoannop_ids_added={props.sf_khoannop_ids_added}
                    filter={props.filter}
                    ts_hocsinh_id={props.ts_hocsinh_id}
                    nam_nhap_hoc={props.nam_nhap_hoc}
                />}
        </>
    );
}
