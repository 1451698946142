import { sf_mucthuphi_select_request } from "../models/request/mucthuphi/sf_mucthiphi_select_rq";
import { apiClient } from "./apiClient"

export const baoCaoHocPhiApi = {
    reportAlpha: (request: sf_mucthuphi_select_request) => {
        return apiClient.post(`baocao_hocphi/report-congno-alpha`, request)
    },
    reportCongNoAll: (request: sf_mucthuphi_select_request) => {
        return apiClient.post(`baocao_hocphi/report-congno-all`, request)
    },
}