import { Column } from "devextreme-react/data-grid";
import FileUploader from "devextreme-react/file-uploader";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useDispatch } from 'react-redux';
import { appInfo } from "../../../AppInfo";
import { mucPhaiThuApi } from "../../../api/mucPhaiThuApi";
import { tbtpApi } from "../../../api/tbtpApi";
import SeparatedDataCell from '../../../components/SaleSetRegisterDichVu/SeparatedDataCell';
import { Button } from "../../../components/buttons";
import { DefaultDataGrid } from "../../../components/data-grid";
import { DefaultPopup } from "../../../components/modal";
import { PlaceHolder } from "../../../components/place-holder";
import { useCommonContext } from "../../../contexts/common";
import { showNotify } from "../../../helpers/toast";
import { PageBaseStatus } from "../../../models/pageBaseStatus";
import { sf_tbtp_viewmodel } from '../../../models/response/tbtp/sf_tbtp';
import { TBTPEditModal } from "../../tbtp/edit_tbtp_modal";

import {
    showEditModal
} from '../../../state/actions/tbtpActions';
type KhoanThuImportModalProps = {
    onClose: () => void;
    onSuccess: () => void;
    nam_hoc: string;
    dm_coso_id: number;
    animationOf: string;
};
type KhoanThuImportModalState = {
    status: PageBaseStatus;
    file_name: string;
    sheets: any[];
    sheet_index: number;
    data_source: any[];
};
const initialState: KhoanThuImportModalState = {
    status: PageBaseStatus.is_completed,
    data_source: [],
    file_name: "",
    sheet_index: -1,
    sheets: [],
};
export const KhoanThuImportModal = (
    props: KhoanThuImportModalProps
) => {
    const [formData, SetFormData] =
        useState<KhoanThuImportModalState>(initialState);
    const [isLoading, SetIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isLoadingSheets, SetIsLoadingSheets] = useState<boolean>(false);
    const [tbtpView, SetTBTPView] = useState<sf_tbtp_viewmodel>()

    const { createUUID } = useCommonContext();
    const onClose = useCallback(() => {
        window.animationHidePopup(props.animationOf || "");
        setTimeout(() => {
            props.onClose();
        }, 500);
    }, [props.animationOf]);
    const grid = useRef<any>();
    const dispatch = useDispatch();
    const { file_name, sheets, sheet_index, data_source } = formData;

    const handleShowEditModal = useCallback((sf_tbtp: sf_tbtp_viewmodel) => {
        dispatch(showEditModal(sf_tbtp))
    }, [])
    const btnPreview_click = async (data: any) => {
        const res = await tbtpApi.select_by_id(data.TBTP)
        if (res.is_success) {
            SetTBTPView(res.data)
        }
    };
    useEffect(() => {
        if (file_name) {
            reloadSheets();
        }
    }, []);
    useEffect(() => {
        if (file_name !== "" && sheets.length > 0 && sheet_index >= 0) {
            reloadDatas();
        }
    }, [sheet_index, sheets]);

    const errr_code = useMemo(() => {
        const count = data_source.filter((x) => x.err !== "").length;
        if (count > 0) {
            return `Có ${count} dòng không hợp lệ, vui lòng kiểm tra lại dữ liệu`;
        }
        return "";
    }, [data_source]);

    const reloadSheets = async () => {
        SetIsLoadingSheets(true);
        const res = await mucPhaiThuApi.getSheets(file_name);
        SetIsLoadingSheets(false);
        if (res.is_success) {
            SetFormData({ ...formData, sheets: res.data });
            if (res.data.length >= 1) {
                SetFormData({ ...formData, sheet_index: 0 });
            }
        }
    };
    const reloadDatas = async () => {
        SetIsLoading(true);
        const res = await mucPhaiThuApi.readFiles_khoanthu({
            file_name: file_name,
            sheet_index: sheet_index,
            nam_hoc: props.nam_hoc,
            dm_coso_id: props.dm_coso_id
        });
        SetIsLoading(false);
        if (res.is_success) {
            SetFormData({ ...formData, data_source: res.data });
        } else {
            showNotify({
                message: res.message || "Có lỗi, vui lòng kiểm tra lại sheet",
                type: "error",
            });
            SetFormData({ ...formData, data_source: [] });
        }
    };
    const handleSaveChanges = async () => {
        setIsSaving(true);
        const res = await mucPhaiThuApi.import_khoanthu({
            file_name: file_name,
            sheet_index: sheet_index,
            nam_hoc: props.nam_hoc,
            dm_coso_id: props.dm_coso_id
        });
        setIsSaving(false);
        if (res.is_success) {
            showNotify({ message: "Import thành công", type: "success" });
            props.onSuccess();
        } else {
            showNotify({ message: res.message || "Có lỗi", type: "error" });
        }
    };
    const onUploaded = (e: any) => {
        if (e && e.request && e.request.response) {
            const res = JSON.parse(e.request.response);
            if (res.is_success) {
                SetFormData({
                    ...formData,
                    file_name: res.data.url,
                    sheets: res.data.sheets,
                    sheet_index: res.data.sheets.length > 0 ? 0 : -1,
                });
                //SetFormData({ ...formData, file_name: res.data[0].file_name_uploaded })
            }
        }
    };
    const columns: any = useMemo(() => {
        return [
            <Column key={createUUID()} dataField="id" alignment='center'
                caption="TT" width={50} />,
            <Column
                key={createUUID()}
                dataField="Ma_hoc_sinh"
                alignment='center'
                caption="Mã HS"
                minWidth={150}
                cellRender={(cell) => <SeparatedDataCell value={cell.value} infor={'Mã học sinh'} />}

            />,
            <Column
                key={createUUID()}
                dataField="TBTP"
                caption="TBTP"
                alignment='center'
                minWidth={100}
                cellRender={(cell: any) => {
                    return <div>
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                btnPreview_click(cell.data);
                            }}
                        >
                            <i aria-hidden="true" className="fa fa-eye" style={{ color: '#54CA7D' }}></i>{' '}
                        </span>
                    </div>
                }}
            />,
            <Column
                key={createUUID()}
                dataField="So_tien_phai_nop"
                caption="Số tiền phải nộp"
                format=",##0"
                alignment='right'
                minWidth={150}
                cellRender={(cell) => <SeparatedDataCell value={cell.value} infor={'Số tiền phải nộp'} />}
            />,
            <Column
                key={createUUID()}
                dataField="Ma_hinh_thuc_thanh_toan"
                caption="Mã hình thức thanh toán"
                alignment='center'
                minWidth={150}
                cellRender={(cell) => <SeparatedDataCell value={cell.value} infor={'Số tiền phải nộp'} />}
            />,
            <Column
                key={createUUID()}
                dataField="Ma_chuan_chi"
                caption="Mã chuẩn chi"
                alignment='center'
                minWidth={150}
            />,
            <Column
                key={createUUID()}
                dataField="Noi_dung"
                caption="Nội dung"
                alignment='left'
                minWidth={150}
            />,
            <Column
                key={createUUID()}
                dataField="Ngay_thu_thuc_te"
                caption="Ngày thu thực tế"
                alignment='center'
                minWidth={150}
                cellRender={(cell) => <SeparatedDataCell value={cell.value} infor={'Ngày thu thực tế'} />}

            />,
            <Column
                key={createUUID()}
                dataField="err"
                caption="Thông tin lỗi"
                alignment='center'
                minWidth={300}
                cellRender={(cell) => <SeparatedDataCell value={cell.value} infor={'Thông tin lỗi'} />}

            />,
        ];
    }, []);
    return (
        <DefaultPopup
            width={'90%'}
            title={`Import tổng thu năm học ${props.nam_hoc} (có TBTP)`}
        // animationOf={props.animationOf}
        >
            <div>
                <div className="pop-up-form">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-inline" style={{ width: "100%" }}>
                                {file_name === "" && (
                                    <div className="form-group" style={{ width: "50%" }}>
                                        <FileUploader
                                            multiple={false}
                                            accept={".xls,.xlsx"}
                                            uploadMode={"instantly"}
                                            uploadHeaders={{
                                                Authorization: `Bearer ${localStorage.access_token}`,
                                            }}
                                            uploadUrl={`${appInfo.baseApiURL}/sf-mucphaithu/upload_file`}
                                            uploadMethod="POST"
                                            showFileList={false}
                                            onUploaded={onUploaded}
                                            labelText="hoặc kéo thả file tại đây để upload"
                                            selectButtonText="Chọn file"
                                        />
                                    </div>
                                )}
                                <div
                                    className="form-group"
                                    style={{ flexFlow: "row-reverse", width: "100%" }}
                                >
                                    <a
                                        href="../../templates/template_import_khoanthu.xlsx"
                                        target="_blank"
                                    >
                                        Tải file mẫu
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            {errr_code && (
                                <p className="text-danger padding-top-1rem">{errr_code}</p>
                            )}
                        </div>
                        <div className="col-md-12 padding-top-1rem">
                            {isLoading && <PlaceHolder line_number={20} />}
                            {!isLoading && (
                                <DefaultDataGrid
                                    columns={columns}
                                    dataSource={data_source}
                                    gridRef={grid}
                                    keyExpr="id"
                                    height={window.innerHeight - 350}
                                    width={window.innerWidth - 350}

                                />
                            )}
                        </div>
                        {tbtpView && <TBTPEditModal
                            onCancel={() => { SetTBTPView(undefined) }}
                            isReadOnly={true}
                            sf_tbtp={tbtpView}
                        />}
                    </div>
                </div>
                <div className="pop-up-actions">
                    <Button type="normal" icon="fa fa-times" text="Đóng" onClick={onClose}></Button>{" "}
                    <Button
                        type="normal" icon="fas fa-check-square"
                        text="Cập nhật"
                        onClick={handleSaveChanges}
                        isLoading={isSaving}
                        isDisabled={errr_code !== ""}
                    />
                </div>
            </div>
        </DefaultPopup>
    );
};
