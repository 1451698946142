import { ePageBaseStatus } from "../../../models/ePageBaseStatus";
import { IAppConfigActionType, eAppConfigActionTypeIds } from "../../action-types/common/IAppConfigActionType";
import { IAppConfigReducer } from "../../page-state-model/common/IAppConfigReducer";

const iniState: IAppConfigReducer = {
    status: ePageBaseStatus.is_not_initialization,
}
export const appConfigReducer = (state: IAppConfigReducer = iniState, action: IAppConfigActionType): IAppConfigReducer => {
    switch (action.type) {
        case eAppConfigActionTypeIds.LOAD_START:
            return {
                ...state,
                status: ePageBaseStatus.is_loading
            }
        case eAppConfigActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: ePageBaseStatus.is_completed,
                appConfig: action.payload
            }
        case eAppConfigActionTypeIds.LOAD_ERROR:
            return {
                ...state,
                status: ePageBaseStatus.is_completed
            }
        default:
            return {
                ...state
            }
    }
}