import NumberBox from "devextreme-react/number-box";
import ScrollView from "devextreme-react/scroll-view";
import Switch from "devextreme-react/switch";
import TextBox from "devextreme-react/text-box";
import DataGrid from "devextreme-react/data-grid";
import { Column, Editing, GroupItem, Grouping, Selection, Sorting, Summary, TotalItem } from "devextreme-react/data-grid";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { AddKhoanNopModalRespone } from "..";
import { bomApi } from "../../../api/bomApi";
import { bomChiTietApi } from "../../../api/bomChiTietApi";
import { hinhThucNopBomApi } from "../../../api/hinhThucNopBom";
import { mucPhaiThuChiTietApi } from "../../../api/mucPhaiThuChiTietApi";
import { localStorageHelper } from '../../../helpers/localStorage';
import { showNotify } from "../../../helpers/toast";
import { sf_bom } from "../../../models/response/bom/sf_bom";
import { sf_bom_chitiet_viewmodel } from "../../../models/response/bom/sf_bom_chitiet";
import { sf_hinhthucnopbom } from "../../../models/response/category/sf_hinhthucnopbom";
import { Button } from "../../buttons";
import { PlaceHolder } from "../../place-holder";

type AddKhoanNopFromBomProps = {
    sf_bom_id: number,
    sf_khoannop_ids_added?: number[],
    onSelected: (data: AddKhoanNopModalRespone) => void,
    onCancel: () => void,
    isSaving: boolean,
    ts_hocsinh_id: number
}
export const AddKhoanNopFromBom = (props: AddKhoanNopFromBomProps) => {
    const [sf_bom, set_sf_bom] = useState<sf_bom>();
    const [sf_bom_chitiet_viewmodels, set_sf_bom_chitiet_viewmodels] = useState<any[]>([])
    const [sf_bom_chitiet_selected_ids, set_sf_bom_chitiet_selected_ids] = useState<number[]>([])
    const [sf_hinhthucnopboms, set_sf_hinhthucnopboms] = useState<sf_hinhthucnopbom[]>([])
    const [is_loading, set_is_loading] = useState<boolean>(false)
    const [is_tinh_mien_giam, set_is_tinh_mien_giam] = useState<boolean>(true)
    const [isShowPlaceHolder, SetIsShowPlaceHolder] = useState<boolean>(true)
    const [khoanDaNops, setKhoanDaNops] = useState<number[]>([]);
    const [summary, setSummary] = useState<number>();

    const grid = useRef<any>();

    useEffect(() => {
        handleReloadBom();
    }, [])
    useEffect(() => {
        if (sf_bom) {
            handleReloadHinhThucNopBom();
            handleReloadBomChiTiets();
        }
    }, [sf_bom]);
    useEffect(() => {
        const new_sf_bom_chitiets = ConfigSoTienApDung(sf_bom_chitiet_selected_ids, undefined);
        set_sf_bom_chitiet_viewmodels(new_sf_bom_chitiets);
    }, [sf_bom_chitiet_selected_ids]);

    useEffect(() => {
        const filterData = sf_bom_chitiet_viewmodels.filter((x: any) => khoanDaNops === undefined || !khoanDaNops.includes(x.sf_khoannop_id));
        set_sf_bom_chitiet_viewmodels(filterData)
    }, [khoanDaNops])
    useEffect(() => {
        const data = {
            sf_mucthuphis: data_source.filter((x) => sf_bom_chitiet_selected_ids.includes(x.id))
        };
        const totalAmount = data.sf_mucthuphis.reduce((count, item) => {
            return count + (item.so_tien_phai_nop == undefined ? item.so_tien : item.so_tien_phai_nop);
        }, 0);
        setSummary(totalAmount)
    }, [sf_bom_chitiet_selected_ids])
    const handleReloadMucPhaiThuChiTiet = async () => {
        const request = { nam_hoc: localStorageHelper.getNamHoc(), ts_hocsinh_id: props.ts_hocsinh_id }
        const res = await mucPhaiThuChiTietApi.select_all_khoannop(request)
        if (res.is_success) {
            const khoanDaNopIds = res.data.map((x: any) => x.sf_khoannop_id);
            setKhoanDaNops(khoanDaNopIds)
        }
    }

    const handleReloadBom = async () => {
        const res = await bomApi.detail(props.sf_bom_id);
        if (res.is_success) {
            set_sf_bom(res.data)
        }
    }
    const handleReloadBomChiTiets = async () => {
        let isLoading = true;
        const interval_obj = setInterval(() => {
            if (!isLoading) {
                SetIsShowPlaceHolder(false)
                clearInterval(interval_obj);
            }
        }, 500)
        const res = await bomChiTietApi.select(sf_bom?.id || 0);
        if (res.is_success) {
            let data = res.data;
            if (data && props.sf_khoannop_ids_added) {
                data.sf_bom_chitiet_viewmodels = data.sf_bom_chitiet_viewmodels.filter((x: any) =>
                    !props.sf_khoannop_ids_added?.includes(x.sf_khoannop_id));
            }
            const new_sf_bom_chitiets = ConfigSoTienApDung([], data.sf_bom_chitiet_viewmodels);
            set_sf_bom_chitiet_viewmodels(new_sf_bom_chitiets);
        }
        handleReloadMucPhaiThuChiTiet()
        isLoading = false;
    }
    const handleReloadHinhThucNopBom = async () => {
        const res = await hinhThucNopBomApi.select_by_bom(sf_bom?.id || 0);
        if (res.is_success) {
            set_sf_hinhthucnopboms(res.data)
        }
    }
    const ConfigSoTienApDung = (sf_bom_chitiet_selected_ids: number[], _sf_bom_chitiets?: sf_bom_chitiet_viewmodel[]) => {
        if (_sf_bom_chitiets === undefined) _sf_bom_chitiets = sf_bom_chitiet_viewmodels
        const new_sf_bom_chitiets = _sf_bom_chitiets.map(sf_bom_chitiet => {
            if (sf_bom_chitiet_selected_ids.includes(sf_bom_chitiet.sf_khoannop_id)) {
                return { ...sf_bom_chitiet, so_tien_ap_dung: sf_bom_chitiet.so_tien_dinh_muc }
            } else {
                return { ...sf_bom_chitiet, so_tien_ap_dung: 0 }
            }
        })
        return new_sf_bom_chitiets;

    }
    const data_source = useMemo(() => {

        let sf_khoannops: any[] = [];
        for (var i = 0; i < sf_bom_chitiet_viewmodels.length; i++) {
            const sf_bom_chitiet = sf_bom_chitiet_viewmodels[i];

            if (sf_khoannops.find(sf_khoannop => sf_khoannop.id == sf_bom_chitiet.sf_khoannop_id) == undefined) {
                sf_khoannops.push({
                    id: sf_bom_chitiet.sf_khoannop_id,
                    ma_khoan_nop: sf_bom_chitiet.ma_khoan_nop,
                    ma_khoan_nop_group: sf_bom_chitiet.ma_khoan_nop_group,
                    ten_khoan_nop_group: sf_bom_chitiet.ten_khoan_nop_group,
                    ten_khoan_nop: sf_bom_chitiet.ten_khoan_nop
                })
            }
            else {
                sf_khoannops.push({ id: 0 });
            }
        }

        sf_khoannops = sf_khoannops.filter(x => x.id > 0);
        const result = sf_khoannops.map(sf_khoannop => {
            let item = { ...sf_khoannop }
            sf_hinhthucnopboms.map(sf_hinhthucnopbom => {
                const sf_bom_chitiet = sf_bom_chitiet_viewmodels.find(x => x.sf_khoannop_id == sf_khoannop.id
                    && x.sf_hinhthucnopbom_id == sf_hinhthucnopbom.id);
                if (sf_bom_chitiet) {
                    item[`T_${sf_hinhthucnopbom.id}`] = sf_bom_chitiet.so_tien_dinh_muc;
                    item[`S_${sf_hinhthucnopbom.id}`] = sf_bom_chitiet.so_tien_ap_dung;
                    item[`ghi_chu_${sf_hinhthucnopbom.id}`] = sf_bom_chitiet.ghi_chu;
                }
            })
            return item;
        })

        return result;
    }, [sf_bom_chitiet_viewmodels, sf_hinhthucnopboms])

    const onValueChanged = (checked: any, data: any) => {
        if (checked) {
            let _sf_bom_chitiet_selected_ids = [...sf_bom_chitiet_selected_ids]
            for (let i = 0; i < data.length; i++) {
                if (_sf_bom_chitiet_selected_ids.find(x => x == data[i].id) == undefined) {
                    _sf_bom_chitiet_selected_ids.push(data[i].id)
                }
            }
            set_sf_bom_chitiet_selected_ids(_sf_bom_chitiet_selected_ids)

        } else {
            let _sf_bom_chitiet_selected_ids = [...sf_bom_chitiet_selected_ids]
            _sf_bom_chitiet_selected_ids = _sf_bom_chitiet_selected_ids.map(x => {
                if (data.find((y: any) => y.id == x)) {
                    return 0;
                } else {
                    return x;
                }
            })
            _sf_bom_chitiet_selected_ids = _sf_bom_chitiet_selected_ids.filter(x => x != 0);
            set_sf_bom_chitiet_selected_ids(_sf_bom_chitiet_selected_ids)
        }
    };
    const groupCellRender = (props: any) => {
        let data: any[] = [];
        if (props.data.items) {
            data = props.data.items;
        } else {
            if (props.data.collapsedItems) {
                data = props.data.collapsedItems;
            }
        }

        const data_selected = data.filter(x => sf_bom_chitiet_selected_ids.includes(x.id));
        const checked = data != null && data.length > 0 && data.length == data_selected.length;
        const groupText = props.text;
        return <React.Fragment>
            {/* <CheckBox
                value={checked}
                className="group-checkbox"
                onValueChanged={(e) => {
                    onValueChanged(e.value, data);
                }}
            /> */}
            <span style={{ marginLeft: "5px" }}> {groupText} </span>
        </React.Fragment>
    };
    const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }: any) => {
        debugger
        set_sf_bom_chitiet_selected_ids(selectedRowKeys)
    }
    const so_tien_cellRender = (cell: any) => {
        const sf_hinhthucnopbom_id = parseInt(cell.column.dataField.replace("S_", ""));
        return <NumberBox defaultValue={cell.data[cell.column.dataField]} format="#,## VNĐ" onValueChanged={(e) => {
            if (sf_bom_chitiet_selected_ids.includes(cell.data.id)) {
                cell.setValue(e.value)
                const new_sf_bom_chitiets = sf_bom_chitiet_viewmodels.map(x => {
                    if (x.sf_khoannop_id == cell.data.id && x.sf_hinhthucnopbom_id == sf_hinhthucnopbom_id) {
                        return { ...x, so_tien_ap_dung: e.value }
                    } else {
                        return { ...x }
                    }
                })
                set_sf_bom_chitiet_viewmodels(new_sf_bom_chitiets)
            } else {
                showNotify({ message: "Vui lòng chọn khoản nộp trước", type: "warning" })
            }

        }} />
    }
    const ghi_chu_cellRender = (cell: any) => {
        const sf_hinhthucnopbom_id = parseInt(cell.column.dataField.replace("ghi_chu_", ""));
        return <TextBox defaultValue={cell.data[cell.column.dataField]} onValueChanged={(e) => {
            cell.setValue(e.value)
            const new_sf_bom_chitiets = sf_bom_chitiet_viewmodels.map(x => {
                if (x.sf_khoannop_id == cell.data.id && x.sf_hinhthucnopbom_id == sf_hinhthucnopbom_id) {
                    return { ...x, ghi_chu: e.value }
                } else {
                    return { ...x }
                }
            })
            set_sf_bom_chitiet_viewmodels(new_sf_bom_chitiets)
        }} />
    }
    const handelSubmit = () => {
        const data = {
            sf_bom_id: sf_bom?.id || 0,
            is_calculate_miengiam: is_tinh_mien_giam,
            sf_bom_chitiets: sf_bom_chitiet_viewmodels.filter(x => sf_bom_chitiet_selected_ids.includes(x.sf_khoannop_id)),
            sf_hinhthucnopbom_id: 0
        }
        props.onSelected(data);
    }
    return (
        <div>
            <div className="pop-up-form">
                <ScrollView width={"100%"} height={window.innerHeight - 170}>
                    <div>
                        {isShowPlaceHolder && <PlaceHolder line_number={20} />}
                        {!isShowPlaceHolder &&
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-inline" style={{ paddingLeft: "1rem" }}>
                                        <div className="form-group" style={{ flex: 1 }}>
                                            <div style={{
                                                fontWeight: "bold",
                                                fontSize: "1.1rem"
                                                , whiteSpace: "pre-line"
                                            }}>
                                                {sf_bom?.ten_bom || ""}</div>
                                        </div>
                                        {/* <div className="form-group">
                                            <label className='form-label'>Tự động tính miễn giảm </label>
                                            <Switch value={is_tinh_mien_giam}
                                                className="margin-top-10px margin-left-10px"
                                                onValueChanged={(e) => {
                                                    if (e.event) {
                                                        set_is_tinh_mien_giam(e.value);
                                                    }
                                                }}
                                            />
                                        </div> */}
                                        <div className="form-group">
                                            <label className='form-label'>Tổng tiền phải nộp: {summary?.toLocaleString()} </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <DataGrid
                                        ref={grid}
                                        keyExpr={"id"}
                                        dataSource={data_source}
                                        columnHidingEnabled={false}
                                        columnAutoWidth={true}
                                        showBorders={true}
                                        showColumnLines={false}
                                        showRowLines={false}
                                        rowAlternationEnabled={true}
                                        allowColumnResizing={true}
                                        columnResizingMode={"nextColumn"}
                                        hoverStateEnabled={true}
                                        focusedRowEnabled={false}
                                        noDataText="Không có dữ liệu"
                                        paging={{ enabled: false }}
                                        height={window.innerHeight - 230}
                                        selectedRowKeys={sf_bom_chitiet_selected_ids}
                                        onSelectionChanged={onSelectionChanged}

                                    >
                                        <Editing
                                            mode="cell"
                                            allowUpdating={true}
                                            allowAdding={false}
                                            allowDeleting={false} />
                                        <Selection mode="multiple" showCheckBoxesMode="always"></Selection>
                                        <Sorting mode="multiple" />
                                        <Column dataField="ten_khoan_nop_group" caption="Nhóm" groupIndex={0} autoExpandGroup={true}
                                            groupCellRender={groupCellRender}
                                        ></Column>
                                        <Column dataField="ma_khoan_nop" caption="Mã" width={100} allowEditing={false}></Column>
                                        <Column dataField="ten_khoan_nop" caption="Tên khoản nộp" allowEditing={false}></Column>
                                        <Column caption="Định mức" alignment="center">
                                            {sf_hinhthucnopboms.map((x, index) => {
                                                return (
                                                    <Column key={index} dataField={`T_${x.id}`}
                                                        width={100} allowEditing={false}
                                                        caption={x.name} format=",##0"></Column>
                                                );
                                            })}
                                        </Column>
                                        <Column caption="Áp dụng" alignment="center">
                                            {sf_hinhthucnopboms.map((x, index) => {
                                                return (
                                                    <Column key={index} dataField={`S_${x.id}`} width={100} caption="Số tiền" format=",##0"
                                                        editCellRender={so_tien_cellRender}
                                                    ></Column>
                                                );
                                            })}

                                        </Column>
                                        <Summary>
                                            {sf_hinhthucnopboms.map((x, index) => {
                                                return (
                                                    <GroupItem
                                                        key={index}
                                                        column={`T_${x.id}`}
                                                        summaryType="sum"
                                                        valueFormat=",##0"
                                                        showInGroupFooter={false}
                                                        displayFormat="{0}"
                                                        alignByColumn={false} />
                                                );
                                            })}
                                            {sf_hinhthucnopboms.map((x, index) => {
                                                return (
                                                    <GroupItem
                                                        key={index}
                                                        column={`S_${x.id}`}
                                                        summaryType="sum"
                                                        valueFormat=",##0"
                                                        showInGroupFooter={false}
                                                        displayFormat="{0}"
                                                        alignByColumn={false} />
                                                );
                                            })}
                                            {sf_hinhthucnopboms.map((x, index) => {
                                                return (
                                                    <TotalItem
                                                        key={index}
                                                        column={`T_${x.id}`}
                                                        summaryType="sum"
                                                        valueFormat=",##0"
                                                        displayFormat="{0}"
                                                    />
                                                );
                                            })}
                                            {sf_hinhthucnopboms.map((x, index) => {
                                                return (
                                                    <TotalItem
                                                        key={index}
                                                        column={`S_${x.id}`}
                                                        summaryType="sum"
                                                        valueFormat=",##0"
                                                        displayFormat="{0}"
                                                    />
                                                );
                                            })}
                                        </Summary>

                                        <Grouping
                                            autoExpandAll={true}
                                        />
                                    </DataGrid>
                                </div>



                            </div>
                        }
                    </div>

                </ScrollView>
            </div>
            <div className="pop-up-actions">
                <Button type="normal" text="Đóng" onClick={() => { props.onCancel() }}></Button> {' '}
                <Button type="normal" text="Áp dụng"
                    isLoading={props.isSaving}
                    isDisabled={sf_bom_chitiet_selected_ids.length === 0}
                    onClick={handelSubmit}></Button>
            </div>
        </div>
    );
}