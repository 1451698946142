import Layout from '../layout';
import PrivateContent from './PrivateContent';
const AuthorizedContent = () => {
    return (
        <>
            <Layout>
                <PrivateContent/>
            </Layout>
        </>

    );
};

export default AuthorizedContent;