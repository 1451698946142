import { useCallback } from "react";
import { Button } from "../../../components/buttons";
import { DefaultPopup } from "../../../components/modal";
import { DataGrid,  } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";

type DetailModalProps = {
    title?: string,
    onClose: () => void,
    animationOf: string,
    dataSource: any
}

const DetailModal = (props: DetailModalProps) => {
    console.log(props.dataSource);

    const onCloseForm = useCallback(() => {
        window.animationHidePopup(props.animationOf || "")
        setTimeout(() => {
            props.onClose();
        }, 500);
    }, [props.animationOf]);

    // Generate columns based on dataSource
    const generateColumns = () => {
        if (props.dataSource && props.dataSource.length > 0) {
            const keys = Object.keys(props.dataSource[0]);
            const idColumn = keys.includes("id") ? (
                <Column
                    key="id"
                    dataField="id"
                    caption="ID"
                />
            ) : null;
    
            const otherColumns = keys
                .filter(key => key.toLowerCase() !== "id")
                .map((key) => (
                    <Column
                        key={key}
                        dataField={key}
                        caption={key.charAt(0).toUpperCase() + key.slice(1)}
                    />
                ));
    
            return [idColumn, ...otherColumns];
        }
        return null;
    };
    

    return (
        <DefaultPopup
            width={'90%'}
            title={props.title}
        >
            <div className="pop-up-form">
                <DataGrid
                    keyExpr="ID"
                    dataSource={props.dataSource}
                    columnHidingEnabled={false}
                    columnAutoWidth={true}
                    showBorders={false}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    allowColumnResizing={true}
                    columnResizingMode={"nextColumn"}
                    hoverStateEnabled={true}
                    focusedRowEnabled={true}
                    height={window.innerHeight - 250}
                    className={"table-noborder-left table-white-space"}
                    noDataText="Không có dữ liệu"
                    wordWrapEnabled={true}
                    loadPanel={{ enabled: false }}
                    paging={{ enabled: false }}
                >
                    {generateColumns()}
                </DataGrid>
            </div>
            <div className="pop-up-actions">
                <Button type="normal" icon="fa fa-times" text="Đóng" onClick={onCloseForm}></Button>{' '}
            </div>
        </DefaultPopup>
    );
}

export default DetailModal;
