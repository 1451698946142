import { accountApi } from "./accountApi";
import { categorySourceApi } from "./categorySourceApi";
import { dictionaryApi } from "./dictionaryApi";
import { hocSinhApi } from "./hocSinhApi";
import { localizedReourceApi } from './localizedReourceApi';
import { uploadApi } from "./uploadApi";


export const apiWrapper = {
    account: accountApi,
    uploadFile: uploadApi,
    categorySource: categorySourceApi,
    dictionary: dictionaryApi,
    hocSinh: hocSinhApi, 
    localizedResource: localizedReourceApi,


}