import { call, put, takeLatest } from "redux-saga/effects";
import { eAppConfigActionTypeIds } from './../../action-types/common/IAppConfigActionType';
import { IBaseResponeModel } from "../../../models/response/base-response";
import { appConfigApi } from "../../../api/appConfigApi";
import { actions } from "../../actions/actionsWrapper";

export function* appConfigSaga(): any {
    yield takeLatest(eAppConfigActionTypeIds.LOAD_START, loadWorker)
}
function* loadWorker(): any {
    const res: IBaseResponeModel = yield call(appConfigApi.getAll)
    if (res.is_success) {
        yield put(actions.appConfig.loadSuccess(res.data))
    } else {
        yield put(actions.appConfig.loadError(res.message))
    }
}