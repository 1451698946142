
import { Select } from '@primer/react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCommonContext } from '../../contexts/common';
import { dm_he } from '../../models/response/category/dm_he';
import { actions } from '../../state/actions/actionsWrapper';
import { RootState } from '../../state/reducers';
type ComboboxHeProps = {
    isReadonly?: boolean;
    value?: number;
    onValueChanged: (id: number, data?: dm_he) => void;
    className?: string;
    isShowClearButton?: boolean;
    preText?: string;
    width?: string | number;
    dm_khoi_id?: number;
    dm_truong_id?: number;
    stylingMode?: 'outlined' | 'filled' | 'underlined';
};
export const ComboboxHe = (props: ComboboxHeProps) => {
    const { translate } = useCommonContext();
    const dispatch = useDispatch();
    const categorySource = useSelector((state: RootState) => state.categorySource);

    useEffect(() => {
        if (categorySource.dm_hes.length === 0) dispatch(actions.categorySource.loadHeStart());
    }, []);

    const source: dm_he[] = useMemo(() => {
        return categorySource.dm_hes.map(x => ({ ...x }));
    }, [categorySource.dm_hes, props.dm_truong_id]);
    return (
        <Select onChange={(e) => {
            const dm_he_id: number = e.currentTarget.value ? parseInt(e.currentTarget.value) : 0
            props.onValueChanged(dm_he_id, source.find(x => x.id == dm_he_id))
        }}
            value={`${props.value ?? 0}`}
            placeholder={translate("Chọn hệ")}
        >
            {source.map(x => {
                return (
                    <Select.Option key={x.id} value={`${x.id}`}>{x.ten_he}</Select.Option>
                );
            })}
        </Select>
    );
}

