import { ChamAnHoanPhiRequest } from "../models/request/cham-an/ChamAnHoanPhiRequest"
import { ChamAnSelectRequest } from "../models/request/cham-an/ChamAnSelectRequest"
import { apiClient } from "./apiClient"

export const chamAnApi = {
    select_by_month: (rq: ChamAnSelectRequest) => {
        return apiClient.post(`ts_chaman/select_by_month`, rq)
    },
    update_hoan_phi: (rq: ChamAnHoanPhiRequest) => {
        return apiClient.post(`ts_chaman/update_hoan_phi`, rq)
    },
    lock_cham_an: (rq: ChamAnSelectRequest) => {
        return apiClient.post(`ts_chaman/lock_cham_an`, rq)
    },
    update_hoan_phi_month: (rq: ChamAnHoanPhiRequest) => {
        return apiClient.post(`ts_chaman/update_hoan_phi`, rq)
    },
}