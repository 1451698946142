import { sf_mucphaithu_search_rq } from "../models/request/tonghop/sf_mucphaithu_search_rq";
import { sf_mucthuphi_chitiet_request } from "../models/request/mucthuphi/sf_mucthuphi_chitiet_request";

import { apiClient } from "./apiClient";

export const SF_MUCPHAITHU_CHITIET_SEARCH_API = "sf_mucphaithu_chitiet/search";
export const SF_MUCPHAITHU_CHITIET_HOCPHI_API = "sf_mucphaithu_chitiet/chi-tiet-hoc-phi";
export const SF_MUCPHAITHU_CHITIET_SELECTALL_KHOANNOP_API = "sf_mucphaithu_chitiet/select-all-khoan-nop";
export const SF_MUCPHAITHU_CHITIET_SELECT_QUYETTOAN_KHOANNOP_API = "sf_mucphaithu_chitiet/select-quyettoan-dasudung-khoan-nop";


export const mucPhaiThuChiTietApi = {
    search: (request: sf_mucphaithu_search_rq) => {
        return apiClient.post(`${SF_MUCPHAITHU_CHITIET_SEARCH_API}`, request)
    },
    chiTietHocPhi: (request: any) => {
        return apiClient.post(`${SF_MUCPHAITHU_CHITIET_HOCPHI_API}`, request)
    },
    select_all_khoannop:(request:sf_mucthuphi_chitiet_request) => {
        return apiClient.post(`${SF_MUCPHAITHU_CHITIET_SELECTALL_KHOANNOP_API}`, request)
    },
    select_quuyetoan_dasudung_by_hocsinh:(request:sf_mucthuphi_chitiet_request) => {
        return apiClient.post(`${SF_MUCPHAITHU_CHITIET_SELECT_QUYETTOAN_KHOANNOP_API}`, request)
    },
    selectByMucPhaiThu: (sf_mucphaithu_id: number) => {
        return apiClient.post(`sf_mucphaithu_chitiet/select_by_sct?sf_mucphaithu_id=${sf_mucphaithu_id}`)
    },
    TinhMienGiamSap:(request:any)=>{
        return apiClient.post(`sf_mucphaithu_chitiet/mien-giam-sap`, request)
    },
    UpdateSoTienTruyThu:(request:any)=>{
        return apiClient.post(`sf_mucphaithu_chitiet/update-truythu`, request)
    }
}