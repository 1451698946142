import { ThemeProvider } from '@primer/react';
import { useEffect } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import BaseStyles from './BaseStyles';
import LoaderPage from './components/loader-page';
import AuthorizedContent from './content/AuthorizedContent';
import UnAuthorizedContent from './content/UnAuthorizedContent';
import { CommonProvider } from './contexts/common';
import { actions } from './state/actions/actionsWrapper';
import { localizedResourceAction } from './state/actions/localized-resource/localizedResourceAction';
import { RootState } from './state/reducers';
import { eAccountReducerStatus } from './state/reducers/authReducer';
import 'devextreme/dist/css/dx.common.css';
import 'semantic-ui-css/semantic.min.css';
import './dx-styles.scss';
import './style.css';
import './themes/generated/theme.additional.css';
import './themes/generated/theme.base.css';
function App() {
  const { user_info, status } = useSelector((x: RootState) => x.auth)
  const { language } = useSelector((x: RootState) => x.common)
  const dispath = useDispatch();
  useEffect(() => {
    dispath(localizedResourceAction.loadStart(language))
  }, [language])

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      dispath(actions.account.getUserStart())
    }
  }, [])

  if (status === eAccountReducerStatus.is_getting_profile) {
    return <LoaderPage />
  }

  return (
    <Router>
      <ThemeProvider colorMode={"light"}>
        <BaseStyles>
          <CommonProvider>
            {user_info && <AuthorizedContent />}
            {!user_info && <UnAuthorizedContent />}
          </CommonProvider>
        </BaseStyles>
      </ThemeProvider>
    </Router>
  );
}

export default App;
