import { IAppConfig } from "../../../models/response/common/IAppConfig"


export enum eAppConfigActionTypeIds {
    LOAD_START = "APP_CONFIG_LOAD_START",
    LOAD_SUCCESS = "APP_CONFIG_LOAD_SUCCESS",
    LOAD_ERROR = "APP_CONFIG_LOAD_ERROR",
}

export interface IAppConfigLoadStart {
    type: eAppConfigActionTypeIds.LOAD_START
}

export interface IAppConfigLoadSuccess {
    type: eAppConfigActionTypeIds.LOAD_SUCCESS,
    payload: IAppConfig
}

export interface IAppConfigLoadError {
    type: eAppConfigActionTypeIds.LOAD_ERROR,
    payload: string
}
export type IAppConfigActionType = IAppConfigLoadStart | IAppConfigLoadSuccess | IAppConfigLoadError