import { SignOutIcon } from "@primer/octicons-react";
import { ActionList, ActionMenu, IconButton, Text } from '@primer/react';
// import {Text} from '@primer/react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../state/reducers";
import { accountActions } from '../../state/actions/accountActions';
const UserPanel = () => {
    const { user_info } = useSelector((x: RootState) => x.auth)
    const dispatch = useDispatch();
    const userIcon = () => {
        return (
            <img src={`${user_info?.img}`}
                width={"28px"}
                height={"28px"}
                style={{
                    borderRadius: "50%"
                }}
            />
        )
    }
    const handleLogout = () => {
        dispatch(accountActions.logOutStart())
    }
    return (
        <ActionMenu>
            <ActionMenu.Anchor>
                <IconButton icon={userIcon} aria-label="Laguage" variant='invisible'
                    size="large"
                />
            </ActionMenu.Anchor>

            <ActionMenu.Overlay>
                <ActionList>

                    <ActionList.Item>
                        <Text sx={{
                            fontWeight: "bold"
                        }}>{user_info?.full_name}</Text>
                        <br />
                        <Text>{user_info?.username}</Text>
                    </ActionList.Item>
                    <ActionList.Divider />
                    <ActionList.Item variant="danger" onClick={handleLogout}>
                        <ActionList.LeadingVisual>
                            <SignOutIcon />
                        </ActionList.LeadingVisual>
                        Đăng xuất
                    </ActionList.Item>
                </ActionList>
            </ActionMenu.Overlay>
        </ActionMenu>

    );
};

export default UserPanel;