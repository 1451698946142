import { NamHocTruongKhoiHeFilter } from "../models/namHocTruongKhoiHeFilter";
import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_import_history } from "../models/response/import-history/sf_import_history";
import { apiClient } from "./apiClient";

export const SF_IMPORT_HISTORY_SELECT_ALL_API = "sf_import_history/select";
export const SF_IMPORT_HISTORY_INSERT_API = "sf_import_history/insert";
export const SF_IMPORT_HISTORY_UPDATE_API = "sf_import_history/update";
export const SF_IMPORT_HISTORY_DELETE_API = "sf_import_history/delete";




export const importHistoryApi = {
    selectAll: () => {
        return apiClient.get('sf_import_history/select')
    },
    select: (filter: NamHocTruongKhoiHeFilter) => {
        return apiClient.post(`${SF_IMPORT_HISTORY_SELECT_ALL_API}`, filter)
    },
    insert: (data: sf_import_history) => {
        return apiClient.post(`${SF_IMPORT_HISTORY_INSERT_API}`, data)
    },
    update: (data: sf_import_history) => {
        return apiClient.post(`${SF_IMPORT_HISTORY_UPDATE_API}`, data)
    },
    delete: (data: number[]) => {
        const model: IBaseDeleteRequestModel = {
            ids: data
        }
        return apiClient.post(`${SF_IMPORT_HISTORY_DELETE_API}`, model)
    },
    read_import_file: (url_file: any) => {
        return apiClient.post(`sf_import_history/read_file?url_file=${url_file}`)
    },
}
