import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import router from "../app-routes";
import { HomePage } from '../pages';
import LogoutPage from '../pages/logout-page/LogoutPage';

const PrivateContent = () => {
    const [visible, setVisible] = useState(true);
    const location = useLocation()
    useEffect(() => {
        setVisible(!visible)
    }, [location.pathname])

    return (
        <>
            <Routes>
                {router.map((x, idx) => {
                    return (
                        <Route key={idx} path={`${x.path}`}
                            // element={x.component}
                            Component={x.component}
                        />

                    );
                })}
                <Route path="/logout" element={<LogoutPage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="*" element={<Navigate to={"/home"} replace />} />
            </Routes>
        </>
    );
};

export default PrivateContent;