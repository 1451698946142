import SelectBox from "devextreme-react/select-box";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCommonContext } from "../../contexts/common";
import { actions } from '../../state/actions/actionsWrapper';
import { RootState } from "../../state/reducers";
type ComboboxNamHocProps = {
    isReadonly?: boolean,
    value?: string,
    onValueChanged: (id: string) => void,
    className?: string,
    isShowClearButton?: boolean,
    preText?: string,
    width?: string,
    stylingMode?: "outlined" | "filled" | "underlined"
}
export const ComboboxNamHoc = (props: ComboboxNamHocProps) => {
    const dm_namhocs = useSelector((state: RootState) => state.categorySource.dm_namhocs);
    const source = useMemo(() => {
        return dm_namhocs.map(x => ({
            ...x,
            text: (props.preText ?? "") + x.nam_hoc
        }))
    }, [props.preText, dm_namhocs])
    let stylingMode = "outlined";
    if (props.stylingMode) stylingMode = props.stylingMode
    const dispatch = useDispatch();
    useEffect(() => {
        if (dm_namhocs.length === 0) dispatch(actions.categorySource.loadNamHocStart())
    }, []);
    const { translate } = useCommonContext();
    return (
        <SelectBox dataSource={source}
            displayExpr={"text"}
            valueExpr={"nam_hoc"}
            value={props.value}
            stylingMode={props.stylingMode != undefined ? props.stylingMode : "outlined"}
            readOnly={props.isReadonly === true}
            placeholder={translate("Chọn năm học")}
            width={props.width ? props.width : "100%"}
            className={"default_selectbox " + (props.className ? props.className : "")}
            showClearButton={props.isShowClearButton === true}
            onValueChanged={(e) => {
                props.onValueChanged(e.value)
            }} />
    );
}

