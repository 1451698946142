import FileUploader from "devextreme-react/file-uploader";
import SelectBox from "devextreme-react/select-box";
import { Column } from "devextreme-react/data-grid";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { appInfo } from "../../../AppInfo";
import { hachToanCongNoApi } from "../../../api/hachToanCongNopApi";
import { Button } from "../../../components/buttons";
import { DefaultDataGrid } from "../../../components/data-grid";
import { AnimationPopup } from "../../../components/modal";
import { PlaceHolder } from "../../../components/place-holder";
import { Spinner } from "../../../components/spinners";
import { useCommonContext } from "../../../contexts/common";
import { showNotify } from "../../../helpers/toast";
import { PageBaseStatus } from "../../../models/pageBaseStatus";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/reducers";
type ImportHachToanModalProps = {
    onClose: () => void,
    onSuccess: () => void,
    nam_hoc: string,
    animationOf: string
}
type ImportHachToanModelState = {
    status: PageBaseStatus,
    file_name: string,
    sheets: any[],
    sheet_index: number,
    data_source: any[],
    root_file_name: string
}
const initialState: ImportHachToanModelState = {
    status: PageBaseStatus.is_completed,
    data_source: [],
    file_name: "",
    sheet_index: -1,
    sheets: [],
    root_file_name: ""
}
export const ImportHachToanModal = (props: ImportHachToanModalProps) => {
    const [formData, SetFormData] = useState<ImportHachToanModelState>(initialState);
    const [isLoading, SetIsLoading] = useState<boolean>(false)
    const [isLoadingSheets, SetIsLoadingSheets] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const { dm_coso_id } = useSelector((x: RootState) => x.common);
    const { createUUID } = useCommonContext();
    const onClose = useCallback(() => {
        window.animationHidePopup(props.animationOf || "")
        setTimeout(() => {
            props.onClose();
        }, 500);
    }, [props.animationOf])
    const grid = useRef<any>();
    const {
        status,
        file_name,
        sheets,
        sheet_index,
        data_source, root_file_name
    } = formData;

    useEffect(() => {
        if (file_name !== "" && sheets.length > 0 && sheet_index >= 0) {
            reloadDatas();
        }
    }, [file_name, sheets]);


    const errr_code = useMemo(() => {
        const count = data_source.filter(x => !x.is_valid).length;
        if (count > 0) {
            return `Có ${count} dòng không hợp lệ, vui lòng kiểm tra lại dữ liệu`;
        }
        return "";
    }, [data_source]);

    const reloadDatas = async () => {
        SetIsLoading(true);
        const interval_obj = setInterval(() => {
            if (isLoaded) {
                SetIsLoading(false)
                clearInterval(interval_obj);
            }
        }, 500)
        let isLoaded = false;
        const res = await hachToanCongNoApi.readFiles({
            file_name: file_name, sheet_index: sheet_index,
            nam_hoc: props.nam_hoc, dm_coso_id: dm_coso_id, root_file_name: root_file_name
        });
        if (res.is_success) {
            SetFormData({ ...formData, data_source: res.data })
        } else {
            showNotify({ message: res.message || "Có lỗi, vui lòng kiểm tra lại sheet", type: "error" })
            SetFormData({ ...formData, data_source: [] })
        }
        isLoaded = true;

    }
    const handleSaveChanges = async () => {
        setIsSaving(true)
        const res = await hachToanCongNoApi.import({
            file_name: file_name, sheet_index: sheet_index,
            nam_hoc: props.nam_hoc, dm_coso_id: dm_coso_id, root_file_name:root_file_name
        });
        setIsSaving(false)
        if (res.is_success) {
            showNotify({ message: "Import thành công", type: 'success' })
            props.onSuccess();
        } else {
            showNotify({ message: res.message || "Có lỗi", type: "error" })
        }
    };
    const onUploaded = (e: any) => {
        if (e && e.request && e.request.response) {
            const res = JSON.parse(e.request.response)
            // console.log(res);
            if (res.is_success) {
                SetFormData({ ...formData, root_file_name: e.file.name, file_name: res.data.url, sheets: res.data.sheets, sheet_index: res.data.sheets.length > 0 ? 0 : -1 })
            }
        }
    }
    const columns: any = useMemo(() => {
        return [
            <Column key={createUUID()} dataField="id" caption="TT" width={60} />,
            <Column key={createUUID()} dataField="ma_hs" caption="Mã HS" width={150} />,
            <Column key={createUUID()} dataField="noi_dung" caption="Nội dung" width={200} />,
            <Column key={createUUID()} dataField="tong_tien" caption="Tổng tiền" width={110} />,
            <Column key={createUUID()} dataField="Hoan_tra_hay_thu_bo_sung" caption="Hoàn trả/ thu bổ sung" width={120} />,
            <Column key={createUUID()} dataField="Ap_dung_cho_ky_nop" caption="Đợt nộp áp dụng" width={200} />,
            <Column key={createUUID()} dataField="is_valid" caption="Hợp lệ" width={100} />,
            <Column key={createUUID()} dataField="error" caption="Mã lỗi" minWidth={200} />
        ]
    }, []);
    return (
        <AnimationPopup
            width={'80%'}
            title={`Import khoản cần quyết toán năm học ${props.nam_hoc}`}
            animationOf={props.animationOf}
        >
            <div>
                <div className="pop-up-form">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-inline" style={{ width: "100%" }}>
                                <div className="form-group" style={{ width: "33%" }}>
                                    <FileUploader
                                        multiple={false}
                                        accept={".xls,.xlsx"}
                                        uploadMode={"instantly"}
                                        uploadHeaders={{ Authorization: `Bearer ${localStorage.access_token}` }}
                                        uploadUrl={`${appInfo.baseApiURL.replace('/api', '')}/sf_hachtoan_congno/upload_file`}
                                        uploadMethod='POST'
                                        showFileList={false}
                                        onUploaded={onUploaded}
                                        labelText='hoặc kéo thả file tại đây để upload'
                                        selectButtonText='Chọn file'
                                    />
                                </div>
                                <div className="form-group" style={{ width: "33%" }}>
                                    <label className="form-label">Chọn sheet</label>
                                    <SelectBox
                                        dataSource={sheets}
                                        stylingMode="outlined"
                                        displayExpr={"name"}
                                        valueExpr={"idx"}
                                        value={sheet_index}
                                        width={200}
                                        readOnly={isLoadingSheets}
                                        placeholder="Chọn sheet"
                                        onValueChanged={(e) => {
                                            SetFormData({ ...formData, sheet_index: e.value })
                                        }}
                                    />
                                    {isLoadingSheets && <Spinner isBlack={true} />}
                                </div>
                                <div className="form-group" style={{ flexFlow: "row-reverse", width: "33%" }} >
                                    <a href="../../templates/template_import_cong_no.xlsx" target="_blank">Tải file mẫu</a>
                                </div>
                            </div>


                        </div>

                        <div className="col-md-12">
                            {errr_code && <p className="text-danger padding-top-1rem">{errr_code}</p>}
                        </div>
                        <div className="col-md-12 padding-top-1rem">
                            {isLoading && <PlaceHolder line_number={20} />}
                            {!isLoading &&
                                <DefaultDataGrid
                                    columns={columns}
                                    dataSource={data_source}
                                    gridRef={grid}
                                    keyExpr="id"
                                    height={window.innerHeight - 350}
                                    width={window.innerWidth - 410}

                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="pop-up-actions">
                    <Button type="normal" icon="fas fa-times" text="Đóng" onClick={onClose}></Button>{' '}
                    <Button type="normal" icon="fas fa-file-import" text="Import" onClick={handleSaveChanges} isLoading={isSaving} isDisabled={errr_code != ""} />
                </div>
            </div>
        </AnimationPopup>
    )
}