
import { Select } from '@primer/react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCommonContext } from '../../contexts/common';
import { dm_truong } from '../../models/response/category/dm_truong';
import { actions } from '../../state/actions/actionsWrapper';
import { RootState } from '../../state/reducers';
type ComboboxTruongProps = {
    isReadonly?: boolean,
    value?: number,
    onValueChanged: (id: number, data?: dm_truong) => void,
    className?: string,
    isShowClearButton?: boolean,
    preText?: string,
    stylingMode?: "outlined" | "filled" | "underlined"
}
const ComboboxTruong = (props: ComboboxTruongProps) => {
    const { dm_coso_id } = useSelector((x: RootState) => x.common);
    const { translate } = useCommonContext();
    const dispatch = useDispatch();
    const categorySource = useSelector((state: RootState) => state.categorySource);

    useEffect(() => {
        if (categorySource.dm_truongs.length === 0) dispatch(actions.categorySource.loadDmTruongStart());
    }, []);

    const source: dm_truong[] = useMemo(() => {
        return categorySource.dm_truongs.filter((x:any) => x.viet_tat !=='na').map(x => ({ ...x }));
    }, [categorySource.dm_truongs, dm_coso_id]);
    return (
        <Select onChange={(e) => {
            const dm_truong_id: number = e.currentTarget.value ? parseInt(e.currentTarget.value) : 0
            props.onValueChanged(dm_truong_id, source.find(x => x.id == dm_truong_id))
        }}
            value={`${props.value ?? 0}`}
            placeholder={translate("Chọn trường")}
        >
            {source.map(x => {
                return (
                    <Select.Option key={x.id} value={`${x.id}`}>{x.viet_tat}</Select.Option>
                );
            })}
        </Select>
    );

}
export { ComboboxTruong };

