import { saveAccessToken, saveRefreshToken } from "../../api/apiClient";
import { ILoginOIDCRequest } from "../../models/request/account/ILoginOIDCRequest";
import { ILoginData } from "../../models/response/account/login-response";
import { IUserInfoModel } from "../../models/response/account/user-info";
import { IAccountLoginError, IAccountLoginStart, IAccountLoginSuccess, IAuthGetUserError, IAuthGetUserStart, IAuthGetUserSuccess, eAuthAcitonTypes } from "../action-types/IAuthActionTypes";
import { baseAction } from "./IActionBase";

export const accountActions = {
    getUserStart: (): IAuthGetUserStart => baseAction(eAuthAcitonTypes.GET_USER_START, undefined),
    getUserSuccss: (data: IUserInfoModel): IAuthGetUserSuccess => baseAction(eAuthAcitonTypes.GET_USER_SUCCESS, data),
    getUserError: (message?: string): IAuthGetUserError => baseAction(eAuthAcitonTypes.GET_USER_ERROR, message),

    loginStart: (request: ILoginOIDCRequest): IAccountLoginStart =>
        baseAction(eAuthAcitonTypes.LOGIN_START, request),
    loginSuccess: (response: ILoginData): IAccountLoginSuccess => {
        saveAccessToken(response.token_info.access_token)
        saveRefreshToken(response.token_info.refresh_token)
        return baseAction(eAuthAcitonTypes.LOGIN_SUCCESS, response);
    },
    loginError: (message: string): IAccountLoginError =>
        baseAction(eAuthAcitonTypes.LOGIN_ERROR, message),


    logOutStart: () => {
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        return baseAction(eAuthAcitonTypes.LOGOUT_SUCCESS, undefined)
    }
}

