import moment from 'moment';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Search } from 'semantic-ui-react';
import { hocSinhApi } from '../../api/hocSinhApi';
import { RootState } from '../../state/reducers';
import styles from "./GlobalSearch.module.css";
interface IGlobalSearchProps {
    onResultChanged: (hocSinh: any) => void
}
interface IHocSinhSeachInfo {
    id: number,
    ho_ten: string,
    ma_hs: string,
    ten_lop: string,
    dm_truong_id: number
}
interface ISearchItem {
    hoc_sinh: IHocSinhSeachInfo,
}
interface ISearchState {
    loading: boolean,
    results: ISearchItem[],
    value: string
}
const initialState: ISearchState = {
    loading: false,
    results: [],
    value: '',
};

const globalSearchReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'CLEAN_QUERY':
            return initialState;
        case 'START_SEARCH':
            return { ...state, loading: true, value: action.query };
        case 'FINISH_SEARCH':
            return { ...state, loading: false, results: action.results };
        case 'UPDATE_SELECTION':
            return { ...state, value: action.selection };

        default:
            throw new Error();
    }
};
interface ISearchTextRenderProps {
    text: string,
    searchKey: string
}
const SearchTextRender = (props: ISearchTextRenderProps) => {


    const { text, searchKey } = props;
    if (!searchKey || !text) {
        return (
            <span>
                {text ?? ""}
            </span>
        )
    }

    // const chars = text.split('');
    const index = text.toLocaleLowerCase().indexOf(searchKey.toLocaleLowerCase());
    if (index < 0) {
        return (
            <span>
                {text ?? ""}
            </span>
        )
    }
    const pre = text.substring(0, index);
    const after = text.substring(index + searchKey.length, text.length)

    return (
        <span>
            {pre}
            {index >= 0 &&
                <span className={styles.highlight}>{text.substring(index, index + searchKey.length)}</span>
            }
            {after}
        </span>
    );
}
const GlobalSearch = (props: IGlobalSearchProps) => {
    const { onResultChanged } = props;
    const { dm_truong_selected_id, language } = useSelector((x: RootState) => x.common)
    const { dm_coso_id } = useSelector((state: RootState) => state.common)
    const { nam_hoc } = useSelector((state: RootState) => state.common)
    const [state, dispatch] = React.useReducer(globalSearchReducer, initialState);
    const { loading, results, value } = state;
    const timeoutRef = React.useRef<any>();
    const searchKey = useRef<any>("")
    const handleSearchChange = React.useCallback(
        (e:any, data:any) => {
            clearTimeout(timeoutRef.current);
            dispatch({ type: 'START_SEARCH', query: data.value });

            timeoutRef.current = setTimeout(() => {
                if (data.value.length === 0) {
                    dispatch({ type: 'CLEAN_QUERY' });
                    return;
                }
                (async () => {
                    const res = await hocSinhApi.search({
                        key: data.value,
                        dm_coso_id, nam_hoc
                    })
                    if (res.is_success) {
                        dispatch({
                            type: 'FINISH_SEARCH',
                            results: res.data.map((x: any) => ({ ...x, ts_hocsinh_id: x.id, title: `${x.ho_ten} - ${x.ma_hs}` }))
                        });
                    } else {
                        return state;
                    }
                })();
            }, 500);
        },
        [dm_coso_id]
    );

    const resultRenderer = (data: any) => {
        return (
            <React.Fragment>
                <div className={styles.item_container}>
                    <>
                        <Link to={`/hoc-sinh/${data.ts_hocsinh_id}`} style={{
                            color: "#182d62"
                        }}>
                            <div className={styles.item_container}>
                                <div className={styles.info_container}>
                                    <div className={styles.item_title}>{data.ho_ten}</div>
                                    <div className={styles.item_description}>{data.ma_hs} - {moment(data.ngay_sinh).format("DD/MM/YYYY")},</div>
                                    <div className={styles.item_description}>{data.ten_khoi}, Lớp {data.ten_lop}</div>
                                    <div className={styles.item_description}>Cha: {data.ho_ten_cha}, Mẹ: {data.ho_ten_me}</div>
                                </div>
                            </div>
                        </Link>
                    </>
                </div>

            </React.Fragment>
        );

    };
    return (
        <div className={styles.container}>
            <Search
                loading={loading}
                onResultSelect={(e, data) => {
                    dispatch({ type: 'UPDATE_SELECTION', selection: data.result.title });
                    if (onResultChanged) {
                        onResultChanged(data.result);
                    }

                }}
                onSearchChange={handleSearchChange}
                results={results ?? []}
                resultRenderer={resultRenderer}
                value={value ?? ''}
                input={{
                    icon: 'search',
                    placeholder: 'Search student',
                }}
                fluid
                className={styles.search}
            ></Search>
        </div>
    );
};

export default GlobalSearch;