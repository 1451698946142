import { Column } from "devextreme-react/data-grid";
import FileUploader from "devextreme-react/file-uploader";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { appInfo } from "../../../AppInfo";
import { mucPhaiThuApi } from "../../../api/mucPhaiThuApi";
import { tbtpApi } from "../../../api/tbtpApi";
import SeparatedDataCell from '../../../components/SaleSetRegisterDichVu/SeparatedDataCell';
import { Button } from "../../../components/buttons";
import { DefaultDataGrid } from "../../../components/data-grid";
import { DefaultPopup } from "../../../components/modal";
import { PlaceHolder } from "../../../components/place-holder";
import { useCommonContext } from "../../../contexts/common";
import { showNotify } from "../../../helpers/toast";
import { PageBaseStatus } from "../../../models/pageBaseStatus";
import { sf_tbtp_viewmodel } from "../../../models/response/tbtp/sf_tbtp";
import { TBTPEditModal } from "../../tbtp/edit_tbtp_modal";

type KhoanNopImportModalProps = {
  onClose: () => void;
  onSuccess: () => void;
  nam_hoc: string;
  dm_coso_id: number;
  animationOf: string;
};
type KhoanNopImportModalState = {
  status: PageBaseStatus;
  file_name: string;
  sheets: any[];
  sheet_index: number;
  data_source: any[];
};
const initialState: KhoanNopImportModalState = {
  status: PageBaseStatus.is_completed,
  data_source: [],
  file_name: "",
  sheet_index: -1,
  sheets: [],
};
export const KhoanNopImportModal = (
  props: KhoanNopImportModalProps
) => {
  const [formData, SetFormData] =
    useState<KhoanNopImportModalState>(initialState);
  const [isLoading, SetIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoadingSheets, SetIsLoadingSheets] = useState<boolean>(false);
  const [tbtpView, SetTBTPView] = useState<sf_tbtp_viewmodel>()
  const { createUUID } = useCommonContext();
  const onClose = useCallback(() => {
    window.animationHidePopup(props.animationOf || "");
    setTimeout(() => {
      props.onClose();
    }, 500);
  }, [props.animationOf]);
  const grid = useRef<any>();
  const { status, file_name, sheets, sheet_index, data_source } = formData;
  useEffect(() => {
    if (file_name) {
      reloadSheets();
    }
  }, []);
  useEffect(() => {
    if (file_name !== "" && sheets.length > 0 && sheet_index >= 0) {
      reloadDatas();
    }
  }, [file_name, sheet_index, sheets]);

  const errr_code = useMemo(() => {
    const count = data_source.filter((x) => x.err !== "").length;
    if (count > 0) {
      return `Có ${count} dòng không hợp lệ, vui lòng kiểm tra lại dữ liệu`;
    }
    return "";
  }, [data_source]);

  const reloadSheets = async () => {
    SetIsLoadingSheets(true);
    const res = await mucPhaiThuApi.getSheets(file_name);
    SetIsLoadingSheets(false);
    if (res.is_success) {
      SetFormData({ ...formData, sheets: res.data });
      if (res.data.length >= 1) {
        SetFormData({ ...formData, sheet_index: 0 });
      }
    }
  };
  const reloadDatas = async () => {
    SetIsLoading(true);
    const res = await mucPhaiThuApi.readFiles_khoannop_v2({
      file_name: file_name,
      sheet_index: sheet_index,
      nam_hoc: props.nam_hoc,
      dm_coso_id: props.dm_coso_id
    });
    SetIsLoading(false);
    if (res.is_success) {
      SetFormData({ ...formData, data_source: res.data });
    } else {
      showNotify({
        message: res.message || "Có lỗi, vui lòng kiểm tra lại sheet",
        type: "error",
      });
      SetFormData({ ...formData, data_source: [] });
    }
  };
  const handleSaveChanges = async () => {
    setIsSaving(true);
    const res = await mucPhaiThuApi.import_khoannop_v2({
      file_name: file_name,
      sheet_index: sheet_index,
      nam_hoc: props.nam_hoc,
      dm_coso_id: props.dm_coso_id
    });
    setIsSaving(false);
    if (res.is_success) {
      showNotify({ message: "Import thành công", type: "success" });
      props.onSuccess();
    } else {
      showNotify({ message: res.message || "Có lỗi", type: "error" });
    }
  };
  const btnPreview_click = async (data: any) => {
    const res = await tbtpApi.select_by_id(data.TBTP_Preview)
    if (res.is_success) {
      SetTBTPView(res.data)
    }
  };
  const onUploaded = (e: any) => {
    if (e && e.request && e.request.response) {
      const res = JSON.parse(e.request.response);
      if (res.is_success) {
        SetFormData({
          ...formData,
          file_name: res.data.url,
          sheets: res.data.sheets,
          sheet_index: res.data.sheets.length > 0 ? 0 : -1,
        });
        //SetFormData({ ...formData, file_name: res.data[0].file_name_uploaded })
      }
    }
  };
  const columns: any = useMemo(() => {
    const objectData = data_source[0];
    const excludedFields = ["ID", "Ma_hoc_sinh", "Ma_hinh_thuc_thanh_toan", "Ma_chuan_chi", "Noi_dung", "Ngay_thu_thuc_te", "TBTP", "TBTP_Preview", "id", "err",];

    const generatedColumns = [
      <Column key={createUUID()} dataField="id" alignment="center" caption="TT" width={80} />,
      <Column
        key={createUUID()}
        dataField="Ma_hoc_sinh"
        alignment="center"
        caption="Mã HS"
        width={150}
        cellRender={(cell) => <SeparatedDataCell value={cell.value} infor={'Mã học sinh'} />}
      />,
      <Column
        key={createUUID()}
        dataField="Ma_hinh_thuc_thanh_toan"
        alignment="center"
        caption="Mã hình thức thanh toán"
        width={120}
        cellRender={(cell) => <SeparatedDataCell value={cell.value} infor={'Mã hình thức thanh toán'} />}
      />,
      <Column
        key={createUUID()}
        dataField="Ma_chuan_chi"
        alignment="center"
        caption="Mã chuẩn chi"
        width={150}
      />,
      <Column
        key={createUUID()}
        dataField="Noi_dung"
        alignment="center"
        caption="Nội dung"
        minWidth={200}
        cellRender={(cell) => <SeparatedDataCell value={cell.value} infor={'Nội dung'} />}
      />,
      <Column
        key={createUUID()}
        dataField="Ngay_thu_thuc_te"
        alignment="center"
        caption="Ngày thu thực tế"
        width={200}
        cellRender={(cell) => <SeparatedDataCell value={cell.value} infor={'Ngày thu thực tế'} />}
      />,
      <Column
        key={createUUID()}
        dataField="TBTP_Preview"
        caption="TBTP Preview"
        alignment='center'
        minWidth={100}
        cellRender={(cell: any) => {
          return <div>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                btnPreview_click(cell.data);
              }}
            >
              <i aria-hidden="true" className="fa fa-eye" style={{ color: '#54CA7D' }}></i>{' '}
            </span>
          </div>
        }}
      />,
      <Column
        key={createUUID()}
        dataField="TBTP"
        caption="TBTP"
        format=",##0"
        alignment='right'
        minWidth={150}
        cellRender={(cell) => <SeparatedDataCell value={cell.value} infor={'TBTP'} />}

      />,
    ];
    for (const key in objectData) {
      if (objectData.hasOwnProperty(key) && !excludedFields.includes(key)) {
        const column = (
          <Column
            key={createUUID()}
            dataField={key}
            caption={key}
            alignment="center"
            width={100}
            cellRender={(cell) => <SeparatedDataCell value={cell.value} infor={key} />}
          />
        );
        generatedColumns.push(column);
      }
    }
    generatedColumns.push(
      <Column
        key={createUUID()}
        dataField="err"
        caption="Thông tin lỗi"
        alignment="center"
        minWidth={400}
        cellRender={(cell) => <SeparatedDataCell value={cell.value} infor={'Thông tin lỗi'} />}
      />
    );

    return generatedColumns;
  }, [data_source]);
  return (
    <DefaultPopup
      maxWidth={1200}
      title={`Import chi tiết khoản năm học ${props.nam_hoc}`}
    >
      <div>
        <div className="pop-up-form">
          <div className="row">
            <div className="col-md-12">
              <div className="form-inline" style={{ width: "100%" }}>
                {file_name === "" && (
                  <div className="form-group" style={{ width: "50%" }}>
                    <FileUploader
                      multiple={false}
                      accept={".xls,.xlsx"}
                      uploadMode={"instantly"}
                      uploadHeaders={{
                        Authorization: `Bearer ${localStorage.access_token}`,
                      }}
                      uploadUrl={`${appInfo.baseApiURL}/sf-mucphaithu/upload_file`}
                      uploadMethod="POST"
                      showFileList={false}
                      onUploaded={onUploaded}
                      labelText="hoặc kéo thả file tại đây để upload"
                      selectButtonText="Chọn file"
                    />
                  </div>
                )}
                <div
                  className="form-group"
                  style={{ flexFlow: "row-reverse", width: "100%" }}
                >
                  <a
                    href="../../templates/template_import_khoannop.xlsx"
                    target="_blank"
                  >
                    Tải file mẫu
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              {errr_code && (
                <p className="text-danger padding-top-1rem">{errr_code}</p>
              )}
            </div>
            <div className="col-md-12 padding-top-1rem">
              {isLoading && <PlaceHolder line_number={20} />}
              {!isLoading && (
                <DefaultDataGrid
                  columns={columns}
                  dataSource={data_source}
                  gridRef={grid}
                  keyExpr="id"
                  width={window.innerWidth-100}
                  height={window.innerHeight - 350}
                />
              )}
            </div>
            {tbtpView && <TBTPEditModal
              onCancel={() => { SetTBTPView(undefined) }}
              isReadOnly={true}
              sf_tbtp={tbtpView}
            />}
          </div>
        </div>
        <div className="pop-up-actions">
          <Button type="normal" icon="fa fa-times" text="Đóng" onClick={onClose}></Button>{" "}
          <Button
            type="normal"
            text="Cập nhật"
            icon="fas fa-check-square"
            onClick={handleSaveChanges}
            isLoading={isSaving}
            isDisabled={errr_code !== ""}
          />
        </div>
      </div>
    </DefaultPopup>
  );
};
