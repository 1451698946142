import DataGrid, { Column, Grouping, GroupItem, Sorting, StateStoring, Summary, TotalItem } from "devextreme-react/data-grid";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hinhThucNopBomApi } from "../../../../api/hinhThucNopBom";
import { AddKhoanNopModal, AddKhoanNopModalRespone } from "../../../../components/add-khoanop-modal";
import { Button } from "../../../../components/buttons";
import { DefaultConfirm } from "../../../../components/confirm";
import { PlaceHolder } from "../../../../components/place-holder";
import { useCommonContext } from "../../../../contexts/common";
import { PageBaseStatus } from "../../../../models/pageBaseStatus";
import { sf_tbtp_add_khoannop_rq } from "../../../../models/request/tbtp/sf_tbtp_add_khoannop_rq";
import { sf_tbtp_insert_item } from "../../../../models/request/tbtp/sf_tbtp_insert_rq";
import { sf_bom_chitiet_add_tbtp_model } from "../../../../models/response/bom/sf_bom_chitiet";
import { sf_hinhthucnopbom } from "../../../../models/response/category/sf_hinhthucnopbom";
import { sf_tbtp_viewmodel } from "../../../../models/response/tbtp/sf_tbtp";
import {
    closeDeleteConfirm,
    closeKhoanNopAddModal,
    deleteKhoanNopStart, loadKhoanNopStart, saveKhoanNopStart, showDeleteConfirm, showKhoanNopAddModal
} from "../../../../state/actions/edit-tbtp/editTBTPKhoanNopActions";

import { RootState } from "../../../../state/reducers";
import { localStorageHelper } from '../../../../helpers/localStorage';
import { ts_hocsinh_viewmodel } from "../../../../models/response/hocsinh/ts_hocsinh";
import { hocSinhApi } from "../../../../api/hocSinhApi";
import { sf_mucphaithu_select_byhocsinh_rq } from "../../../../models/request/thu-phi/sf_mucphaithu_select_byhocsinh_rq";

type EditTBTPKhoanNopProps = {
    sf_tbtp_viewmodel: sf_tbtp_viewmodel,
    onChanged: () => void,
    isReadOnly?: boolean
}
// const sf_hinhthucnopboms_all: sf_hinhthucnopbom[] = [
//     { id: 2, name: "Học kỳ I", is_active: true },
//     { id: 3, name: "Học kỳ II", is_active: true },
//     { id: 1, name: "Cả năm", is_active: true }
// ];
export const EditTBTPKhoanNop = (props: EditTBTPKhoanNopProps) => {
    const { getDataGridStorageKey } = useCommonContext();
    const dataGridStateStorageKey = getDataGridStorageKey("editTBTPKhoanNop")
    const grid = useRef<any>();
    const state = useSelector((x: RootState) => x.editTBTPKhoanNop)

    const [ts_hocsinh_viewmodel, set_ts_hocsinh_viewmodel] = useState<ts_hocsinh_viewmodel>();

    const {
        sf_hachtoan_congnos_added,

    } = useSelector((x: RootState) => x.editTBTPHachToan);
    const dispatch = useDispatch();
    const [khoanDaNops, setKhoanDaNops] = useState<number[]>([]);
    const [sf_hinhthucnopboms_all, set_sf_hinhthucnopboms_all] = useState<sf_hinhthucnopbom[]>([]);
    const {
        is_show_confirm_remove_khoannop,
        is_show_modal_add_khoannop,
        sf_khoannop_editing_id,
        sf_tbtp_khoannops,
        status,
        sf_tbtp_viewmodel
    } = state;
    const sf_hinhthucnopbom_ids = useMemo(() => {

        const result = Array.from(new Set(sf_tbtp_khoannops.map(x => x.sf_hinhthucnopbom_id)));
        if (result.length == 0) {
            return (props.sf_tbtp_viewmodel?.sf_hinhthucnopbom_ids ?? '').split(',').map(x => parseInt(x)).filter(x => x > 0);
        }
        return result;
    }, [sf_tbtp_khoannops, props.sf_tbtp_viewmodel]);

    const sf_hinhthucnopboms = useMemo(() => {
        return sf_hinhthucnopboms_all.filter(x => sf_hinhthucnopbom_ids.includes(x.id))
    }, [sf_hinhthucnopboms_all, sf_hinhthucnopbom_ids])
    useEffect(() => {
        handleReloadHinhThucNopBoms();
        handleReloadHocSinh();
    }, [])
    useEffect(() => {
        handleReload();
    }, [props.sf_tbtp_viewmodel.id])

    useEffect(() => {
        if (status === PageBaseStatus.is_need_reload) {
            dispatch(loadKhoanNopStart(props.sf_tbtp_viewmodel.id))
        }
    }, [status])


    const handleReloadMucPhaiThuChiTiet = async () => {
        // const request = { nam_hoc: localStorageHelper.getNamHoc(), ts_hocsinh_id: props.ts_hocsinh_id }
        // const res = await mucPhaiThuChiTietApi.select_all_khoannop(request)
        // if (res.is_success) {
        //     const khoanDaNopIds = res.data.map((x: any) => x.sf_khoannop_id);
        //     setKhoanDaNops(khoanDaNopIds)
        // }
    }
    const handleReloadHocSinh = async () => {
        if (props.sf_tbtp_viewmodel) {
            const datasend: sf_mucphaithu_select_byhocsinh_rq= { ts_hocsinh_id: props.sf_tbtp_viewmodel.ts_hocsinh_id, nam_hoc: props.sf_tbtp_viewmodel.nam_hoc }
            const res = await hocSinhApi.selectByIdNamHoc(datasend);

            if (res.is_success) {
                set_ts_hocsinh_viewmodel(res.data)
            }
        }
    }
    const handleReloadHinhThucNopBoms = async () => {
        const res = await hinhThucNopBomApi.selectAll();
        if (res.is_success) {
            set_sf_hinhthucnopboms_all(res.data)
        }
    }
    const handleReload = useCallback(() => {
        dispatch(loadKhoanNopStart(props.sf_tbtp_viewmodel.id))
        // dispatch(loadHinhThucNopBomStart(props.sf_tbtp_viewmodel.sf_bom_id))
    }, [props.sf_tbtp_viewmodel.id])

    const btnAddKhoanNop_Click = useCallback(() => {
        dispatch(showKhoanNopAddModal())

    }, [])

    const handleShowDeleteConfirm = useCallback((sf_khoannop_id: number) => {
        dispatch(showDeleteConfirm(sf_khoannop_id))
    }, [props.sf_tbtp_viewmodel])

    const handleCloseDeleteConfirm = useCallback(() => {
        dispatch(closeDeleteConfirm())

    }, [])
    const handleCloseAddModal = useCallback(() => {
        dispatch(closeKhoanNopAddModal())

    }, [])
    const btnExportClick = () => {
        if (grid && grid.current && grid.current.instance) {
            // let source = [...dataSource];
            // sf_hachtoan_congnos_added.forEach(congNo => {
            //     let item:any =
            //     {
            //         ten_khoan_nop_group: "Hoàn phí/ Quyết toán",
            //         ma_khoan_nop: "",
            //         ten_khoan_nop: congNo.noi_dung,
            //         sf_khoannop_id:-1* congNo.id
            //     };
            //     sf_hinhthucnopboms.forEach(ht=>{
            //         item[`PN_${ht.id}`] = congNo.is_hoan_tra ? -1 *congNo.tong_tien : congNo.tong_tien;
            //     })
            //     source.push(item);
            // })
            // console.log({
            //     source
            // });
            // debugger
            // grid.current.instance.option('dataSource', source);
            // grid.current.instance.refresh();
            grid.current.instance.exportToExcel();
            // grid.current.instance.option('dataSource', dataSource);
            // grid.current.instance.refresh();
        }
    }
    const handleSubmitAddKhoanNop = (data: AddKhoanNopModalRespone) => {
        if (data.sf_bom_chitiets && data.sf_bom_chitiets.length > 0) {
            const rq: sf_tbtp_add_khoannop_rq = {
                is_calculate_miengiam: data.is_calculate_miengiam,
                sf_bom_chitiets: data.sf_bom_chitiets.map((x: sf_bom_chitiet_add_tbtp_model) => {
                    const obj: sf_tbtp_insert_item = {
                        ghi_chu: "",
                        id: x.id,
                        ma_khoan_nop: x.ma_khoan_nop,
                        sf_bom_id: x.sf_bom_id,
                        sf_hinhthucnopbom_id: data.sf_hinhthucnopbom_id,
                        sf_khoannop_id: x.sf_khoannop_id,
                        sf_mucthuphi_id: x.sf_mucthuphi_id,
                        so_tien_ap_dung: x.so_tien_ap_dung,
                        so_tien_dinh_muc: x.so_tien_dinh_muc

                    }
                    return obj;
                }),
                sf_tbtp_id: props.sf_tbtp_viewmodel.id
            }

            dispatch(saveKhoanNopStart(rq))
            props.onChanged();
        }

    }
    const handleRemoveKhoanNop = async () => {
        dispatch(deleteKhoanNopStart({
            sf_khoannop_id: sf_khoannop_editing_id,
            sf_tbtp_id: props.sf_tbtp_viewmodel.id
        }))
        props.onChanged();
    }

    const dataSource = useMemo(() => {
        let _sf_khoannops = [];
        for (let i = 0; i < sf_tbtp_khoannops.length; i++) {
            const sf_tbtp_khoannop_viewmodel = sf_tbtp_khoannops[i];
            if (_sf_khoannops.find(x => x.sf_khoannop_id === sf_tbtp_khoannop_viewmodel.sf_khoannop_id) === undefined) {
                _sf_khoannops.push({
                    sf_khoannop_id: sf_tbtp_khoannop_viewmodel.sf_khoannop_id,
                    ten_khoan_nop: sf_tbtp_khoannop_viewmodel.ten_khoan_nop,
                    ten_khoan_nop_group: sf_tbtp_khoannop_viewmodel.ten_khoan_nop_group,
                    ma_khoan_nop: sf_tbtp_khoannop_viewmodel.ma_khoan_nop
                });
            }
        }
        const result = _sf_khoannops.map(_sf_khoannop => {
            let item: any = { ..._sf_khoannop }
            for (let i = 0; i < sf_hinhthucnopboms.length; i++) {
                const sf_hinhthucnopbom = sf_hinhthucnopboms[i];
                const sf_tbtp_khoannop_viewmodel = sf_tbtp_khoannops.find(x => x.sf_khoannop_id === item.sf_khoannop_id
                    && x.sf_hinhthucnopbom_id === sf_hinhthucnopbom.id)
                if (sf_tbtp_khoannop_viewmodel) {
                    item[`DM_${sf_hinhthucnopbom.id}`] = sf_tbtp_khoannop_viewmodel.so_tien_ap_dung;
                    item[`MG_${sf_hinhthucnopbom.id}`] = sf_tbtp_khoannop_viewmodel.so_tien_mien_giam;
                    item[`PN_${sf_hinhthucnopbom.id}`] = sf_tbtp_khoannop_viewmodel.so_tien_ap_dung - sf_tbtp_khoannop_viewmodel.so_tien_mien_giam;
                }
            }
            return item;
        });
        // let source = [...result];
        sf_hachtoan_congnos_added.forEach(congNo => {
            // console.log({congNo});

            let item: any =
            {
                ten_khoan_nop_group: "Hoàn phí/ Quyết toán",
                ma_khoan_nop: "",
                ten_khoan_nop: congNo.noi_dung,
                sf_khoannop_id: -1 * congNo.id
            };
            sf_hinhthucnopboms.forEach(ht => {
                if (congNo.sf_hinhthucnopbom_ids.split(',').includes(ht.id.toString())) {
                    item[`PN_${ht.id}`] = congNo.is_hoan_tra ? -1 * congNo.tong_tien : congNo.tong_tien;
                }
            })
            result.push(item);
        })
        return result;
    }, [sf_tbtp_khoannops, sf_hinhthucnopboms, sf_hachtoan_congnos_added]);

    const DynamicColumn = useMemo(() => {

        return sf_hinhthucnopboms.map((x, idx) => {
            return (
                <Column key={idx} caption={x.name} alignment={"center"} width={300}>
                    <Column dataField={`DM_${x.id}`} format=",##0" caption="Mức thu" width={120}></Column>
                    <Column dataField={`MG_${x.id}`} format=",##0" caption="Giảm trừ" width={120} cellRender={(cell) => {
                        if (cell.data[`MG_${x.id}`] !== 0) {
                            if (cell.data[`MG_${x.id}`]) {
                                return <div>{cell.data[`MG_${x.id}`].toLocaleString()}</div>
                            }
                        } else {
                            return <div>-</div>
                        }
                    }}></Column>
                    <Column dataField={`PN_${x.id}`} format=",##0" caption="Thành tiền" width={120}></Column>
                </Column>
            );
        })
    }, [sf_hinhthucnopboms]);

    const DynamicSummary = useMemo(() => {
        return <Summary>
            {sf_hinhthucnopboms.map((x, idx) => {
                return (
                    <GroupItem
                        key={idx}
                        column={`DM_${x.id}`}
                        summaryType="sum"
                        valueFormat=",##0"
                        showInGroupFooter={false}
                        displayFormat="{0}"
                        alignByColumn={true} />
                );
            })}
            {sf_hinhthucnopboms.map((x, idx) => {
                return (
                    <TotalItem
                        key={idx}
                        column={`DM_${x.id}`}
                        summaryType="sum"
                        valueFormat=",##0"
                        displayFormat="{0}"
                    />
                );
            })}
            {sf_hinhthucnopboms.map((x, idx) => {
                return (

                    <GroupItem
                        key={idx}
                        column={`MG_${x.id}`}
                        summaryType="sum"
                        valueFormat=",##0"
                        showInGroupFooter={false}
                        displayFormat="{0}"
                        customizeText={(e: any) => {
                            if (e.value !== 0) {
                                return e.value.toLocaleString();
                            } else {
                                return "-";
                            }

                        }}
                        alignByColumn={true} />
                );
            })}
            {sf_hinhthucnopboms.map((x, idx) => {
                return (

                    <TotalItem
                        key={idx}
                        column={`MG_${x.id}`}
                        summaryType="sum"
                        valueFormat=",##0"
                        displayFormat="{0}"
                        customizeText={(e: any) => {
                            if (e.value !== 0) {
                                return e.value.toLocaleString();
                            } else {
                                return "-";
                            }

                        }}
                    />
                );
            })}
            {sf_hinhthucnopboms.map((x, idx) => {
                return (

                    <GroupItem
                        key={idx}
                        column={`PN_${x.id}`}
                        summaryType="sum"
                        valueFormat=",##0"
                        showInGroupFooter={false}
                        displayFormat="{0}"
                        alignByColumn={true} />
                );
            })}
            {sf_hinhthucnopboms.map((x, idx) => {
                return (

                    <TotalItem
                        key={idx}
                        column={`PN_${x.id}`}
                        summaryType="sum"
                        valueFormat=",##0"
                        displayFormat="{0}"
                    />
                );
            })}
        </Summary>
    }, [sf_hinhthucnopboms]);


    return (<div>
        {status === PageBaseStatus.is_loading && <PlaceHolder line_number={10} />}
        {status === PageBaseStatus.is_completed &&
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div style={{
                            display: "flex",
                            width: "100%",
                            marginBottom: "10px"
                        }}>
                            <b style={{ flex: 1, fontSize: "1.2rem" }}>Các khoản nộp của {props.sf_tbtp_viewmodel.noi_dung} - học sinh {props.sf_tbtp_viewmodel.ho_ten}</b>
                            <Button
                                text="Export to Excel"
                                type="normal"
                                icon="fas fa-file-export"
                                id="btnAddKhoanNop"
                                onClick={btnExportClick}
                            />
                            {props.isReadOnly !== true &&
                                <Button
                                    text="Thêm khoản nộp"
                                    type="normal"
                                    icon="plus"
                                    id="btnAddKhoanNop"
                                    onClick={btnAddKhoanNop_Click}
                                />}

                        </div>

                    </div>
                    <DataGrid
                        ref={grid}
                        keyExpr={"sf_khoannop_id"}
                        dataSource={dataSource}
                        columnHidingEnabled={false}
                        columnAutoWidth={true}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        allowColumnResizing={true}
                        columnResizingMode={"nextColumn"}
                        hoverStateEnabled={true}
                        focusedRowEnabled={false}
                        noDataText="Không có dữ liệu"
                        paging={{ enabled: false }}
                        wordWrapEnabled={true}
                        height={window.innerHeight - 400}
                        width={window.innerWidth - 210}
                    >
                        <StateStoring enabled={false} type={"localStorage"} storageKey={dataGridStateStorageKey} />
                        <Sorting mode="multiple" />
                        {!props.sf_tbtp_viewmodel.is_sended && props.isReadOnly !== true && <Column caption="Bỏ" alignment={"center"} width={100} cellRender={(cell) => {
                            if (cell.data.sf_khoannop_id > 0) {
                                return (
                                    <a style={{ cursor: "pointer", color :"#3c22ff" }} onClick={() => {
                                        handleShowDeleteConfirm(cell.data.sf_khoannop_id)
                                    }}>
                                        <i style={{ color: "#f32727" }} className="fas fa-trash"></i>
                                    </a>
                                );
                            }
                            return (
                                <span></span>
                            );
                        }} />}

                        <Column dataField="ten_khoan_nop_group" caption="Nhóm" groupIndex={0} autoExpandGroup={true} sortOrder={'asc'}></Column>
                        <Column dataField="ma_khoan_nop" caption="Mã khoản nộp" width={120}></Column>
                        <Column dataField="ten_khoan_nop" caption="Tên khoản nộp" minWidth={200}></Column>
                        {DynamicColumn}
                        {DynamicSummary}
                        <Grouping
                            autoExpandAll={false}
                        />
                    </DataGrid>


                    {is_show_modal_add_khoannop && <AddKhoanNopModal
                        isSaving={false}
                        onCancel={handleCloseAddModal}
                        onSelected={(data: AddKhoanNopModalRespone) => {
                            handleSubmitAddKhoanNop(data);
                        }}
                        sf_hinhthucnopbom_ids = {props.sf_tbtp_viewmodel.sf_hinhthucnopbom_ids}
                        sf_bom_id={props.sf_tbtp_viewmodel.sf_bom_id}
                        sf_khoannop_ids_added={dataSource.map((x: any) => x.sf_khoannop_id)}
                        animationOf="btnAddKhoanNop"
                        ts_hocsinh_id={props.sf_tbtp_viewmodel.ts_hocsinh_id}
                        nam_nhap_hoc={ts_hocsinh_viewmodel?.nam_nhap_hoc}
                        filter={{
                            nam_hoc:  ts_hocsinh_viewmodel?.nam_hoc || localStorageHelper.getNamHoc(),
                            dm_he_id: ts_hocsinh_viewmodel?.dm_he_id || localStorageHelper.getDmHeId(),
                            dm_truong_id: ts_hocsinh_viewmodel?.dm_truong_id || localStorageHelper.getDmTruongId(),
                            dm_khoi_id: ts_hocsinh_viewmodel?.dm_khoi_id ||localStorageHelper.getDmKhoiId()
                        }}
                    />}

                </div>


                {is_show_confirm_remove_khoannop && <DefaultConfirm
                    onClose={handleCloseDeleteConfirm}
                    onConfirmed={handleRemoveKhoanNop}
                    type="danger"
                    text="Bạn có chắc chắn muốn bỏ khoản nộp toán này khỏi thông báo thu phí ?"
                    text_close_button="Đóng"
                    text_confirm_button="Bỏ khoản nộp"

                />
                }
            </div>
        }

    </div>)
}