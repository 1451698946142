import List from "devextreme-react/list";
import ArrayStore from "devextreme/data/array_store";
import React, { useCallback, useMemo, useState } from "react";
import { tbtpApi } from "../../../../api/tbtpApi";
import { Button } from "../../../../components/buttons";
import { AnimationPopup } from "../../../../components/modal";
import { showNotify } from "../../../../helpers/toast";
import { sf_hachtoan_congno } from "../../../../models/response/hach-toan-cong-no/sf_hachtoan_congno";
type AddHachToanModelProps = {
    sf_hachtoan_congnos: sf_hachtoan_congno[],
    animationOf: string,
    sf_tbtp_id: number,
    onCancel: () => void,
    onSuccess: () => void
}
export const AddHachToanModel = (props: AddHachToanModelProps) => {
    const [selectedIds, SetSelectedIds] = useState<number[]>([]);
    const [isSaving, SetIsSaving] = useState<boolean>(false)
    const onCancel = useCallback(()=>{
        window.animationHidePopup(props.animationOf || "")
        setTimeout(() => {
            props.onCancel();
        }, 500);
    },[])
    const handleSubmit = async() => {
        SetIsSaving(true)
        const res = await tbtpApi.add_hachtoan({
            sf_tbtp_id: props.sf_tbtp_id,
            sf_hachtoan_congno_ids: selectedIds
        })
        SetIsSaving(false)
        if (res.is_success) {
            showNotify({ type: 'success', message: 'Cập nhật thành công' })
            window.animationHidePopup(props.animationOf || "")
            setTimeout(() => {
                props.onSuccess();
            }, 500);

        } else {
            showNotify({ type: 'error', message: 'Cập nhật thất bại' })
        }

    }
    const dataSourceOptions = useMemo(() => {
        return {
            store: new ArrayStore({
                data: props.sf_hachtoan_congnos,
                key: 'id',
            }),
            searchExpr: ['noi_dung'],
        }
    }, [props.sf_hachtoan_congnos])
    const ListItemRender = (data: sf_hachtoan_congno): React.ReactNode => {
        return (
            <div>
                <div className="list_caption">{data.is_hoan_tra ? "Hoàn trả" : "Thu bổ sung"}</div>
                <div className="list_description">{data.noi_dung}</div>
                <div className="list_description text-danger">{data.tong_tien}</div>
            </div>
        );
    }
    return (
        <AnimationPopup animationOf={props.animationOf}
            title="Chọn khoản quyết quán từ danh sách phía dưới"
        >

            <div className="pop-up-form">
                {selectedIds.length > 0 && <h5>Đã chọn {selectedIds.length} khoản hạch toán</h5>}
                <List
                    selectionMode="all"
                    dataSource={dataSourceOptions}
                    searchEnabled={false}
                    selectedItemKeys={selectedIds}
                    onSelectionChanged={(e: any) => {
                        let newSelectedIds = [...selectedIds]
                        const addedIds = e.addedItems.map((x: any) => x.id);
                        addedIds.forEach((element: number) => {
                            newSelectedIds.push(element)
                        });
                        const removedIds = e.removedItems.map((x: any) => x.id)
                        newSelectedIds = newSelectedIds.map((x: any) => {
                            if (removedIds.includes(x)) return 0;
                            return x;
                        })
                        newSelectedIds = newSelectedIds.filter(x => x > 0);
                        SetSelectedIds(newSelectedIds);

                    }}
                    itemRender={ListItemRender}
                    collapsibleGroups={true}
                    allowItemDeleting={false}

                />
            </div>
            <div className="pop-up-actions">
                <Button onClick={onCancel} text="Đóng"></Button> {" "}
                <Button isLoading={isSaving} onClick={handleSubmit} text="Áp dụng" type="normal"

                ></Button>
            </div>
        </AnimationPopup>
    )

}