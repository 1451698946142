import React, { useCallback, useEffect } from "react";
import Modal from "../ui/modal";
import { getDefaultAnimation } from "../../helpers/pop-up-animation";


interface Window {
    animationShowPopup(animationOf: string): void;
    animationHidePopup(animationOf: string): void;
}
declare global {
    interface Window {
        animationShowPopup(animationOf: string): void;
        animationHidePopup(animationOf: string): void;
    }
}
type DefaultModalProps = {
    title?: string
    maxWidth?: any,
    minHeight?: number,
    children?: React.ReactNode,
    height?: string,
    onClose?: () => void,
    width?: "large" | "small" | "xlarge" | "medium" | "xxlarge" | 'xmedium' | any,
}
type AnimationModalProps = {
    title?: string
    animationOf: string,
    maxWidth?: any,
    minHeight?: number,
    children?: React.ReactNode,
    height?: string | any,
    mustClose?: boolean,
    wrapperClass?: string,
    width?: "large" | "small" | "xlarge" | "medium" | "xxlarge" | 'xmedium' | any,
    onClose?: () => void

}
export const AnimationPopup = (props: AnimationModalProps) => {
    const animaition = props.animationOf ? getDefaultAnimation({ of: props.animationOf }) : undefined;
    const onClose = useCallback(() => {
        window.animationHidePopup(props.animationOf || "")
    }, [props.animationOf])
    useEffect(() => {
        if (props.mustClose) onClose();
    }, [props.mustClose])


    return (
        <Modal onClose={() => {
            if (props.onClose) {
                props.onClose()
            }
        }}
            title={props.title}
            isOpen={true}
            width={props.width ?? "xxlarge"}
            height={"auto"}
        >
            {props.children}
        </Modal >
        // <Popup
        //     maxWidth={props.maxWidth || 400}
        //     height={props.height || "auto"}
        //     maxHeight={window.innerHeight}
        //     minHeight={props.minHeight || 200}
        //     showTitle={props.title ? true : false}
        //     title={translate(props.title ?? "")}
        //     dragEnabled={true}
        //     closeOnOutsideClick={false}
        //     visible={true}
        //     showCloseButton={false}
        //     animation={animaition}
        //     width={props.maxWidth || 400}
        //     wrapperAttr={{
        //         class: (animaition ? `my-animation-popup` : '') + (" ") + (props.wrapperClass ? props.wrapperClass : "")
        //     }}
        //     onShown={(e: any) => {
        //         if (animaition) {
        //             window.animationShowPopup(props.animationOf || "")
        //         }
        //     }}


        // >
        //     <ScrollView width='100%' height='100%'>
        //         {props.children}
        //     </ScrollView>

        // </ Popup>
    );
}

export const DefaultPopup = (props: DefaultModalProps) => {
    return (
        <Modal onClose={() => {
            if (props.onClose) {
                props.onClose()
            }
        }}
            title={props.title}
            isOpen={true}
            width={props.width ?? "xxlarge"}
            height={"auto"}
        >
            {props.children}
        </Modal >
        // <Popup
        //     maxWidth={props.maxWidth || 400}
        //     height={props.height || "auto"}
        //     maxHeight={window.innerHeight - 100}
        //     minHeight={props.minHeight || 250}
        //     showTitle={props.title ? true : false}
        //     title={props.title}
        //     dragEnabled={true}
        //     closeOnOutsideClick={false}
        //     visible={true}
        //     showCloseButton={false}
        //     animation={undefined}
        // >
        //     <ScrollView width='100%' height='100%'>
        //         {props.children}
        //     </ScrollView>

        // </ Popup>
    );
}

