import { ActionList, ActionMenu, Box, Button, PageLayout, useTheme } from '@primer/react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { appInfo } from '../../AppInfo';
import { ComboboxCoSo } from '../../components/combobox-coso';
import { ComboboxNamHoc } from "../../components/combobox-namhoc";

import GlobalSearch from '../../components/global-search';
import UserPanel from '../../components/user-panel';
import { actions } from "../../state/actions/actionsWrapper";
import { RootState } from "../../state/reducers";
import MenuGroup from '../menu-group';
import styles from "./Header.module.css";
const LogoIcon = () => {
    const handleClick = () => {
        window.location.href = appInfo.homeURL;

    };

    return (
        <img
            alt='logo'
            style={{ height: "40px", borderRadius: '5px' }}
            src="https://portal.truenorth.edu.vn/images/logo-white.svg"
            onClick={handleClick}
        />
    );
};

const AppsIcon = () => {
    return <img alt='apps' style={{ height: "16px" }} src="../../images/apps.png" />
}
const VietNamIcon = () => {
    return <img alt='vn' style={{ height: "16px" }} src="../../images/vietnam.png" />
}
const EnglandIcon = () => {
    return <img alt='en' style={{ height: "16px" }} src="../../images/united-kingdom.png" />
}
const KoreanIcon = () => {
    return <img alt='kr' style={{ height: "16px" }} src="../../images/south-korea.png" />
}
interface IHeaderProps {
    // onColorModeChanged: (colorMode: "day" | "night") => void
}
const Header = (props: IHeaderProps) => {
    const { colorMode } = useTheme()
    const common = useSelector((state: RootState) => state.common);
    const dispatch = useDispatch();
    const { dm_truong_selected_id, nam_hoc } = useSelector((x: RootState) => x.common)

    return (

        <Box sx={{
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
            borderBottomColor: "border.default",
            borderTopWidth: "1px",
            borderTopStyle: "solid",
            borderTopColor: "border.default",
            backgroundColor: "rgba(24, 45, 98, 0.2)",
            // backgroundColor: "accent.fg",
            // paddingLeft: "2px",
            // paddingRight: "8px"
        }}>
            <PageLayout sx={{ padding: 0 }} containerWidth="full">

                <Box style={{
                    height: appInfo.headerHeight,
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    backgroundColor: "#182D62"

                }}
                    className={styles.header}
                >
                    <Box className={styles.left} sx={{
                        width: 250,
                        pl: 4,
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <Link to={"../../home"}>
                            <LogoIcon />
                        </Link>
                    </Box>
                    <div className={styles.item}>
                        <GlobalSearch onResultChanged={(data: any) => {
                        }} />
                    </div>
                    <div className={styles.center} style={{
                        flex: 1,
                        height: 56,
                        display: "flex",
                        // justifyContent: "center"
                    }}>
                        {/* <MenuGroup /> */}
                    </div>
                    <div className={styles.right} style={{
                        // width: 200
                    }}>
                        {/* <div className={styles.item}>
                            <ComboboxTruong
                                value={dm_truong_selected_id}
                                onValueChanged={(value) => {
                                    dispatch(actions.common.changedValueHeaderComboboxTruong(value))
                                    dispatch(changeTruong(value))
                                }}
                            />
                        </div> */}
                        <div className={styles.item}>
                            <ComboboxCoSo
                                value={common.dm_coso_id > 0 ? common.dm_coso_id : undefined}
                                preText="Cơ sở: "
                                onValueChanged={(value) => {
                                    dispatch(actions.common.changedValueHeaderComboboxCoSo(value))
                                    setTimeout(() => {
                                        // window.location.reload();
                                    }, 100);
                                }} />
                        </div>
                        <div className={styles.item}>
                            <ComboboxNamHoc
                                value={nam_hoc}
                                width="170px"
                                onValueChanged={(value) => {
                                    dispatch(actions.common.changedValueHeaderComboboxNamHoc(value))
                                }}
                            />
                        </div>

                        <div className={styles.item}>
                            <Button leadingVisual={AppsIcon}>

                            </Button>

                        </div>
                        <div className={styles.item}>
                            <ActionMenu>
                                <ActionMenu.Button aria-label="Select field type" leadingVisual={VietNamIcon}>
                                    Tiếng Việt
                                </ActionMenu.Button>
                                <ActionMenu.Overlay width="auto">
                                    <ActionList selectionVariant="single">
                                        <ActionList.Item selected={true}

                                            onSelect={() => {
                                            }}>
                                            <ActionList.LeadingVisual>
                                                <VietNamIcon />
                                            </ActionList.LeadingVisual>
                                            Tiếng Việt</ActionList.Item>
                                        <ActionList.Item disabled>
                                            <ActionList.LeadingVisual>
                                                <EnglandIcon />
                                            </ActionList.LeadingVisual>English</ActionList.Item>
                                        <ActionList.Item disabled>
                                            <ActionList.LeadingVisual>
                                                <KoreanIcon />
                                            </ActionList.LeadingVisual>한국어</ActionList.Item>
                                    </ActionList>
                                </ActionMenu.Overlay>
                            </ActionMenu>
                        </div>
                        <div className={styles.item}>
                            <UserPanel />
                        </div>
                    </div>
                </Box>
                <MenuGroup />

            </PageLayout>
        </Box>

    );
};

export default Header;