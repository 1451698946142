import { appInfo } from '../AppInfo';
import { IBaseResponeModel } from '../models/response/base-response';
import { axiosClient } from './axiosClient';

const apiGuestClient = {
    get: async (path: string): Promise<IBaseResponeModel> => {
        const url = `${appInfo.baseApiURL}/${path}`
        try {
            
            const config = {
                headers: {
                    language: localStorage.getItem("language"),
                }
            }
            const res = await axiosClient.get<any, IBaseResponeModel>(url, config);
            return res;
        } catch (error: any) {
            return {
                status_code: error.response.data.status_code,
                is_success: false,
                message: error.response.data.message || "Có lỗi"
            };

        }
    }
    ,
    post: async (path: string, data?: any, domain?: string): Promise<IBaseResponeModel> => {
        const url = `${domain ? domain : appInfo.baseApiURL}/${path}`

        try {
            const config = {
                headers: {
                    language: localStorage.getItem("language"),
                }
            }
            const res = await axiosClient.post<any, IBaseResponeModel>(url, data, config);
            return res;
        } catch (error: any) {
            return {
                status_code: error.response.data.status_code,
                is_success: false,
                message: error.response.data.message || "Có lỗi"
            };

        }
    },
    put: async (path: string, data?: any): Promise<IBaseResponeModel> => {
        const url = `${appInfo.baseApiURL}/${path}`
        try {
            const config = {

                headers: {
                    language: localStorage.getItem("language"),
                }
            }
            const res = await axiosClient.put<any, IBaseResponeModel>(url, data, config);
            return res;
        } catch (error: any) {
            return {
                status_code: error.response.data.status_code,
                is_success: false,
                message: error.response.data.message || "Có lỗi"
            };
        }
    },
    delete: async (path: string): Promise<IBaseResponeModel> => {
        const url = `${appInfo.baseApiURL}/${path}`
        try {
            
            const config = {
                headers: {
                    language: localStorage.getItem("language"),
                }
            }
            const res = await axiosClient.delete<any, IBaseResponeModel>(url, config);
            return res;
        } catch (error: any) {
            return {
                status_code: error.response.data.status_code,
                is_success: false,
                message: error.response.data.message || "Có lỗi"
            };

        }
    },
    
}
export { apiGuestClient };
