import React from 'react';

const SeparatedDataCell = ({ value, infor }) => {
    const renderSeparatedData = () => {
        if (!value && infor !== 'Thông tin lỗi') {
            return <span></span>;
        }
        if (infor === 'Thông tin lỗi') {
            return <p style={{ marginBottom: 0, color: 'red' }}>{value}</p>;
        }
        const separatedData = value.split('.#');
        return (
            <p style={{ marginBottom: 0 }}>
                {separatedData[0]}
                {separatedData[1] && (
                    <>
                        <hr />
                        <span style={{ color: 'red' }}>{separatedData[1]}</span>
                    </>
                )}
            </p>
        );

    };

    return renderSeparatedData();
};

export default SeparatedDataCell;