import clsx from 'clsx';
import { useDebounce } from 'use-debounce';
import { Spinner } from "../spinners";
import styles from './button.module.css';
type ButtonProps = {
    text?: string,
    icon?: string,
    isLoading?: boolean | false,
    isDisabled?: boolean | false,
    type?: "normal" | "primary" | "danger" | "success",
    onClick?: () => void,
    isSubmitButton?: boolean | false,
    id?: string
}
const Button = (props: ButtonProps) => {
    const [isShowSpinner] = useDebounce<boolean>(props.isLoading || false, 100);

    return <button
        type={props.isSubmitButton ? "submit" : "button"}
        onClick={props.onClick}
        disabled={(props.isDisabled || props.isLoading) ? true : false}
        id={props.id}
        className={clsx(
            styles.btn,
            props.type == "primary" && styles.btn_primary,
            props.type == "danger" && styles.btn_danger,
            props.type == "success" && styles.btn_success,
            (props.isDisabled || props.isLoading) && styles.btn_disabled
        )}>
        {props.icon && !isShowSpinner && <i className={clsx(styles.icon, props.icon)}></i>}
        {isShowSpinner && <>{' '} <Spinner isBlack={!props.type || props.type === "normal"} /></>}
        {props.text}

    </button >
}
export { Button };

