import { Box } from "@primer/react";
import { CheckBox, DateBox } from "devextreme-react";
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    Sorting,
} from "devextreme-react/data-grid";
import SelectBox from "devextreme-react/select-box";
import { Item, Toolbar } from "devextreme-react/toolbar";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import { saveAs } from 'file-saver-es';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Transition } from "semantic-ui-react";
import { chamAnApi } from "../../api/chamAnApi";
import { Button } from "../../components/buttons";
import { ComboboxHe } from "../../components/combobox-he";
import ComboboxKhoi from "../../components/combobox-khoi";
import { ComboboxTruong } from "../../components/combobox-truong";
import TagBoxTrangThaiHocSinh from "../../components/tagbox-trang-thai-hoc-sinh";
import { useCommonContext } from "../../contexts/common";
import { RootState } from "../../state/reducers";
import styles from "./TongHopChamAn.module.css";
import { ChamAnHoanPhiRequest } from "../../models/request/cham-an/ChamAnHoanPhiRequest";
import { DefaultConfirm } from "../../components/confirm";
import { showNotify } from "../../helpers/toast";

const TongHopChamAnPage = () => {
    const thangs = [
        {
            id: 8,
            text: "Tháng 8"
        },
        {
            id: 9,
            text: "Tháng 9"
        },
        {
            id: 10,
            text: "Tháng 10"
        },
        {
            id: 11,
            text: "Tháng 11"
        },
        {
            id: 12,
            text: "Tháng 12"
        },
        {
            id: 1,
            text: "Tháng 1"
        },
        {
            id: 2,
            text: "Tháng 2"
        },
        {
            id: 3,
            text: "Tháng 3"
        },
        {
            id: 4,
            text: "Tháng 4"
        },
        {
            id: 5,
            text: "Tháng 5"
        },
        {
            id: 6,
            text: "Tháng 6"
        },
        {
            id: 7,
            text: "Tháng 7"
        },
    ]
    const bua_ans = [
        {
            id: 1,
            text: "Bữa sáng"
        },
        {
            id: 2,
            text: "Bữa trưa"
        },
        {
            id: 3,
            text: "Bữa ăn nhẹ"
        }
    ]
    const loai_tong_hops = [
        {
            id: 1,
            text: "Theo ngày"
        },
        {
            id: 2,
            text: "Theo tháng"
        }
    ]
    const [buaAnSelected, set_buaAnSelected] = useState<number>(0);
    const [dm_truong_id, set_dm_truong_id] = useState<number>(0);
    const [dm_khoi_id, set_dm_khoi_id] = useState<number>(0);
    const [dm_he_id, set_dm_he_id] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { nam_hoc } = useSelector((x: RootState) => x.common);
    const [dataSource, setDataSource] = useState<any>([]);
    const [totalDays, setTotalDays] = useState<any>([]);
    const [selectedStatusStudent, setSelectedStatusStudent] = useState<number[]>([]);
    const [monthSelected, set_monthSelected] = useState(0);
    const [confirm_lock, set_confirm_lock] = useState(false);

    const [loaiTongHopSelected, set_loaiTongHopSelected] = useState(0);
    const [ngay_cham_an, set_ngay_cham_an] = useState<any>(
        moment().format("YYYY-MM-DD")
    );
    const grid = useRef<any>();
    const { createUUID } = useCommonContext();
    useEffect(() => {
        if (loaiTongHopSelected == 2) {
            let currentYear = parseInt(nam_hoc.split('-')[0])
            const days = getDaysInMonth(currentYear, monthSelected);
            setTotalDays(days);
        }
    }, [nam_hoc, monthSelected, loaiTongHopSelected]);
    const getDaysInMonth = (year: number, monthIndex: number): Date[] => {
        const month = monthIndex; // Chuyển đổi từ chỉ số về giá trị tháng (ví dụ: 5 -> tháng 6)
        const date = new Date(year, month - 1, 1); // Sử dụng month - 1 để khởi tạo tháng đúng
        const daysInMonth: Date[] = [];

        // Kiểm tra nếu tháng hiện tại vẫn còn là tháng mà chúng ta đang xem xét
        while (date.getMonth() + 1 === month) {
            daysInMonth.push(new Date(date)); // Thêm một bản sao của ngày vào mảng để tránh thay đổi vô tình
            date.setDate(date.getDate() + 1); // Tăng ngày lên 1 để kiểm tra ngày tiếp theo
        }

        return daysInMonth;
    };
    const handleSearch = () => {
        handleReloadReportChamAn();
    };

    const handleReloadReportChamAn = async () => {
        setIsLoading(true)
        const res = await chamAnApi.select_by_month({
            nam_hoc: nam_hoc,
            dm_he_id: dm_he_id,
            dm_khoi_id: dm_khoi_id,
            dm_truong_id: dm_truong_id,
            thang: monthSelected,
            dm_buaan_id: buaAnSelected,
            ngay_cham_an: ngay_cham_an,
            loai_tong_hop: loaiTongHopSelected
        })
        setIsLoading(false)
        if (res.is_success) {
            showNotify({ message: "Tải dữ liệu thành công", type: "success" });
            setDataSource(res.data)
        }
        else {
            showNotify({ message: "Tải dữ liệu không thành công", type: "error" });

        }
    }
    const handleLockChamAn = async () => {
        setIsLoading(true)
        const res = await chamAnApi.lock_cham_an({
            nam_hoc: nam_hoc,
            dm_he_id: dm_he_id,
            dm_khoi_id: dm_khoi_id,
            dm_truong_id: dm_truong_id,
            thang: monthSelected,
            dm_buaan_id: buaAnSelected,
            ngay_cham_an: ngay_cham_an,
            loai_tong_hop: loaiTongHopSelected
        })
        setIsLoading(false)
        if (res.is_success) {
            set_confirm_lock(false)
            showNotify({ message: "Khóa chấm thành công", type: "success" });
            handleSearch();
        }
    }
    const isLocked = useMemo(() => {
        if (dataSource) {
            if (dataSource[0] !== undefined && dataSource[0].is_lock === true)
                return true
            else
                return false
        }
        return false
    }, [dataSource])
    const dynamicColumns = useMemo(() => {
        if (loaiTongHopSelected == 2) {
            return totalDays.map((x: any) => {
                return (
                    <Column caption={x.toLocaleDateString('en-GB', { day: '2-digit' })}>
                        <Column
                            key={createUUID()}
                            dataField={'D_' + x.getDate()}
                            caption={'Trạng thái'}
                            width={70}
                            dataType={"boolean"}
                            allowSorting={false}
                            allowFiltering={false}
                        />
                        {/* <Column
                            key={createUUID()}
                            dataField={'HP_' + x.getDate()}
                            caption={'Hoàn phí'}
                            width={70}
                            dataType={"boolean"}
                            allowSorting={false}
                            allowFiltering={false}
                        /> */}

                        <Column
                            key={createUUID()}
                            dataField={'HP_' + x.getDate()}
                            caption="Hoàn phí"
                            width={70}
                            allowFiltering={false}
                            cellRender={(cell) => {
                                return (
                                    <CheckBox
                                        value={cell.data['HP_' + x.getDate()]}
                                        onValueChanged={(e) => {
                                            if (e.event) {
                                                const newValue = e.value;
                                                handleSetCellValueMonth(cell.data, newValue, x.id);
                                            }
                                        }}
                                    />
                                );
                            }}
                            dataType="boolean"
                        />
                    </Column>
                );
            });
        }
        else {
            return bua_ans.map((x: any) => {
                return (
                    <Column caption={x.text}>
                        <Column
                            key={createUUID()}
                            dataField={'D_' + x.id + '_'}
                            caption={'Có ăn'}
                            width={160}
                            dataType={"boolean"}
                            allowSorting={false}
                            allowFiltering={false}
                        />
                        {/* <Column
                            key={createUUID()}
                            dataField={'HP_' + x.id + '_' + (new Date(ngay_cham_an)).getDate()}
                            caption={'Hoàn phí'}
                            width={160}
                            dataType={"boolean"}
                            allowSorting={false}
                            allowFiltering={false}
                        /> */}

                        <Column
                            key={createUUID()}
                            dataField={'HP_' + x.id + '_'}
                            caption="Hoàn phí"
                            width={160}
                            cellRender={(cell) => {
                                return (
                                    <CheckBox
                                        value={cell.data['HP_' + x.id + '_']}
                                        onValueChanged={(e) => {
                                            if (e.event) {
                                                const newValue = e.value;
                                                handleSetCellValue(cell.data, newValue, x.id);
                                            }
                                        }}
                                    />
                                );
                            }}
                            dataType="boolean"
                        />
                    </Column>
                );
            });
        }
    }, [totalDays, loaiTongHopSelected]);
    const handleSetCellValue = async (currentRowData: any, value: any, dm_buaan_id: number) => {
        const data_send: ChamAnHoanPhiRequest = {
            ts_hocsinh_id: currentRowData.id,
            dm_buaan_id: dm_buaan_id,
            ngay_cham_an: ngay_cham_an,
            is_hoanphi: value
        }
        setIsLoading(true)
        const res = await chamAnApi.update_hoan_phi(data_send)
        setIsLoading(false)
        if (res.is_success) {
            showNotify({ message: "Cập nhật thành công", type: "success" });
            handleSearch()
        }
    }
    const handleSetCellValueMonth = async (currentRowData: any, value: any, dm_buaan_id: number) => {
        debugger
    }
    const onExporting = () => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
            component: grid.current.instance,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'BaoCaoChamAn-' + moment().format('YYYY-MM-DD_HHmmss').toString() + '.xlsx');
            });
        });
    }

    return (
        <Box className={styles.container} sx={{ pl: 1, pt: 1 }}>
            <Transition
                animation="scale"
                visible={true}
                duration={100}
                transitionOnMount={true}
            >
                <div className="row">
                    <div className="col-md-12 page-toolbar">
                        <h4>Báo cáo chấm ăn</h4>
                        <Toolbar className="bg-background">
                            <Item location="before">
                                <ComboboxTruong
                                    stylingMode="outlined"
                                    isReadonly={isLoading}
                                    value={dm_truong_id}
                                    isShowClearButton={true}
                                    onValueChanged={(value) => {
                                        set_dm_truong_id(value || 0);
                                    }}
                                ></ComboboxTruong>
                            </Item>
                            <Item location="before">
                                <ComboboxKhoi
                                    stylingMode="outlined"
                                    isReadonly={isLoading}
                                    value={dm_khoi_id}
                                    dm_truong_id={dm_truong_id}
                                    isShowClearButton={true}
                                    onValueChanged={(value) => {
                                        set_dm_khoi_id(value || 0);
                                    }}
                                    width={120}
                                ></ComboboxKhoi>
                            </Item>
                            <Item location="before">
                                <ComboboxHe
                                    stylingMode="outlined"
                                    isReadonly={isLoading}
                                    value={dm_he_id}
                                    dm_truong_id={dm_truong_id}
                                    dm_khoi_id={dm_khoi_id}
                                    isShowClearButton={true}
                                    onValueChanged={(value) => {
                                        set_dm_he_id(value || 0);
                                    }}
                                    width={120}
                                ></ComboboxHe>

                            </Item>
                            <Item location="before">
                                <TagBoxTrangThaiHocSinh
                                    width={220}
                                    stylingMode="outlined"
                                    value={selectedStatusStudent}
                                    onValueChanged={(id: any, data: any) => {
                                        setSelectedStatusStudent(id)
                                    }}

                                />
                            </Item>
                            <Item location="before">
                                <SelectBox
                                height={34}
                                    dataSource={loai_tong_hops}
                                    displayExpr={"text"}
                                    valueExpr={"id"}
                                    placeholder={"Loại tổng hợp"}
                                    stylingMode="outlined"
                                    width={130}
                                    showClearButton={true}
                                    value={loaiTongHopSelected}
                                    onValueChanged={(e) => {
                                        console.log(e.value)
                                        set_loaiTongHopSelected(e.value)
                                    }}
                                />
                            </Item>
                            {loaiTongHopSelected === 1 &&
                                <Item location="before">
                                    <DateBox
                                        width={120}
                                        value={ngay_cham_an}
                                        onValueChange={(value) => {
                                            const newNgayChamAn =
                                                moment(value).format("YYYY-MM-DD");
                                            set_ngay_cham_an(newNgayChamAn);
                                        }}
                                        displayFormat={"dd-MM-yyyy"}
                                        dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                        // labelMode={"static"}
                                        stylingMode="outlined"
                                        // label={"Ngày chấm ăn"} 
                                        readOnly={false}
                                    />
                                </Item>
                            }
                            {loaiTongHopSelected === 2 &&
                                <Item location="before">
                                    <SelectBox
                                        dataSource={thangs}
                                        displayExpr={"text"}
                                        valueExpr={"id"}
                                        placeholder={"Chọn tháng"}
                                        stylingMode="outlined"
                                        width={120}
                                        showClearButton={true}
                                        value={monthSelected}
                                        onValueChanged={(e) => {
                                            if (e.event) {
                                                set_monthSelected(e.value || 0)
                                            }
                                        }}
                                    />
                                </Item>
                            }
                            {loaiTongHopSelected > 1 &&
                                <Item location="before">
                                    <SelectBox
                                        dataSource={bua_ans}
                                        displayExpr={"text"}
                                        valueExpr={"id"}
                                        placeholder={"Chọn bữa ăn"}
                                        stylingMode="outlined"
                                        width={130}
                                        showClearButton={true}
                                        value={buaAnSelected}
                                        onValueChanged={(e) => {
                                            if (e.event) {
                                                set_buaAnSelected(e.value || 0)
                                            }
                                        }}
                                    />
                                </Item>
                            }
                            {(loaiTongHopSelected === 1 && !isLocked) &&
                                <Item location="after"
                                    locateInMenu="never">
                                    <Button
                                        isLoading={isLoading}
                                        text="Khóa"
                                        type="normal"
                                        icon="fa fa-lock"
                                        onClick={() => set_confirm_lock(true)}
                                    />{" "}
                                </Item>
                            }
                            {loaiTongHopSelected > 0 &&
                                <Item location="after"
                                    locateInMenu="never">
                                    <Button
                                        isLoading={isLoading}
                                        isDisabled={(loaiTongHopSelected === 2 && monthSelected === 0)}
                                        text="Tổng hợp"
                                        type="normal"
                                        icon="fa fa-calculator"
                                        onClick={handleSearch}
                                    />{" "}
                                    <Button
                                        isLoading={isLoading}
                                        text="Export"
                                        type="normal"
                                        icon="fa fa-file-excel"
                                        onClick={onExporting}
                                    />
                                </Item>
                            }
                        </Toolbar>
                    </div>
                    <div className="col-md-12" style={{ paddingTop: '5px' }}>
                        <DataGrid
                            ref={grid}
                            dataSource={dataSource}
                            key={"id"}
                            columnHidingEnabled={false}
                            columnAutoWidth={true}
                            showBorders={true}
                            showColumnLines={true}
                            showRowLines={true}
                            rowAlternationEnabled={true}
                            allowColumnResizing={true}
                            columnResizingMode={"nextColumn"}
                            hoverStateEnabled={true}
                            focusedRowEnabled={false}
                            height={window.innerHeight - 210}
                            className={"table"}
                            noDataText={
                                isLoading
                                    ? "Đang tải dữ liệu ..."
                                    : "Không có dữ liệu"
                            }
                            wordWrapEnabled={true}
                        >
                            <FilterRow visible={true} />
                            <Sorting mode="multiple" />
                            <Paging defaultPageSize={50} />
                            <FilterRow visible={true} />
                            <HeaderFilter visible={false} />

                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[10, 20, 50, 200, 500]}
                                infoText="Trang {0} /{1} ({2} dòng)"
                                showInfo={true}
                            />
                            <Column dataField={"ma_hs"} caption={"Mã HS"} width={loaiTongHopSelected == 2 ? 130 : 150} fixed />
                            <Column dataField={"ho_ten"} caption={"Họ  tên"} width={loaiTongHopSelected == 2 ? 150 : 240} fixed />
                            <Column
                                dataField="ten_lop"
                                caption="Tên lớp"
                                width={loaiTongHopSelected == 2 ? 100 : 150} fixed
                            ></Column>,
                            <Column dataField={"trang_thai"} caption={"Trạng thái HS"} width={loaiTongHopSelected == 2 ? 130 : 200} fixed />

                            {dynamicColumns}
                            {loaiTongHopSelected == 2 &&

                                <Column
                                    dataField={"tong_ngay_tren_thang"}
                                    caption={"Tổng ngày trong tháng"}
                                    headerCellRender={(e) => (
                                        <span
                                            style={{
                                                color: "blue"
                                            }}
                                        >
                                            {e.column.caption}
                                        </span>
                                    )}
                                    width={150}
                                    cellRender={(e) => (
                                        <span
                                            style={{
                                                color: "blue"
                                            }}
                                        >
                                            {e.text}
                                        </span>
                                    )}
                                />}
                            {loaiTongHopSelected == 2 &&

                                <Column
                                    dataField={"tong_ngay_an"}
                                    caption={"Tổng ngày ăn"}
                                    headerCellRender={(e) => (
                                        <span
                                            style={{
                                                color: "green"
                                            }}
                                        >
                                            {e.column.caption}
                                        </span>
                                    )}
                                    width={150}
                                    cellRender={(e) => (
                                        <span
                                            style={{
                                                color: "green"
                                            }}
                                        >
                                            {e.text}
                                        </span>
                                    )}
                                />
                            }
                            {loaiTongHopSelected == 2 &&
                                <Column
                                    dataField={"tong_ngay_khong_an"}
                                    caption={"Tổng ngày không ăn"}
                                    headerCellRender={(e) => (
                                        <span
                                            style={{
                                                color: "red"
                                            }}
                                        >
                                            {e.column.caption}
                                        </span>
                                    )}
                                    width={150}
                                    cellRender={(e) => (
                                        <span
                                            style={{
                                                color: "red"
                                            }}
                                        >
                                            {e.text}
                                        </span>
                                    )}
                                />}

                        </DataGrid>
                    </div>
                    {confirm_lock &&
                        <DefaultConfirm
                            onClose={() => { set_confirm_lock(false) }}
                            onConfirmed={handleLockChamAn}
                            text={`Bạn có muốn khóa chấm ăn ngày hôm nay không?`}
                            type="success"
                            text_close_button="Đóng"
                            text_confirm_button="Xác nhận"
                            is_saving={isLoading}
                        />
                    }
                </div>
            </Transition>
        </Box>
    );
};
export default TongHopChamAnPage;
