import React from 'react';
import { Text } from "@primer/react"
import { TextProps } from '@primer/react/lib-esm';
import { useCommonContext } from '../../../contexts/common';
interface IMyTextProps extends TextProps {
    text: string
}
const MyText = (props: IMyTextProps) => {
    const {translate} = useCommonContext();
    return (
        <Text sx={{
            ...props.sx,
        }}>
            {translate(props.text)}
        </Text>
    );
};

export default MyText;