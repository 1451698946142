
import { UnderlineNav } from '@primer/react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../state/actions/actionsWrapper';
import { RootState } from '../../state/reducers';
import styles from "./MenuGroup.module.css";
const MenuGroup = () => {
    const { user_info } = useSelector((x: RootState) => x.auth)
    const { menu_group_id } = useSelector((x: RootState) => x.common)
    const dispatch = useDispatch();
    return (

        <UnderlineNav aria-label="Repository" sx={{
            borderBottom: 0,
            borderBottomStyle: "solid",
            borderColor: "border.default",
            justifyContent: "center",
            display: "flex",
            width: "100%"

        }}>
            {user_info && <>
                {user_info.menus.map((menu, index) => {
                    const Icon = () => {
                        return <i className={`${menu.icon}`}></i>
                    }
                    return (
                        <UnderlineNav.Item key={index} icon={Icon}
                            aria-current={menu.id === menu_group_id}
                            onSelect={() => {
                                dispatch(actions.common.changeMenuGroup(menu.id))
                            }}
                            className={clsx(styles.item, menu.id === menu_group_id ? styles.selected : "")}
                        >
                            {menu.name}
                        </UnderlineNav.Item>
                    )
                })}
            </>}


        </UnderlineNav>
    );
};

export default MenuGroup;