import { ILoginOIDCRequest } from '../models/request/account/ILoginOIDCRequest';
import { apiClient } from './apiClient';
import { apiGuestClient } from './apiGuestClient';

const accountApi = {
    getUser: () => {
        return apiClient.post(`login/detail`)
    },
    logInSSO: (data: ILoginOIDCRequest) => {
        return apiGuestClient.post(`login`, data)
    },
    logOut() {
        return apiClient.post(`api/logout`, undefined, localStorage.getItem("homeURL") ?? "")
    }

}
export { accountApi };

