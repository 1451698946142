import SelectBox from "devextreme-react/select-box";
import React, { useEffect, useState } from "react";
import { hinhThucNopBomApi } from "../../api/hinhThucNopBom";
import { useCommonContext } from "../../contexts/common";
import { sf_hinhthucnopbom } from "../../models/response/category/sf_hinhthucnopbom";
type ICmbHinhThucNopBomProps = {
    isReadonly?: boolean,
    value?: number,
    width?: number,
    onValueChanged: (id: number, data?: sf_hinhthucnopbom) => void,
    className?: string,
    isShowClearButton?: boolean,
    preText?: string,
    stylingMode?: "outlined" | "filled" | "underlined",
    dmCoSoId?: number,
    sf_hinhthucnopbom_dotgui?: string
}
const CmbHinhThucNopBom = (props: ICmbHinhThucNopBomProps) => {
    const [hinhThucNopBoms, setHinhThucNopBoms] = useState<sf_hinhthucnopbom[]>([]);
    useEffect(() => {
        handleReloadHinhThucNopBoms();
    }, [props.dmCoSoId])
    const handleReloadHinhThucNopBoms = async () => {
        const res = await hinhThucNopBomApi.selectAll();
        if (res.is_success) {
            var temp = res.data;
            temp = res.data.filter((x: any) => x.id > 0);
            if (props.sf_hinhthucnopbom_dotgui) {
                const htnb_ids = props.sf_hinhthucnopbom_dotgui.split(',')
                    .map(Number);
                temp = temp.filter((x: any) => htnb_ids.includes(x.id));
            }
            setHinhThucNopBoms(temp)
        }
    }
    const { translate } = useCommonContext();
    return (
        <SelectBox dataSource={hinhThucNopBoms}
            displayExpr={"name"}
            valueExpr={"id"}
            value={props.value}
            stylingMode={props.stylingMode || "outlined"}
            readOnly={props.isReadonly === true}
            placeholder={translate("Chọn kỳ nộp")}
            width={props.width ?? "100%"}
            className={"default_selectbox " + (props.className ? props.className : "")}
            showClearButton={props.isShowClearButton === true}
            onValueChanged={(e) => {
                if (e.event) {
                    const data = hinhThucNopBoms.find(x => x.id == e.value)
                    props.onValueChanged(e.value, data)
                }
            }} />
    );
}
export default CmbHinhThucNopBom

