

import React from "react";
import TongHopCongNoPage from "../tong-hop-cong-no/TongHopCongNoPage";
const HomePage = () => {
  return (
    <React.Fragment>
      {/* <TongHopCongNoPage /> */}
    </React.Fragment >
  );
}
export default HomePage
