import { accountActions } from './accountActions';
import { categorySourceActions } from './categorySourceActions';
import { appConfigAction } from './common/appConfigAction';
import { commonActions } from './commonActions';
import { dictionaryActions } from './dictionaryAction';
import { notifyActions } from './notify/notifyAction';
import { uploadActions } from './upload-file/uploadActions';

export const actions = {
    account: accountActions,
    appConfig:appConfigAction,
    categorySource: categorySourceActions,
    common: commonActions,
    dictionary: dictionaryActions,
    uploadFile: uploadActions,
    notify: notifyActions,
}
